var angularModule = angular.module('t2g.common.directives.uniqueInput', []);

module.exports = angularModule;

angularModule.directive('uniqueInput', function () {
  return {
    require: 'ngModel',
    scope: {
      uniqueInput: '=',
    },
    link: function (scope, elem, attrs, ngModel) {
      scope.$watch('uniqueInput', () => {
        if (Object.values(scope.uniqueInput).filter(item => item === ngModel.$viewValue).length > 1) {
          ngModel.$setValidity('unique', false);

        } else {
          ngModel.$setValidity('unique', true);
        }
      }, true);
    }
  }
});
