const angularModule = angular.module('t2g.common.data-access.user', []);

module.exports = angularModule;

angularModule.factory('UserResource', function ($resource, settings) {
  var resourceUrl = settings.apiBase + '/api/v2/user/:subResource1/:subResource2';

  var resource = $resource(resourceUrl, {}, {
    selectOffice: { method: 'GET', isArray: false, params: { subResource1: 'selectOffice' } },
    logout: { method: 'GET', isArray: false, params: { subResource1: 'logout' } },
    ping: { method: 'GET', isArray: false, params: { subResource1: 'ping' } },
    insert: { method: 'POST', isArray: false },
    update: { method: 'PUT', isArray: false, params: { subResource1: '@_id' } },
    remove: { method: 'DELETE', isArray: false },
    updateAccount: { method: 'PUT', isArray: false }
  });

  return resource;
});

angularModule.factory('UserCache', function ($cacheFactory) {
  return $cacheFactory('UserCache', { capacity: 20 });
});

angularModule.factory('UserDA', ($q,
                                 $rootScope,
                                 $timeout,
                                 UserResource,
                                 UserSession,
                                 LocalStorage,
                                 AppointmentCache,
                                 CustomerCache,
                                 gettextCatalog,
                                 ObjectCache,
                                 CustomerDA,
                                 settings,
                                 OfficeCache,
                                 ResourceDA) => {

  let _users = [];

  return {
    ping,
    selectOffice,
    list,
    get: get,
    insert,
    update,
    updateAccount,
    remove,
    logout
  };

  function ping() {
    let user;

    return UserResource
      .ping()
      .$promise
      .then((response) => {
        user = response.session;

        return UserSession.qSet(response.session);
      })
      .then(() => {
        return user;
      });
  }

  function logout() {
    LocalStorage.remove('userRole');
    LocalStorage.remove('countryConfig');
    LocalStorage.remove('openDay');

    const calendarView = LocalStorage.get('calendarView');
    LocalStorage.set('calendarView', {
      view: calendarView.view
    })
    UserSession.clean();

    return $timeout(50)
      .then(() => {
        return UserResource
          .logout()
          .$promise;
      })
      .then(() => {
          window.location = settings.login;
      });
  }

  function insert(user) {
    return UserResource
      .insert(user)
      .$promise
      .then((response) => {
        _users.push(response.user);
        return response.user;
      });
  }

  function update(update) {
    return UserResource
      .update(update)
      .$promise
      .then((response) => {
        let index = _users.findIndex(user => user._id === update._id);
        if (index > -1) {
          _users[index].firstname = response.user.firstname;
          _users[index].lastname = response.user.lastname;
          _users[index].email = response.user.email;
          _users[index].role = response.user.role;
        }

        return response.user;
      });
  }

  function updateAccount(update) {
    return UserResource
      .updateAccount(update)
      .$promise
      .then((response) => {
        return response.user;
      });
  }

  function remove(userId) {
    return UserResource
      .delete({ subResource1: userId })
      .$promise
      .then((response) => {
        if (response.success) {
          let index = _users.findIndex(user => user._id === update._id);
          _users.splice(index, 1);
        }
      });
  }

  function get(userId) {
    return UserResource
      .get({ subResource1: userId })
      .$promise
      .then((response) => {
        return response.user;
      });
  }

  function list() {
    if (_users && _users.length) {
      return $q.when(_users);
    } else {
      return UserResource
        .get()
        .$promise
        .then(function (response) {
          _users = response.users;
          return response.users;
        });
    }
  }

  function selectOffice(officeId) {
    return UserResource
      .selectOffice({ subResource2: officeId })
      .$promise
      .then((response) => {
        UserSession.setBranch(officeId);
        AppointmentCache.removeAll();
        CustomerCache.removeAll();
        ObjectCache.removeAll();
        OfficeCache.removeAll();
        CustomerDA.purgeCache();
        ResourceDA.clearCache();



        return response;
      });

  }
});
