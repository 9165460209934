'use strict';

module.exports = function(PixelTimeService) {
    return {
        restrict: 'E',
        template: '<div class="absent-time" data-ng-class="{\'absent-time--absence\': !absentTime.available}" data-ng-repeat="absentTime in absentTimes" data-ng-style="absentTime"></div>',
        replace: true,
        scope: {
            workingHours: '='
        },
        link: function($scope, $element) {
            $scope.absentTimes = [];
            var times = $scope.workingHours.times;
            $scope.available = $scope.workingHours.available;

            if($scope.available && times && times.length) {
                times.forEach((time, index) => {
                    if(index === 0) {
                        $scope.absentTimes.push({
                            top: 0,
                            available: $scope.workingHours.available,
                            height: PixelTimeService.minutesToPixel(times[0].start)
                        });
                    } else if (index > 0) {
                        $scope.absentTimes.push({
                            top: PixelTimeService.minutesToPixel(times[index - 1].end),
                            available: $scope.workingHours.available,
                            height: PixelTimeService.minutesToPixel(times[index].start - times[index - 1].end, true)
                        });
                    }
                });

                $scope.absentTimes.push({
                    bottom: 0,
                    available: $scope.workingHours.available,
                    top: PixelTimeService.minutesToPixel(times[times.length - 1].end)
                });
            } else {
                $scope.absentTimes.push({
                    top: 0,
                    available: $scope.workingHours.available,
                    bottom: 0
                });
            }
        }
    };
};

