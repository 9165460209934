'use strict';

var angularModule = angular.module('t2g.common.directives.capitalizeDirective', []);

module.exports = angularModule;

angularModule.directive('capitalize', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $element, $attributes, ngModel) {

            var capitalize = function (inputValue) {
                var capitalized = inputValue.toUpperCase();

                if (capitalized !== inputValue) {
                    ngModel.$setViewValue(capitalized);
                    ngModel.$render(capitalized);
                }

                return capitalized;
            };


            if (ngModel) {
                ngModel.$parsers.push(capitalize);
            }
        }
    }
});

angularModule.directive('capitalizeFirst', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $element, $attributes, ngModel) {

            var capitalize = function (inputValue) {
                if (!inputValue) return;

                var capitalized = inputValue.charAt(0).toUpperCase() +
                    inputValue.substring(1);

                if (capitalized !== inputValue) {
                    ngModel.$setViewValue(capitalized);
                    ngModel.$render();
                }

                return capitalized;
            };

            ngModel.$parsers.push(capitalize);
            capitalize($scope[$attributes.ngModel]);  // capitalize initial value
        }
    };
});