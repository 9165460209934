var angularModule = angular.module('t2g.common.filter.uniqueFilter', []);

module.exports = angularModule;

angularModule.filter('unique', function() {
    return function(model1, model2) {
        var uniqueItems = model1;
        var index;

        if (typeof model2 !== 'undefined') {
            angular.forEach(model2, function(item2) {
                angular.forEach(model1, function(item1) {
                    index = -1;

                    if (angular.equals(item1, item2)) {
                        index = model1.indexOf(item1);
                        uniqueItems.splice(index, 1);
                    }
                });
            });
        }

        return uniqueItems;
    }
});
