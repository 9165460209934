'use strict';

var customTranslationsModule = angular.module('t2g.common.custom-translations', [
    'gettext'
]);

module.exports = customTranslationsModule;

customTranslationsModule.factory('CustomTranslationsService', function(gettextCatalog) {
    // custom translations
    gettextCatalog.getString('CUSTOMER');
    gettextCatalog.getString('CUSTOMERS');
    gettextCatalog.getString('CLIENT');
    gettextCatalog.getString('CLIENTS');
    gettextCatalog.getString('PATIENT');
    gettextCatalog.getString('PATIENTS');
    gettextCatalog.getString('GUEST');
    gettextCatalog.getString('GUESTS');

    gettextCatalog.getString('Customer');
    gettextCatalog.getString('Customers');
    gettextCatalog.getString('Client');
    gettextCatalog.getString('Clients');
    gettextCatalog.getString('Patient');
    gettextCatalog.getString('Patients');
    gettextCatalog.getString('Guest');
    gettextCatalog.getString('Guests');

    gettextCatalog.getString('customer');
    gettextCatalog.getString('customers');
    gettextCatalog.getString('client');
    gettextCatalog.getString('clients');
    gettextCatalog.getString('patient');
    gettextCatalog.getString('patients');
    gettextCatalog.getString('guest');
    gettextCatalog.getString('guests');

    gettextCatalog.getString('WEEK_DAY_SHORT_0');
    gettextCatalog.getString('WEEK_DAY_SHORT_1');
    gettextCatalog.getString('WEEK_DAY_SHORT_2');
    gettextCatalog.getString('WEEK_DAY_SHORT_3');
    gettextCatalog.getString('WEEK_DAY_SHORT_4');
    gettextCatalog.getString('WEEK_DAY_SHORT_5');
    gettextCatalog.getString('WEEK_DAY_SHORT_6');

    gettextCatalog.getString('SALUTATION_f');
    gettextCatalog.getString('SALUTATION_m');

    gettextCatalog.getString('inbox');
    gettextCatalog.getString('outbox');

    gettextCatalog.getString('CASH_ENTRY_withdrawal');
    gettextCatalog.getString('CASH_ENTRY_contribution');

    // validation
    gettextCatalog.getString('Address needs to be an URL');

    gettextCatalog.getPlural(0, 'week', '{{$count}} weeks', {});


    return {};
});
    
