'use strict';

var angularModule = angular.module('t2g.common.services.datePickerSettings', []);

module.exports = angularModule;

angularModule.factory('DatepickerSettings', function() {
    return {
        default: {
            onOpenFocus: true,
            showWeeks: false,
            formatDay: 'dd',
            startingDay: 1,
            closeOnDateSelection: true,
            closeText: 'OK'
        },
        minMonth: {
            closeOnDateSelection: true,
            onOpenFocus: true,
            showWeeks: false,
            formatMonth: 'MMM',
            monthColumns: 3,
            minMode: 'month',
            closeText: 'OK'
        },
        minMonthWithWeek: {
            closeOnDateSelection: true,
            onOpenFocus: true,
            showWeeks: true,
            formatMonth: 'MMM',
            monthColumns: 3,
            minMode: 'month',
            closeText: 'OK'
        }
    };
});
