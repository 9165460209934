'use strict';

var WorkingHoursCtrl = function ($scope,
                                 $state,
                                 $stateParams,
                                 shiftPlanning,
                                 gettextCatalog,
                                 office,
                                 Notification,
                                 moment,
                                 ResourceDA,
                                 shiftClipboard,
                                 ShiftPlanningDA,
                                 DatepickerSettings) {

  var self = this;

  this.gettextCatalog = gettextCatalog;
  this._Notification = Notification;
  this._ResourceDA = ResourceDA;
  this._shiftClipboard = shiftClipboard;
  this._ShiftPlanningDA = ShiftPlanningDA;
  this._state = $state;
  this._stateParams = $stateParams;
  this._moment = moment;

  this.employee = shiftPlanning.employee;
  this.weekDays = [];
  this.office = office;
  this.openTab = this._stateParams.openTab;
  this.dates = shiftPlanning.dates;
  this.isSecondTimeWeekly = this.checkSecondTimeWeekly();
  this.isSecondTimeDaily = this.checkSecondTimeDaily();
  this.currentMonth = moment(this._stateParams.date).toDate();
  this.dtpOptions = DatepickerSettings.minMonth;
  this.weekValidation = [
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1}
  ];

  this.absenceReasons = [
    {
      key: 'other',
      text: gettextCatalog.getString('Other')
    },
    {
      key: 'vacation',
      text: gettextCatalog.getString('Vacation')
    },
    {
      key: 'ill',
      text: gettextCatalog.getString('Ill')
    },
    {
      key: 'pd',
      text: gettextCatalog.getString('PD')
    }
  ];

  $scope.$watch(function () {
    return self.currentMonth;
  }, function (newMonth, oldMonth) {
    if (newMonth && newMonth.toString() !== oldMonth.toString()) {
      var newDate = moment(newMonth).startOf('month').format('YYYY-MM-DD');

      self._state.go('private.main.shiftPlanning.workingHours', {
        employeeId: self.employee._id,
        date: newDate,
        openTab: 'dailyPlanning'
      });
    }
  });
};


/**
 * helper function for ng-repeat (repeat x times)
 * @param {Number} num
 * @returns {Array}
 */
WorkingHoursCtrl.prototype.getNumber = function (num) {
  return new Array(num);
};


/**
 * check if a second time was added in the weekly planning
 * @returns {boolean}
 */
WorkingHoursCtrl.prototype.checkSecondTimeWeekly = function () {
  var secondTimeIndex = _.findIndex(this.employee.workingHours, function (workingHours) {
    return workingHours.times.length > 1;
  });

  return secondTimeIndex > -1 ? true : false;
};


/**
 * check if a second time was added in the daily planning
 * @returns {boolean}
 */
WorkingHoursCtrl.prototype.checkSecondTimeDaily = function () {
  var secondTimeIndex = _.findIndex(this.dates, function (date) {
    var times = date.times;

    if (times) {
      return date.times.length > 1;
    } else {
      return false;
    }
  });

  return secondTimeIndex > -1 ? true : false;
};


/**
 * month change handler
 * @param {String} month
 */
WorkingHoursCtrl.prototype.changeMonth = function (month) {
  var monthMoment = this._moment(this.currentMonth).startOf('month');
  monthMoment.add(month, 'month');
  this.currentMonth = monthMoment.toDate();
};


/**
 * save weekly working hours (shifts)
 */
WorkingHoursCtrl.prototype.saveWeekly = function () {
  var self = this;

  let valid = true;
  this.weekValidation = [
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1}
  ];

  // validate input
  this.employee.workingHours.forEach((day, dayIndex) => {
    if (day.available && day.times) {
      day.times.forEach((time, timeIndex) => {
        if (time.end <= time.start) {
          this.weekValidation[dayIndex].valid = false;
          this.weekValidation[dayIndex].invalidTimeIndex = timeIndex;
          valid = false;
        }

        if (timeIndex > 0) {
          if (time.start <= day.times[timeIndex - 1].end) {
            this.weekValidation[dayIndex].valid = false;
            this.weekValidation[dayIndex].invalidTimeIndex = timeIndex;
            valid = false;
          }
        }
      });
    }
  });

  if(!valid) {
    this._Notification.addErrorMessage(self.gettextCatalog.getString('Please check the marked times. Start and End time are overlapping'));
  } else {
    this._ResourceDA.update({
      _id: this.employee._id,
      workingHours: this.employee.workingHours
    })
      .then(
        function () {
          self._Notification.addSuccessMessage(self.gettextCatalog.getString('Working hours saved'));
        },
        function (error) {
          self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant save working hours (Error: {{error}})', {error: error}));
        }
      );
  }
};


/**
 * save daily working hours
 */
WorkingHoursCtrl.prototype.saveDaily = function () {
  var self = this;
  var update = {
    resource: this.employee._id,
    month: this._moment(this.currentMonth).format('YYYY-MM-DD'),
    dates: {}
  };

  _.forEach(this.dates, function (date) {
    var dateKey;

    if (date.type !== 'default') {
      dateKey = self._moment(date.date).format('YYYY-MM-DD');
      update.dates[dateKey] = date;
    }
  });


  this._ShiftPlanningDA
    .update(update)
    .then(
      function () {
        self._Notification.addSuccessMessage(self.gettextCatalog.getString('Working hours saved'));
      },
      function (error) {
        self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant save working hours (Error: {{error}})', {error: error}));
      }
    );
};


/**
 * copy a shift
 * @param {Number} index
 */
WorkingHoursCtrl.prototype.copy = function (index) {
  this._shiftClipboard.copy(this.dates[index]);
};


/**
 * paste clipboard to a date
 * @param {Number} index
 */
WorkingHoursCtrl.prototype.paste = function (index) {
  this._shiftClipboard.paste(this.dates[index]);
};


/**
 * shorthand for availability of a weekly planning day
 * @param {Number} index
 */
WorkingHoursCtrl.prototype.isAvailableWH = function (index) {
  var workingHours = this.employee.workingHours[index];
  return workingHours ? workingHours.available : false;
};


/**
 * handler if type of daily planning changed
 * @param {Number} index
 */
WorkingHoursCtrl.prototype.typeChanged = function (index) {
  var date = this.dates[index];
  var type = date.type;

  switch (type) {
    case 'default':
      var dayOfWeek = this._moment(date.date).weekday();
      date.times = _.cloneDeep(this.employee.workingHours[dayOfWeek].times);

      delete date.absence;
      delete date.reason;
      break;
    case 'custom':
      delete date.absence;
      delete date.reason;
      break;
    case 'absence':
      date.available = false;
      date.absence = true;
      date.reason = 'other';
      break;

  }
};


/**
 * add weekly secondary time
 * @param {Number} index
 */
WorkingHoursCtrl.prototype.addWeeklyTime = function (dayIndex) {
  var endTimeBefore = this.employee.workingHours[dayIndex].times[this.employee.workingHours[dayIndex].times.length - 1].end;

  this.employee.workingHours[dayIndex].times.push({
    start: ((endTimeBefore + 60) > 1440) ? 1440 : endTimeBefore + 60,
    end: ((endTimeBefore + 240) > 1440) ? 1440 : endTimeBefore + 240
  });

  this.isSecondTimeWeekly = true;
};


/**
 * remove weekly secondary time
 * @param {Number} index
 */
WorkingHoursCtrl.prototype.removeWeeklyTime = function (dayIndex, timeIndex) {
  this.employee.workingHours[dayIndex].times.splice(timeIndex, 1);
  this.isSecondTimeWeekly = this.checkSecondTimeWeekly();
};


/**
 * add daily secondary time
 * @param {Number} index
 */
WorkingHoursCtrl.prototype.addDailyTime = function (index) {
  var firstEndTime = this.dates[index].times[0].end;

  this.dates[index].times.push({
    start: ((firstEndTime + 60) > 1440) ? 1440 : firstEndTime + 60,
    end: ((firstEndTime + 240) > 1440) ? 1440 : firstEndTime + 240
  });

  this.isSecondTimeDaily = true;
};


/**
 * remove daily secondary time
 * @param {Number} index
 */
WorkingHoursCtrl.prototype.removeDailyTime = function (index) {
  this.dates[index].times.splice(1, 1);
  this.isSecondTimeDaily = this.checkSecondTimeDaily();
};


/**
 * copy opening hours to working hours
 */
WorkingHoursCtrl.prototype.applyOpeningHours = function () {
  var self = this;

  _.forEach(this.office.openingHours.regular, function (openingHour, index) {
    var workingHour = self.employee.workingHours[index];
    workingHour.available = openingHour.open;
    workingHour.times = openingHour.times;
  });

  this.isSecondTimeWeekly = this.checkSecondTimeWeekly();
};


WorkingHoursCtrl.prototype.getColspan = function () {
  var colspan = 5;

  if (!this.isSecondTimeDaily) {
    colspan = 3;
  }

  return colspan;
};

/**
 * navigate between tabs
 * @param {String} tab
 */
WorkingHoursCtrl.prototype.goTo = function (tab) {
  this._state.go('private.main.shiftPlanning.workingHours', {
    employeeId: this._stateParams.employeeId,
    date: this._stateParams.date,
    openTab: tab
  }, {notify: false});
};

module.exports = WorkingHoursCtrl;
