'use strict';

function getParameterByName (name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

var ScanQRModalCtrl = function ($scope,
                                Notification,
                                office,
                                CustomerDA,
                                gettextCatalog,
                                ModalService,
                                modalInstance) {

  this._modalInstance = modalInstance;
  this._Notification = Notification;
  this._ModalService = ModalService;
  this._CustomerDA = CustomerDA;
  this._scope = $scope;
  this.gettextCatalog = gettextCatalog;
  this.office = office;
  this.input = '';
};

ScanQRModalCtrl.prototype.close = function () {
  this._modalInstance.close();
};

ScanQRModalCtrl.prototype.inputChanged = function () {
  if (this.input) {
    if (this.input.match(/^[a-f\d]{24}$/i)) {
      this._ModalService.openTestModal(this.input);
      this.close();
    } else if (this.input.match(/^c:[a-f\d]{24}$/i)) {
      this.openNewAppointment(this.input.replace('c:', ''));
      this.close();
    } else if (this.input.includes('testId') && this.input.match(/[a-f\d]{24}/i)) {
      const testId = this.input.match(/[a-f\d]{24}/i)[0];
      this._ModalService.openTestModal(testId);
      this.close();
    } else if (this.input.includes('customerId') && this.input.match(/[a-f\d]{24}/i)) {
      const customerId = this.input.match(/[a-f\d]{24}/i)[0];
      this.openNewAppointment(customerId);
      this.close();
    } else if (getParameterByName('testId', this.input)) {
      const testId = getParameterByName('testId', this.input);

      if (testId && testId.match(/^[a-f\d]{24}$/i)) {
        this._ModalService.openTestModal(testId);
        this.close();
      }
    } else if (getParameterByName('customerId', this.input)) {
      const customerId = getParameterByName('customerId', this.input);

      if (customerId && customerId.match(/^[a-f\d]{24}$/i)) {
        this.openNewAppointment(customerId);
        this.close();
      }
    } else {
      this.input = '';
    }
  }
};

/**
 * BEGIN:VCARD
 VERSION:4.0
 N:Schiela;Thomas;;;
 FN:Thomas Schiela
 BDAY:19860619
 EMAIL;TYPE=home:thomas.schiela@gmail.com
 TEL;TYPE="cell,home":015788486007
 ADR;TYPE=home:;;Am Spreeufer 40 ;Grünheide ;;15537
 REV:20220131T111823Z
 END:VCARD
 */

ScanQRModalCtrl.prototype.openNewAppointment = function (customerId) {
  const serviceGroups = this.office.serviceGroups.filter(serviceGroup => !serviceGroup.intern && serviceGroup.services.length);
  const preselectedService = serviceGroups ? serviceGroups[0].services[0] : null;
  const roundedUp = Math.ceil(moment().minute() / 5) * 5;
  const start = moment().minute(roundedUp).second(0).toDate();
  const duration = preselectedService ? preselectedService.duration : 5;
  const cachedCustomer = customerId ? this._CustomerDA.getFromCache(customerId) : null;
  const appointment = {
    start: start,
    end: moment(start).add(duration, 'minutes').toDate(),
  };
  let serviceCount = 0;
  serviceGroups.forEach(serviceGroup => serviceCount += serviceGroup.services.length);

  if (cachedCustomer) {
    appointment.customer = {
      _id: cachedCustomer._id,
      firstname: cachedCustomer.firstname,
      lastname: cachedCustomer.lastname,
      fullname: cachedCustomer.fullname
    }
  }

  if (preselectedService && serviceCount === 1) {
    appointment.services = [{
      _id: preselectedService._id,
      name: preselectedService.name,
      duration: preselectedService.duration,
      price: preselectedService.price,
      shorthand: preselectedService.shorthand
    }];
  }

  this._ModalService.openAppointmentModal(
    appointment,
    'appointment'
  );
};

ScanQRModalCtrl.prototype.save = function () {
};

module.exports = ScanQRModalCtrl;
