'use strict';

var navigationModule = angular.module('t2g.common.directives.navigation', []);

module.exports = navigationModule;

var allowedRoles = {
  admin: ['admin'],
  manager: ['admin', 'manager'],
  employee: ['admin', 'manager', 'employee'],
  viewer: ['admin', 'manager', 'employee', 'viewer']
};

navigationModule.directive('navigation', function ($document,
                                                   $compile,
                                                   $rootScope,
                                                   NavigationSettings) {
  return {
    restrict: 'E',
    template: '' +
      '<div class="navbar-offcanvas" off-canvas>' +
      '   <a href="https://www.coachfrog.ch/de/mein-profil/"><div class="navbar-logo"></div></a>' +
      '   <ul class="navbar-offcanvas-nav">' +
      '       <li data-ng-repeat="topItem in navigation" data-ng-class="{\'nav-item-notification\': topItem.notification, \'nav-item-highlight\': topItem.highlight}">' +
      '           <navigation-item href="#" role="button">' +
      '               <i class="{{::topItem.icon}}"></i>{{::topItem.label}}' +
      '           </navigation-item>' +
      '       </li>' +
      '   </ul>' +
      '</div>',
    controller: function ($scope, $element, $interpolate) {
      function enrichNavigationItem(item) {
        if ((/{{.*}}/).test(item.label)) {
          item.label = $interpolate(item.label)($scope);
        }

        if (item.notification) {
          item.notification = $scope.$eval(item.notification);
        }

        return item;
      }

      function getNav() {
        NavigationSettings
          .get()
          .then((navigationSettings) => {
            _.map(navigationSettings, function (navigationSettingsItem) {
              navigationSettingsItem = enrichNavigationItem(navigationSettingsItem);

              if (navigationSettingsItem.subMenu) {
                _.map(navigationSettingsItem.subMenu, enrichNavigationItem);
              }

              return navigationSettingsItem;
            });

            $scope.navigation = navigationSettings;
            $scope.checkRole = function (minimumRole) {
              var role = _.get($scope.user, 'role');
              var authorized = false;

              if (role && allowedRoles[minimumRole].indexOf(role) !== -1) {
                authorized = true;
              }

              return authorized;
            };
          });
      }

      getNav();
    },
    link: function ($scope, $element) {
    }
  };
});

navigationModule.directive('navigationItem', function ($state, ModalService) {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    template: '<a ng-transclude></a>',
    link: function ($scope, $element) {
      var subMenuContainer;
      var subMenuList;

      if (($scope.topItem.role && !$scope.checkRole($scope.topItem.role))
        || $scope.topItem.show && !$scope.$eval($scope.topItem.show)) {
        $element.remove();
        return;
      }

      if ($scope.topItem.subMenu) {
        $element.addClass('has-submenu');
        $element[0].href = '#';

        // generate sub menu
        subMenuContainer = document.createElement('div');
        subMenuContainer.setAttribute('class', 'navbar-submenu-wrapper');
        subMenuList = document.createElement('ul');

        _.forEach($scope.topItem.subMenu, function (subMenuItem) {
          if (subMenuItem.role && !$scope.checkRole(subMenuItem.role)) {
            return;
          }

          var subMenuListItem = document.createElement('li');
          var subMenuItemLink = document.createElement('a');
          var subMenuItemIcon;
          var subMenuItemText = document.createTextNode(subMenuItem.label);

          if (subMenuItem.notification) {
            subMenuListItem.classList.add('nav-item-notification');
          }

          if(subMenuItem.highlight) {
            subMenuListItem.classList.add('nav-item-highlight');
          }

          if (subMenuItem.icon) {
            subMenuItemIcon = document.createElement('i');
            subMenuItemIcon.setAttribute('class', 'icon-star');
            subMenuItemLink.appendChild(subMenuItemIcon);
          }

          if (subMenuItem.modal) {
            subMenuItemLink.href = '#';
            subMenuItemLink.addEventListener('click', (event) => {
              ModalService[subMenuItem.modal](subMenuItem.modalData);
              event.preventDefault();
              event.stopPropagation();
            });
          } else if (subMenuItem.click) {
            subMenuItemLink.href = '#';
            subMenuItemLink.addEventListener('click', (event) => {
              subMenuItem.click();
              event.preventDefault();
              event.stopPropagation();
              $('.in').removeClass('in');
            });
          } else if (subMenuItem.state) {
            subMenuItemLink.href = $state.href(subMenuItem.state, subMenuItem.stateParams, { inherit: false });
          }

          if (subMenuItem.attributes) {
            Object
              .keys(subMenuItem.attributes)
              .forEach((key) => {
                subMenuItemLink.setAttribute(key, subMenuItem.attributes[key]);
              });
          }

          subMenuItemLink.appendChild(subMenuItemText);
          subMenuListItem.appendChild(subMenuItemLink);
          subMenuList.appendChild(subMenuListItem);
        });

        subMenuContainer.appendChild(subMenuList);
        $element[0].parentNode.appendChild(subMenuContainer);
      } else if ($scope.topItem.state) {
        if ($scope.topItem.state !== 'private.main.calendar') {
          $element[0].href = $state.href($scope.topItem.state, $scope.topItem.stateParams, { inherit: false });
        } else {
          $element[0].href = '#/private/main/calendar';
        }
      }

      _.forEach($scope.topItem.attributes, function (value, key) {
        $element[0].setAttribute(key, value);
      });

      $element.on('click', function (event) {
        if ($('.navbar').is(':visible')) {
          return;
        }

        if ($(subMenuContainer).hasClass('in')) {
          $(subMenuContainer).removeClass('in');
          $('body').removeClass('nav-open');
        } else {
          $('.in').removeClass('in');
          if($scope.topItem.subMenu) {
            $(subMenuContainer).addClass('in');
            $('body').addClass('nav-open');
          }
        }

        event.stopPropagation();

        if ($scope.topItem.subMenu) {
          event.preventDefault();
        }
      });
    }
  };
});


