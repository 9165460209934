function ServiceLocationModalCtrl(office, gettextCatalog, Notification, OfficeDA, modalInstance) {
  this._modalInstance = modalInstance;
  this._gettextCatalog = gettextCatalog;
  this._Notification = Notification;
  this._OfficeDA = OfficeDA;
  this.office = office;
  this.defaultLocation = {
    label: office.address.formatted,
    type: 'address',
    _id: 'at-office'
  };
  this.types = [
    {
      id: 'address',
      label: gettextCatalog.getString('Address') // Adresse
    },
    {
      id: 'at-customer',
      label: gettextCatalog.getString('On-site with the customer') // 'Vor-Ort beim Kunden'
    },
    {
      id: 'remote',
      label: gettextCatalog.getString('Online meeting') // 'Telekonferenz / Online-Präsentation'
    }
  ];
}

ServiceLocationModalCtrl.prototype.addRow = function () {
  this.office.serviceLocations.push({
    label: '',
    type: 'address'
  });
};

ServiceLocationModalCtrl.prototype.removeRow = function (index) {
  this.office.serviceLocations.splice(index, 1);
};

ServiceLocationModalCtrl.prototype.close = function () {
  this._modalInstance.close();
};

ServiceLocationModalCtrl.prototype.abort = function () {
  this.close();
};

ServiceLocationModalCtrl.prototype.save = function () {
  this._OfficeDA
    .update(this.office)
    .then(() => {
      this._Notification.addSuccessMessage(this._gettextCatalog.getString('Service location saved'));
      this.close();
    })
    .catch((error) => {
      this._Notification.addErrorMessage({text: error});
    });
};

module.exports = ServiceLocationModalCtrl;
