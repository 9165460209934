'use strict';

var angularModule = angular.module('t2g.common.directives.offCanvas', []);

module.exports = angularModule;

angularModule.directive('offCanvas', function () {
    return {
        restrict: 'A',
        link: function ($scope, $element) {
            var subMenus = $element.find('.has-submenu');


            $(function () {
                $('.has-submenu').click(function (event) {
                    // submenu is always open on mobile devices
                    if ($('.navbar').is(':visible')) {
                        return;
                    }


                    var $that = $(this);
                    var target = $that.data('target');

                    if ($(target).hasClass('in')) {
                        $(target).removeClass('in');
                        $('body').removeClass('nav-open');
                    } else {
                        $('.in').removeClass('in');
                        $(target).addClass('in');
                        $('body').addClass('nav-open');
                    }

                    event.stopPropagation();
                    event.preventDefault();
                });

                // if mouse will be clicked outside navigation, close submenu
                $(document).click(function () {
                    $('.in').removeClass('in');
                    $('body').removeClass('nav-open');
                });

                // handle navigation on mobile devices
                $('.navbar-menu-toggle').click(function (event) {
                    $('body').toggleClass('nav-open');

                    event.stopPropagation();
                });
            });
        }
    };
});
