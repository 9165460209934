'use strict';

var angularModule = angular.module('t2g.common.directives.fab', []);
var SHOW_CLASS = 'show';

module.exports = angularModule;

angularModule.directive('fabContainer', function($interval) {
    return {
        restrict: 'E',
        controller: function($scope) {
            var fabItems = $scope.fabItems = [];

            this.addFabItem = function(fabItem) {
                fabItems.push(fabItem);
            };
        },
        link: function($scope, $element, $attributes) {
            var show = false;
            var fixedOnXS = $element.hasClass('floating-button--fixed-on-xs');
            var isMobile = $element.css('position') === 'fixed';
            var reverse = !fixedOnXS || fixedOnXS && isMobile;
            var $fabBtn = $element.find('button').get(0);

            var handleClick = function() {
                var index = 0;
                var len = $scope.fabItems.length;
                var items = reverse ? $scope.fabItems.reverse() : $scope.fabItems;
                var fn = function() {
                    if(len > 1){
                        items[index++].toggleItem();
                    }
                };

                if (show) {
                    angular.forEach(items, function(item) {
                        item.toggleItem();
                    });
                } else {
                    $interval(fn, 75, len, true);
                }

                show = !show;
            };

            $fabBtn.addEventListener('click', handleClick);

            $scope.$on('$destroy', function() {
                $fabBtn.removeEventListener('click', handleClick);
            });
        }
    };
});

angularModule.directive('fabItem', function() {
    return {
        restrict: 'E',
        require: '^fabContainer',
        scope: {},
        link: function($scope, $element, $attributes, fabContainerController) {
            fabContainerController.addFabItem($scope);

            $scope.toggleItem = function() {
                $element.toggleClass(SHOW_CLASS);
            };
        }
    };
});
