'use strict';

var customFieldModule = angular.module('t2g.customFields', [
  'gettext',
  'ui.router',
  require('t2g.common.services.notificationService').name
]);

module.exports = customFieldModule;

customFieldModule.config(function($stateProvider) {
  $stateProvider
    .state('private.main.customFields', {
      url: '/customFields',
      title: 'Custom Fields',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/customFields/CustomFieldsView.html?v=3.82.3',
      controller: 'CustomFieldCtrl as CustomFieldCtrl',
      resolve: {
        officeResolve: function(OfficeDA) {
          return OfficeDA.get();
        }
      }
    });

});


/**
 * @param $scope
 * @param officeResolve
 * @param Notification
 * @constructor
 */
var CustomFieldCtrl = function($scope,
                               gettextCatalog,
                               Notification,
                               OfficeDA,
                               ModalService,
                               officeResolve) {

  var self = this;
  this.showPremiumNotification = $scope.subscription.onlyBasicFunctions;
  this.scope_ = $scope;
  this.scope_.office = officeResolve;
  this.scope_.office.settings.customFields = this.scope_.office.settings.customFields || {
    'customer': [],
    'appointment': []
  };
  this._OfficeDA = OfficeDA;
  this.Notification_ = Notification;
  this.gettextCatalog = gettextCatalog;
  this._ModalService = ModalService;


};


/**
 * @param {object} field
 */
CustomFieldCtrl.prototype.select = function(field, type, index) {
  this.scope_.currentField = field;
  this._ModalService.openCustomFieldsModal({
    type: type,
    index: index,
    field: field
  }, this.reload.bind(this));
};


/**
 * @param {string} type
 */
CustomFieldCtrl.prototype.add = function(type) {
  var self = this;
  var newField = {
    label: self.gettextCatalog.getString('New field'),
    required: true,
    booking: true,
    type: 'textfield'
  };
  self.scope_.office.settings.customFields[type].push(newField);
  self.select(newField, type, self.scope_.office.settings.customFields[type].length - 1);
};


/**
 * save custom fields
 */
CustomFieldCtrl.prototype.save = function(type) {
  var self = this;

  this._OfficeDA
    .update(this.scope_.office)
    .then(() => {
      if (type === 'save' || !type) {
        self.Notification_.addSuccessMessage(self.gettextCatalog.getString('Custom field saved'));
      } else if (type === 'delete' || !type) {
        self.Notification_.addSuccessMessage(self.gettextCatalog.getString('Custom field removed'));
      }
    })
    .catch((error) => {
      self.Notification_.addErrorMessage(self.gettextCatalog.getString('Cant save custom field'));
    });
};

CustomFieldCtrl.prototype.moveField = function(index, type, direction) {
  if (direction > 0) {
    if (index > 0) {
      this.scope_.office.settings.customFields[type].move(index, (index - 1));
    }
  } else {
    if (index < (this.scope_.office.settings.customFields[type].length - 1)) {
      this.scope_.office.settings.customFields[type].move(index, (index + 1));
    }
  }
  this.save();
};

CustomFieldCtrl.prototype.reload = function(response) {
  if (response.type === 'save') {
    this.scope_.office.settings.customFields[response.data.type][response.data.index] = response.data.field;
    this.save('save');
  } else if (response.type === 'close' && (response.data.field && !response.data.field.name)) {
    this.scope_.office.settings.customFields[response.data.type].splice(response.data.index, 1);
  } else if (response.type === 'delete' && (response.data.field)) {
    this.scope_.office.settings.customFields[response.data.type].splice(response.data.index, 1);
    this.save('delete');
  }
};


customFieldModule.controller('CustomFieldCtrl', CustomFieldCtrl);
customFieldModule.controller('CustomFieldsModalCtrl', require('./CustomFieldsModalCtrl'));
