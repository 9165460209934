'use strict';

var CashModalCtrl = function($scope,
                             $state,
                             cash,
                             services,
                             LocalStorage,
                             gettextCatalog,
                             customLabels,
                             office,
                             taxes,
                             customers,
                             Notification,
                             AccountingDA,
                             DatepickerSettings,
                             modalInstance) {
  var self = this;
  this._state = $state;
  this._modalInstance = modalInstance;
  this._LocalStorage = LocalStorage;
  this._AccountingDA = AccountingDA;
  this._Notification = Notification;
  this.taxes = taxes;
  this.gettextCatalog = gettextCatalog;
  this.office = office;
  this.services = services;
  this.customers = customers;
  this.customerLabel = gettextCatalog.getString(customLabels.customer.toLowerCase());
  this.discount = 0;
  this.model = cash || {positions: [], total: 0, discount: 0, discountMode: 'absolute'};
  this.model.cashed = new Date();
  this.model.type = 'cashUp';
  this.model.discountMode = this.model.discountMode || 'absolute';
  this.dtpOpened = false;
  this.dtpOptions = DatepickerSettings.default;
  this.paymentsMethods = {
    apple: 'Apple Pay',
    cash: gettextCatalog.getString('Cash'),
    ec: gettextCatalog.getString('EC-Card'),
    es: gettextCatalog.getString('Einzahlungsschein'),
    cc: gettextCatalog.getString('Credit Card'),
    coupon: gettextCatalog.getString('Coupon'),
    dd: gettextCatalog.getString('Direct Debit'),
    pp: 'PayPal',
    pc: gettextCatalog.getString('Post-Card'),
    invoice: gettextCatalog.getString('Invoice'),
    twint: gettextCatalog.getString('TWINT'),
  };

  if (this.model.customer) {
    this.selectedCustomer = _.find(this.customers, {_id: this.model.customer});
  }

  $scope.$watch(function() {
    return self.selectedCustomer;
  }, this.customerChanged.bind(this));

  $scope.$watch(function() {
    return self.model.positions;
  }, this.calc.bind(this), true);

  this.calc();
};

CashModalCtrl.prototype.close = function(storeCashInSession) {
  if (storeCashInSession) {
    this._LocalStorage.set('openCash', this.model);
  } else {
    this._LocalStorage.remove('openCash');
  }

  this._modalInstance.close();
};

CashModalCtrl.prototype.openCustomerModal = function(customerId) {
  this.close(true);
  this._state.go('private.main.customerProfile', {customerId: customerId});
};

CashModalCtrl.prototype.customerChanged = function() {
  if (angular.isObject(this.selectedCustomer)) {
    this.model.customer = this.selectedCustomer._id;
  }
};

CashModalCtrl.prototype.save = function() {
  var self = this;

  if (!this.model.paymentMethod) {
    return self
      ._Notification
      .addErrorMessage(self.gettextCatalog.getString('Please choose a payment Method'));
  } else if (this.model.total === 0) {
    return self
      ._Notification
      .addErrorMessage(self.gettextCatalog.getString('Please add positions first'));
  } else {
    this._AccountingDA
      .createCashBookEntry(this.model)
      .then((response) => {

        if(this.office.isHelloCashActivated && this.office.settings.accounting.printReceipt) {
          window.open(`https://myhellocash.com/intern/cash-register/invoice/print?iid=${response.invoice.helloCashId}`, '_blank');
        }

        self.close();
        self._Notification.addSuccessMessage(self.gettextCatalog.getString('Cashbook Entry created'));
      })
      .catch(function(error) {
        self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant create Cashbook Entry (Error: {{error}})', {error: error}));
      });
  }
};

CashModalCtrl.prototype.changeQuantity = function(item, quantity) {
  item.quantity = item.quantity + quantity;
  item.quantity = Math.max(item.quantity, 1);
};

CashModalCtrl.prototype.removePosition = function(index) {
  this.model.positions.splice(index, 1);
};

CashModalCtrl.prototype.servicePicked = function(service) {
  const defaultTax = service && service.accounting && service.accounting.tax !== undefined ? service.accounting.tax : this.taxes[0].value;

  if (service._id === 'freePosition') {
    this.model.positions.push({
      name: '',
      type: 'free',
      quantity: 1,
      tax: defaultTax
    });
  } else {
    this.model.positions.push({
      _id: service._id,
      name: service.name,
      price: service.price || 0,
      quantity: 1,
      tax: defaultTax
    });
  }

  this.currentService = null;
};

CashModalCtrl.prototype.selectDiscountMode = function(discountMode) {
  this.model.discountMode = discountMode;
  this.calc();
};

CashModalCtrl.prototype.calc = function() {
  var convertedGiven = this.given ? parseFloat(this.given) : 0;
  this.model.total = 0;
  this.model.overall = 0;
  this.model.discount = 0;
  this.return = 0;

  _.forEach(this.model.positions, function(position) {
    var price = position.price ? parseFloat(position.price) : 0;
    var quantity = position.quantity || 1;

    if (!isNaN(price)) {
      this.model.total += price * quantity;
      this.model.overall += price * quantity;
    }
  }.bind(this));

  // calc discount
  if(this.discount) {
    if (this.model.discountMode === 'absolute') {
      this.model.discount = this.discount;
    } else {
      this.model.discount = ((this.discount)/100)*this.model.total;
    }

    this.model.total = this.model.overall - this.model.discount;
  }

  if (convertedGiven && !isNaN(convertedGiven)) {
    this.return = Math.max(convertedGiven - this.model.total, 0);
  }
};

CashModalCtrl.prototype.getMaxDiscount = function () {
  if (this.model.discountMode === 'absolute') {
    return this.model.overall;
  } else {
    return 100;
  }
};

module.exports = CashModalCtrl;
