module.exports = function ($scope,
                           gettextCatalog,
                           Notification,
                           OfficeDA,
                           ServiceGroupModal) {
  $scope.groups = [];

  OfficeDA
    .get()
    .then((response) => {
      $scope.office = response;
      $scope.groups = $scope.office.serviceGroups || [];
    });


  /**
   * add a new group to list
   */
  $scope.addGroupRow = function () {
    $scope.groups.push({
      intern: false,
      name: '',
      services: []
    });
  };

  $scope.moveUp = function (arr, index) {
    if (index > 0) {
      arr.move(index, (index - 1));
    }
  };

  $scope.moveDown = function (arr, index) {
    if (index < (arr.length - 1)) {
      arr.move(index, (index + 1));
    }
  };


  /**
   * remove a group from list
   * @param group
   */
  $scope.removeGroupRow = function (group) {
    var index = $scope.groups.indexOf(group);

    if (index > -1) {
      if (index === 0) {
        Notification.addErrorMessage(gettextCatalog.getString('There should be at least one service group'));
      } else {
        for (var i = 0; i < $scope.groups[index].services.length; i++) {
          $scope.groups[0].services.push($scope.groups[index].services[i]);
        }

        $scope.groups.splice(index, 1);

        OfficeDA
          .update($scope.office)
          .then(() => {
            Notification.addSuccessMessage(gettextCatalog.getString('service group "{{name}}" deleted', { name: group.name }));
          })
          .catch((error) => {
            Notification.addErrorMessage({ text: error });
          });
      }
    }
  };


  /**
   * save to rest webservice
   */
  $scope.save = function () {
    OfficeDA
      .update($scope.office)
      .then(() => {
        Notification.addSuccessMessage(gettextCatalog.getString('service groups saved'));
        ServiceGroupModal.close();
      })
      .catch((error) => {
        Notification.addErrorMessage({ text: error });
      });
  };

  /**
   * close the modal view
   */
  $scope.abort = function () {
    ServiceGroupModal.close();
  }
};
