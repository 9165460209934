var GoogleSyncCtrl = function ($scope,
                               $state,
                               $window,
                               googleSync,
                               Notification,
                               GoogleSyncDA,
                               gettextCatalog,
                               resources) {

  this.showPremiumNotification = $scope.subscription.onlyBasicFunctions;
  this._Notification = Notification;
  this._GoogleSyncDA = GoogleSyncDA;
  this._window = $window;
  this.gettextCatalog = gettextCatalog;
  this.resources = resources;
  this.responseError = false;

  if (!googleSync) {
    this.responseError = true;
  } else if (googleSync.success === false) {
    this.responseError = true;
  } else {
    this.sync = googleSync.sync || {};
    this.sync.googleMapping = this.sync.googleMapping || {};
    this.calendarList = googleSync.payload;

    this.calendarList.forEach(function (calendar) {
      calendar.id = calendar.id.replace(/\./g, '__');
    });
  }

  this.reload = function () {
    $state.reload();
  };
};

/**
 *  speichere mappings
 *
 *  funktion implementieren die checkt ob es einen change gab
 *  initales laden der calendar
 */

GoogleSyncCtrl.prototype.connect = function () {
  this._window.open('/api/v2/auth/google', 'google_auth', 'width=450, height=500, scrolling=yes, resizable=no');
};


GoogleSyncCtrl.prototype.disconnect = function () {
  var self = this;

  this._GoogleSyncDA
    .revokeAccess()
    .then(function () {
      self.calendarList = [];
      self.responseError = true;
      self._Notification.addSuccessMessage(self.gettextCatalog.getString('Disconnected from Google'));
    }, function (error) {
      self._Notification.addErrorMessage({ text: error });
    });
};

GoogleSyncCtrl.prototype.save = function () {
  var self = this;

  Object
    .keys(this.sync.googleMapping)
    .forEach((calendarId) => {
      let resourceId = this.sync.googleMapping[calendarId].resource;
      let resource = this.resources.find(r => r._id === resourceId);
      let resourceType = resource ? resource.type : 'employee';

      this.sync.googleMapping[calendarId].resourceType = resourceType;
    });

  self._GoogleSyncDA
    .update(this.sync)
    .then(function () {
      self._Notification.addSuccessMessage(self.gettextCatalog.getString('Calendar mapping saved'));
    }, function (error) {
      self._Notification.addErrorMessage({ text: error });
    });
};

GoogleSyncCtrl.prototype.resourceChanged = function (calendarId) {
  delete this.sync.googleMapping[calendarId].primary;
};

GoogleSyncCtrl.prototype.checkPrimary = function (calendarId) {
  var mappings = this.sync.googleMapping;
  var checkMapping = mappings[calendarId];

  if (checkMapping) {
    var resource = mappings[calendarId].resource;

    _.forEach(mappings, function (mapping, key) {
      if (key !== calendarId && mapping.resource === resource) {
        delete mapping.primary;
      }
    });
  }
};

module.exports = GoogleSyncCtrl;

