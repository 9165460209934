var angularModule = angular.module('t2g.common.directives.pwCheck', []);

module.exports = angularModule;

angularModule.directive('pwCheck', function () {
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            var firstPassword = '#' + attrs.pwCheck;
            elem.add(firstPassword).on('keyup', function () {
                scope.$apply(function () {
                    var v = elem.val() === $(firstPassword).val();
                    ngModel.$setValidity('pwmatch', v);
                });
            });
        }
    }
});
