var angularModule = angular.module('t2g.common.data-access.account', []);

module.exports = angularModule;

angularModule.factory('AccountResource', function ($resource, settings) {
  var resourceUrl = settings.apiBase + '/api/v2/user/:subResource0';

  var resource = $resource(resourceUrl, {}, {
    changePassword: { method: 'POST', isArray: false, params: { subResource0: 'changePassword' } }
  });

  return resource;
});

angularModule.factory('AccountDA', function (AccountResource) {
  return {
    changePassword
  };

  function changePassword(user) {
    return AccountResource
      .changePassword(user)
      .$promise;
  }
});

