var angularModule = angular.module('t2g.common.directives.toogleLabel', []);

module.exports = angularModule;

angularModule.directive('toggleLabel', function ($timeout) {
    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {
            ngModel: '=',
            trueTitle: '=',
            falseTitle: '='
        },
        link: function ($scope, $element) {
            $timeout(function () {
                if (!$scope.ngModel) {
                    $scope.ngModel = false;
                }
                setTitle();
            });

            $element.click(function () {
                $scope.$apply(function () {
                    $scope.ngModel = !$scope.ngModel;
                });
                setTitle();
            });

            function setTitle() {
                if (!$scope.trueTitle || !$scope.falseTitle) return;

                if ($scope.ngModel) {
                    $element.attr('title', $scope.trueTitle).tooltip('fixTitle');
                } else {
                    $element.attr('title', $scope.falseTitle).tooltip('fixTitle');
                }

            }
        }
    }
});
