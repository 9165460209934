var angularModule = angular.module('t2g.common.services.settingsHolderService', []);

module.exports = angularModule;

angularModule.factory('Settings', function() {
    var _settings = {};

    return {
        get: function() {
            return _settings;
        },
        getKey: function(key) {
            return _settings[key];
        },
        set: function(settings) {
            _settings = settings;
        },
        setKey: function(key, value) {
            _settings[key] = value;
        }
    }
});

