'use strict';

var SubscriptionCtrl = function (gettextCatalog,
                                 LoadingIndicatorService,
                                 Notification,
                                 SubscriptionDA,
                                 $state,
                                 $stateParams,
                                 $filter,
                                 $window,
                                 employees,
                                 office,
                                 OfficeDA,
                                 countries,
                                 settings,
                                 subscription,
                                 ModalService,
                                 BillwerkService,
                                 LocalStorage) {

  this._SubscriptionDA = SubscriptionDA;
  this._LoadingIndicatorService = LoadingIndicatorService;
  this._BillwerkService = BillwerkService;
  this._Notification = Notification;
  this._window = $window;
  this._ModalService = ModalService;
  this.gettextCatalog = gettextCatalog;
  this.employees = employees;
  this.openTab = $stateParams.openTab;
  this.countries = countries;
  this.office = office;
  this.office.settings.reminder = this.office.settings.reminder || {};
  this.office.settings.reminder.sms = this.office.settings.reminder.sms || { enabled: false };
  this._OfficeDA = OfficeDA;
  this._settings = settings;
  this._state = $state;
  this._filter = $filter;
  this.invoices = [];
  this.isLoading = false;
  this.contract = { balance: 0 };
  this.subscription = subscription;
  this.subscriptionExists = !this.subscription.empty;
  this.countryCode = _.get(office, 'address.countryCode', 'de').toLowerCase();
  this.products = this.getProducts(this.countryCode);
  this.methods = this._settings.paymentMethods[this.countryCode] || [this._settings.billwerk.bearer.invoice, this._settings.billwerk.bearer.payPal];
  this._LocalStorage = LocalStorage;
  this.isSubscriptionEmpty = subscription.empty || !subscription.billwerk;
  this._smsSettingsChanged = false;
  this.billingSubmitted = false;
  this.paymentMethodSubmitted = false;
  this.model = {
    interval: _.get(subscription, 'interval', 'yearly'),
    package: _.get(subscription, 'package', 'single'),
    planVariantId: _.get(subscription, 'billwerk.planVariantId', this.products.basic),
    additionalEmployees: _.get(subscription, 'billwerk.employees.amount', 0),
    additionalLicenses: _.get(subscription, 'billwerk.licences.amount', 0),
    smsComponent: subscription.smsComponent || false
  };
  this.billingModel = subscription.billingInformation;
  this.paymentModel = subscription.paymentInformation || {};
  this.subscriptionProcessModel = this._LocalStorage.get('SubscriptionProcess');
  this.showDiscount = false;

  if (_.get(this.subscriptionProcessModel, 'subscription')) {
    _.merge(this.model, this.subscriptionProcessModel.subscription);
  }

  this.isBasicPackage = this.model.package === 'basic';
  this.isPremiumPackage = this.model.package === 'premium';
  this.isPlusPackage = this.model.package === 'plus';
  this.isSinglePackage = this.model.package === 'single';
  this.isEnterprisePackage = this.model.package === 'enterprise';
  this.isSchnelltestPackage = this.model.package === 'schnelltest';
  this.isSchnelltest2Package = this.model.package === 'schnelltest2';
  this.isBasicVisible = this.isBasicPackage;
  this.isCoronaReportActivated = _.get(office, 'settings.covidTest.provider') === 'termin2go' && _.get(office, 'settings.covidTest.enabled');
  this.changeEmployees(0);

  // TODO: enable up and downgrades (check number of employees)
  // TODO: disable packages if to many employees
  // TODO: preselect package
  this.isSchnelltestVisible = this.isSchnelltestPackage;
  this.isSchnelltest2Visible = !this.isSchnelltestPackage && (this.isCoronaReportActivated || this.isSchnelltest2Package);
  this.isSingleVisible = (this.isSubscriptionEmpty || this.isBasicPackage || this.isSinglePackage) && !this.isCoronaReportActivated;
  this.isPlusVisible = (this.isSubscriptionEmpty || this.isBasicPackage || this.isSinglePackage || this.isPlusPackage) && !this.isCoronaReportActivated;
  this.isEnterpriseVisible = (this.isSubscriptionEmpty || this.isBasicPackage || this.isSinglePackage || this.isPlusPackage || this.isEnterprisePackage) && !this.isCoronaReportActivated;
  this.isPremiumVisible = this.isPremiumPackage;

  if (this.isSchnelltest2Visible) {
    this.model.package = 'schnelltest2';
  }
};

SubscriptionCtrl.prototype.getProducts = function (countryCode) {
  if (countryCode.toUpperCase() === 'CH') {
    return this._settings.billwerk.products.chf;
  } else {
    return this._settings.billwerk.products.eur;
  }
};

SubscriptionCtrl.prototype.isEmployeeNotificationVisible = function () {
  return this.employees.length > this.model.additionalEmployees + 1;
};

SubscriptionCtrl.prototype.getNeededLicenceCount = function () {
  return (this.employees.length - 1) - this.model.additionalEmployees;
};

SubscriptionCtrl.prototype.switchTab = function (tab) {
  this._state.transitionTo('private.main.subscription', {
    openTab: tab
  }, { notify: false, reloadOnSearch: false });

  if (tab === 'invoices') {
    this._SubscriptionDA
      .getInvoices()
      .then((invoices) => {
        this.invoices = invoices;
      })
      .catch(() => {
        this._Notification.addErrorMessage(this.gettextCatalog.getString('An Error occured. Invoices cant be loaded. Please contact support.'));
      });

    this._SubscriptionDA
      .getContract()
      .then((contract) => {
        this.contract.balance = contract.Balance;
      })
      .catch(() => {
        this
          ._Notification
          .addErrorMessage(this.gettextCatalog.getString('Cant load contract data'));
      });
  }
};

SubscriptionCtrl.prototype.getPrice = function () {
  if (!this.prices) {
    return;
  }

  const pkg = this.model.package;
  const prices = (!this.subscriptionExists || this.isBasicPackage) && this.prices.discount ? this.prices.discount : this.prices[pkg];

  if (pkg === 'basic') {
    return '0,00';
  }

  if (this.model.interval === 'monthly') {
    return this._filter('number')(prices.monthly.perMonth, '2');
  } else {
    return this._filter('number')(prices.yearly.perYear, '2');
  }
};

SubscriptionCtrl.prototype.changeEmployees = function (amount) {
  var self = this;
  var newAmount = this.model.additionalEmployees + amount;
  this.model.additionalEmployees = Math.max(newAmount, 0);
  this._LoadingIndicatorService.show();

  this._SubscriptionDA
    .getPrice(self.countryCode, this.model.additionalEmployees + 1, this.model.additionalLicenses + 1)
    .then(function (prices) {
      self.prices = prices;
      self._LoadingIndicatorService.hide();
      self.showDiscount = (!self.subscriptionExists || self.isBasicPackage) && (self.prices.premium.discount || self.prices.plus.discount);
    })
    .catch(function () {
      self._LoadingIndicatorService.hide();
      self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant load Pricing data. Please contact support.'));
    });
};

SubscriptionCtrl.prototype.changeLicenses = function (amount) {
  var self = this;
  var newAmount = this.model.additionalLicenses + amount;
  this.model.additionalLicenses = Math.max(newAmount, 0);
  this._LoadingIndicatorService.show();

  this._SubscriptionDA
    .getPrice(self.countryCode, this.model.additionalEmployees + 1, this.model.additionalLicenses + 1)
    .then(function (prices) {
      self.prices = prices;
      self._LoadingIndicatorService.hide();
      self.showDiscount = (!self.subscriptionExists || self.isBasicPackage) && (self.prices.premium.discount || self.prices.plus.discount);
    })
    .catch(function () {
      self._LoadingIndicatorService.hide();
      self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant load Pricing data. Please contact support.'));
    });
};

SubscriptionCtrl.prototype.isPeriodChangeable = function () {
  let interval = _.get(this.subscription, 'interval');

  // return !interval || interval === 'monthly';
  return true;
};

SubscriptionCtrl.prototype.next = function (save) {
  var self = this;
  var oldInterval = _.get(this.subscription, 'interval');
  var newInterval = _.get(this.model, 'interval');
  var oldPackage = _.get(this.subscription, 'package');

  if (oldPackage === 'premium' && oldInterval === 'yearly' && newInterval === 'monthly') {
    this._Notification.addErrorMessage(self.gettextCatalog.getString('You cant change payment period from yearly to monthly. Please contact our support: info@termin2go.com'));
    return;
  }

  if (this.model.package === 'premium' && this.isEmployeeNotificationVisible()) {
    return;
  }

  if (this.model.package === 'basic') {
    this.model.planVariantId = this.products.basic;
  } else if (this.model.package === 'premium') {
    if (this.model.interval === 'monthly') {
      this.model.planVariantId = this.products.premium_monthly;
    } else {
      this.model.planVariantId = this.products.premium_yearly;
    }
  } else if (this.model.package === 'single') {
    if (this.model.interval === 'monthly') {
      this.model.planVariantId = this.products.single_monthly;
    } else {
      this.model.planVariantId = this.products.single_yearly;
    }
  } else if (this.model.package === 'enterprise') {
    if (this.model.interval === 'monthly') {
      this.model.planVariantId = this.products.enterprise_monthly;
    } else {
      this.model.planVariantId = this.products.enterprise_yearly;
    }
  } else if (this.model.package === 'schnelltest') {
    if (this.model.interval === 'monthly') {
      this.model.planVariantId = this.products.schnelltest_monthly;
    } else {
      this.model.planVariantId = this.products.schnelltest_yearly;
    }
  } else if (this.model.package === 'schnelltest2') {
    if (this.model.interval === 'monthly') {
      this.model.planVariantId = this.products.schnelltest2_monthly;
    } else {
      this.model.planVariantId = this.products.schnelltest2_yearly;
    }
  } else {
    if (this.model.interval === 'monthly') {
      this.model.planVariantId = this.products.plus_monthly;
    } else {
      this.model.planVariantId = this.products.plus_yearly;
    }
  }


  if (!save) {
    let voucher = '';

    if (this.model.package === 'plus') {
      if (this.model.interval === 'monthly') {
        this.model.componentId = this.products.branch_monthly;
      } else {
        this.model.componentId = this.products.branch_yearly;
      }
    } else if (this.model.package === 'premium') {
      this.model.componentId = this.products.employee;
    }

    this._LocalStorage.set('SubscriptionProcess', {
      subscription: this.model,
      activateSMS: this._smsSettingsChanged && this.office.settings.reminder.sms.enabled
    });
    this._state.transitionTo('private.main.billingDetails', { voucher });
  } else {
    self.isLoading = true;

    let componentId = this.products.employee;
    let params = {
      componentId: componentId,
      planVariantId: self.model.planVariantId
    };

    if (this.model.package === 'plus') {
      params.additionalLicenses = self.model.additionalLicenses;

      if (this.model.interval === 'monthly') {
        params.componentId = this.products.branch_monthly;
      } else {
        params.componentId = this.products.branch_yearly;
      }
    } else if (this.model.package === 'premium') {
      params.additionalEmployees = self.model.additionalEmployees;
      params.componentId = this.products.employee;
    }

    this._SubscriptionDA
      .upDownGrade(params)
      .then(function () {
        self.isLoading = false;
        self._Notification.addSuccessMessage(self.gettextCatalog.getString('Subscription changed successfully'));
        if (self._smsSettingsChanged) {
          self._OfficeDA.update(self.office);
        }
      })
      .catch(function (error) {
        self.isLoading = false;
        self._Notification.addErrorMessage({ text: error });
      });
  }
};

SubscriptionCtrl.prototype.saveBilling = function () {
  var self = this;
  var country = _.find(this.countries, { code: this.billingModel.country.toUpperCase() });
  var locale = country ? country.language[0] : 'de-DE';

  var customer = {
    emailAddress: this.billingModel.email,
    firstName: this.billingModel.firstName,
    lastName: this.billingModel.lastName,
    tag: this.office._id,
    companyName: this.billingModel.companyName,
    address: {
      street: this.billingModel.street,
      postalCode: this.billingModel.postal,
      city: this.billingModel.city,
      country: this.billingModel.country.toUpperCase()
    },
    locale: locale
  };

  if (this.billingModel.vatID) {
    customer.vatId = this.billingModel.vatID;
  }

  this.billingSubmitted = true;
  this.isLoading = true;

  if (this.BillingDetailsForm.$valid) {
    this._BillwerkService
      .changeBillingInformation(customer)
      .then(function (response) {
        self.isLoading = false;

        if (response.Message) {
          self._Notification.addErrorMessage({ text: response.Message });
        } else {
          self._Notification.addSuccessMessage(self.gettextCatalog.getString('Changed Billing Details successfully'));
        }
      })
      .catch(function (error) {
        self.isLoading = false;
        self._Notification.addErrorMessage({ text: error.Message || error });
      });
  }
};

SubscriptionCtrl.prototype.loadInvoice = function (invoice) {
  var self = this;

  this._SubscriptionDA
    .getInvoiceLink(invoice.id)
    .then(function (invoiceLink) {
      self._window.open(invoiceLink);
    })
    .catch(function () {
      self
        ._Notification
        .addErrorMessage(self.gettextCatalog.getString('Cant load invoice'));
    });
};

SubscriptionCtrl.prototype.savePaymentMethod = function () {
  var paymentData;
  this.paymentMethodSubmitted = true;

  if (this.isSelected('creditCard')) {
    paymentData = {
      bearer: this._settings.billwerk.bearer.creditCard,
      cardNumber: this.paymentModel.cardNumber,
      expiryMonth: this.paymentModel.expiryMonth,
      expiryYear: this.paymentModel.expiryYear,
      cardHolder: this.paymentModel.cardHolder,
      cvc: this.paymentModel.cvc
    };
  } else if (this.isSelected('payPal')) {
    paymentData = {
      bearer: this._settings.billwerk.bearer.payPal,
      emailAddress: this.paymentModel.paypalEmail
    };
  } else if (this.isSelected('directDebit')) {
    paymentData = {
      bearer: this._settings.billwerk.bearer.directDebit,
      accountHolder: this.paymentModel.accountHolder,
      iban: this.paymentModel.iban,
      mandateSignatureDate: new Date(),
      mandateText: `${this.gettextCatalog.getString('By signing this mandate form, you authorise')}
            ${this.gettextCatalog.getString('(A)Termin2go to send instructions to your bank to debit your account and')}
            ${this.gettextCatalog.getString('(B) your bank to debit your account in accordance with the instructions from Termin2go.')}
            ${this.gettextCatalog.getString('As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.')}
            `
    };
    console.log(paymentData);
  } else if (this.isSelected('invoice')) {
    paymentData = {
      bearer: this._settings.billwerk.bearer.invoice
    };
  } else if (this.isSelected('none')) {
    paymentData = {
      bearer: this._settings.billwerk.bearer.none
    };
  }

  if (this.PaymentDetailsForm.$valid) {
    this.isLoading = true;

    this._BillwerkService
      .changePaymentInformation(paymentData)
      .then((response) => {
        this.isLoading = false;

        if (response.Message) {
          this._Notification.addErrorMessage({ text: response.Message });
        } else {
          this._Notification.addSuccessMessage(this.gettextCatalog.getString('Changed Payment Details successfully'));
        }
      })
      .catch((error) => {
        this._Notification.addErrorMessage({ text: error.Message });
        this.isLoading = false;
      });
  }
};


SubscriptionCtrl.prototype.packageChanged = function () {
  if (this.model.package === 'basic') {
    this.model.additionalEmployees = 0;
  }
};

SubscriptionCtrl.prototype.changeBillingInformation = function () {
  this._LocalStorage.remove('SubscriptionProcess');
  this._state.transitionTo('private.main.billingDetails');
};

SubscriptionCtrl.prototype.changePaymentMethod = function () {
  this._LocalStorage.remove('SubscriptionProcess');
  this._state.transitionTo('private.main.paymentDetails');
};

SubscriptionCtrl.prototype.toggleComponent = function () {
  this.office.settings.reminder.sms.enabled = !this.office.settings.reminder.sms.enabled;
  this._smsSettingsChanged = !this._smsSettingsChanged;
};

SubscriptionCtrl.prototype.showInvoiceNotification = function() {
  return this.office.settings.currency === 'EUR' && this.model.interval === 'monthly';
};

SubscriptionCtrl.prototype.isPaymentMethodVisible = function (method) {
  if (this.isBasicPackage) {
    return false;
  }

  if (this.showInvoiceNotification() && method === 'invoice') {
    return false;
  }

  return this.methods.indexOf(method) > -1;
};

SubscriptionCtrl.prototype.select = function (paymentType) {
  this.paymentModel.method = this._settings.billwerk.bearer[paymentType];
};

SubscriptionCtrl.prototype.isSelected = function (paymentType) {
  return this._settings.billwerk.bearer[paymentType].indexOf(this.paymentModel.method) > -1;
};

SubscriptionCtrl.prototype.openCancelAccountModal = function () {
  this._ModalService.openCancelAccountModal();
};

module.exports = SubscriptionCtrl;
