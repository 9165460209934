var LogListCtrl = function ($scope, items, DatepickerSettings, LogDA, Notification, ModalService, UserSession, employees, users, gettextCatalog) {
  this._scope = $scope;
  this.items = items;
  this.employees = employees;
  this.users = users;
  this.dtpfilterDateOpened = false;
  this.dtpOptions = angular.copy(DatepickerSettings.default);
  this.filter = {
    type: undefined,
    date: undefined,
    user: undefined,
    employee: undefined,
    documentId: undefined,
  };
  this._LogDA = LogDA;
  this._Notification = Notification;
  this._ModalService = ModalService;
  this.gettextCatalog = gettextCatalog;
  this.typeSelectItems = [
    {
      value: 'appointment',
      label: gettextCatalog.getString('Appointment')
    },
    {
      value: 'customer',
      label: gettextCatalog.getString('Customer')
    }
  ];
};


LogListCtrl.prototype.isPackage = function (subPackage) {
  return subPackage === this._scope.subscription.package;
}

LogListCtrl.prototype.resetFilter = function () {
  this.filter = {
    type: undefined,
    date: undefined,
    user: undefined,
    employee: undefined,
    documentId: undefined,
  };
};

LogListCtrl.prototype.search = function () {
  this._LogDA
    .list(this.filter)
    .then((items) => {
      this.items = items;
    })
    .catch((error) => {
      console.error(error);
    });
};

LogListCtrl.prototype.openAppointment = function (appointmentId) {
  this._ModalService.openAppointmentModal(
    {  _id: appointmentId },
    'appointment');
};

module.exports = LogListCtrl;
