'use strict';

module.exports = function(CalendarSettings) {

    return {
        pixelToTime: pixelToTime,
        timeToPixel: timeToPixel,
        minutesToPixel: minutesToPixel
    };


    /**
     * get hours and minutes from pixel
     * @param {number} pixel
     * @param {boolean} useOffset
     * @returns {{hours: number, minutes: number}}
     */
    function pixelToTime(pixel, useOffset) {
        var offset = useOffset ? CalendarSettings.get('offset') : 0;
        var hours = Math.floor(pixel / CalendarSettings.get('hourHeight')) + offset;
        var minutes = Math.floor(((pixel % CalendarSettings.get('hourHeight')) / CalendarSettings.get('hourHeight')) * 60);
        var roundedMinutes = 15 * Math.round(minutes / 15);

        if (roundedMinutes === 60) {
            hours++;
            roundedMinutes = 0;
        }

        return {
            hours: hours,
            minutes: minutes,
            roundedMinutes: roundedMinutes
        };
    }


    /**
     * get pixel (top) from datetime
     * @param {date} dateTime
     * @returns {number}
     */
    function timeToPixel(dateTime) {
        let mDate = moment(dateTime);
        var hours = mDate.hours();
        var minutes = mDate.minutes();

        return Math.floor(((hours + minutes / 60) - CalendarSettings.get('offset')) * CalendarSettings.get('hourHeight'));
    }


    /**
     * mainly used for calc absent time blocks
     * @param {number} minutes
     * @returns {number}
     */
    function minutesToPixel(minutes, disableOffset){
        var offset = disableOffset ? 0 : CalendarSettings.get('offset');

        return Math.floor(((minutes / 60) - offset) * CalendarSettings.get('hourHeight'));
    }

};
