const CampaignListCtrl = function ($state, campaigns, Notification, gettextCatalog) {
  this._Notification = Notification;
  this._state = $state;
  this.campaigns = campaigns;
  this.gettextCatalog = gettextCatalog;
  this.states = {
    unknown: gettextCatalog.getString('STATE_UNKNOWN'),
    stopped: gettextCatalog.getString('STATE_STOPPED'),
    running: gettextCatalog.getString('STATE_RUNNING'),
    error: gettextCatalog.getString('STATE_ERROR')
  };
};

CampaignListCtrl.prototype.getState = function (state) {
  return this.states[state];
};

CampaignListCtrl.prototype.edit = function (campaign) {
  this._state.go('private.main.automated-campaign', { type: 'birthday', id: campaign._id });
};

module.exports = CampaignListCtrl;
