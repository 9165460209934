var pinModule = angular.module('t2g.pin', [
    'gettext',
    require('t2g.common.services.modalService').name,
    require('t2g.common.services.notificationService').name,
]);

module.exports = pinModule;

pinModule.controller('PinModalCtrl', require('./PinModalCtrl'));

// customer insert, remove, update
// PUT http://localhost:3000/api/v2/customer/65e64ca7aea1607df838c234
// DELETE http://localhost:3000/api/v2/customer/65e64ca7aea1607df838c234
// TODO test with other user roles
// TODO log UI global
// ...
// TODO kasse
// TODO booking accept, decline new UI first
