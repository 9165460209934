'use strict';

/**
 * @param {Object} $scope
 * @param {Object} calendarSettings
 * @constructor
 */
var AppointmentCtrl = function ($scope,
                                $sce,
                                ModalService,
                                PixelTimeService,
                                AppointmentDA,
                                gettextCatalog,
                                OfficeDA,
                                AppointmentDragAndResizeState) {

  this._scope = $scope;
  this._sce = $sce;
  this.model = $scope.appointment;
  this.model.start = new Date(this.model.start);
  this.model.end = new Date(this.model.end);
  this.model.preparationStart = new Date(this.model.preparationStart);
  this.model.followUpEnd = new Date(this.model.followUpEnd);
  this.model.note = this.model.note ? this._sce.trustAsHtml(this.model.note) : undefined;
  this.customer = this.model.customer;
  this._ModalService = ModalService;
  this._PixelTimeService = PixelTimeService;
  this._AppointmentDA = AppointmentDA;
  this._AppointmentDragAndResizeState = AppointmentDragAndResizeState;
  this.gettextCatalog = gettextCatalog;
  this.contextMenuModel = {
    event: 'contextmenu',
    items: [
      {
        label: gettextCatalog.getString('Copy'),
        action: this.copy.bind(this),
        icon: 'icon-copy'
      }
    ]
  };

  OfficeDA
    .get()
    .then((office) => {
      const appointmentTemplate = _.get(office, 'settings.calendar.customerAppointmentTemplate', 'appointmentCustomerDefault');
      this.model.template = appointmentTemplate;
    });
};

AppointmentCtrl.prototype.getTooltip = function () {
  let customer = '';
  let exposureTime = '';
  let time = `${moment(this.model.start).format('HH:mm')} - ${moment(this.model.end).format('HH:mm')}`;

  if (this.model.type === 'customer' && this.model.customer) {
    customer = `<br>${this.model.customer.fullname}`;

    if(this.model.freeTimeStart  && this.model.freeTimeEnd) {
      exposureTime = `<br>${this.gettextCatalog.getString('Exposure time')}: ${moment(this.model.freeTimeStart).format('HH:mm')} - ${moment(this.model.freeTimeEnd).format('HH:mm')}`;
    }
  }

  return `${time}${exposureTime}${customer}`;
};


/**
 * calculate the vertical position of the appointment
 */
AppointmentCtrl.prototype.calculateVerticalPosition = function (startProperty, endProperty) {
  var dateMoment = moment(this._scope.date.date);
  var startMoment = moment(this.model[startProperty]);
  var endMoment = moment(this.model[endProperty]);
  var start;
  var end;
  var top;
  var height;
  var hideEnd;
  var hideStart;

  if (startMoment.isBefore(dateMoment.startOf('day'))) {
    start = 0;
    hideStart = true;
  } else {
    start = this._PixelTimeService.timeToPixel(this.model[startProperty]);
  }

  if (endMoment.isAfter(dateMoment.endOf('day'))) {
    end = this._PixelTimeService.timeToPixel(new Date(2016, 0, 0, 23, 59, 59));
    hideEnd = true;
  } else {
    end = this._PixelTimeService.timeToPixel(this.model[endProperty]);
  }

  top = start < 0 ? 0 : start;
  height = end - top;

  return {
    top: top,
    height: height,
    hideStart: hideStart,
    hideEnd: hideEnd
  };
};


/**
 * open appointment modal
 * @param {Object<Event>} event
 * @param {Object} appointment
 */
AppointmentCtrl.prototype.openAppointment = function (event, appointment) {
  if (!this._AppointmentDragAndResizeState.isActive()) {
    this._ModalService.openAppointmentModal(
      appointment,
      'appointment'
    );
    event.stopPropagation();
  }
};


/**
 * copy appointment
 */
AppointmentCtrl.prototype.copy = function () {
  var appointmentCopy = _.cloneDeep(this.model);
  delete appointmentCopy._id;
  delete appointmentCopy.seriesId;
  appointmentCopy.recurring = {
    enabled: false
  };

  this._ModalService.openAppointmentModal(
    appointmentCopy
  );
};

AppointmentCtrl.prototype.updateEnd = function (change) {
  var minutes = change.hours * 60 + change.roundedMinutes;
  this.model.end = moment(this.model.start).add(minutes, 'minutes');
  this._AppointmentDA.update(this.model);
};

module.exports = AppointmentCtrl;
