'use strict';

var angularModule = angular.module('t2g.common.data-access.payments', []);

module.exports = angularModule;

angularModule.factory('PaymentResource', function ($resource, settings) {
    var resourceUrl = settings.apiBase + '/api/v2/payment/:subResource1/:subResource2';

    var resource = $resource(resourceUrl, {}, {
        getStripeLink: { method: 'GET', params: { subResource1: 'stripe', subResource2: 'link' }, isArray: false },
        getStripeOAuthLink: { method: 'GET', params: { subResource1: 'stripe', subResource2: 'oAuthLink' }, isArray: false },
        deauthorizeStripe: { method: 'GET', params: { subResource1: 'stripe', subResource2: 'deauthorize' }, isArray: false }
    });

    return resource;
});

angularModule.factory('PaymentDA', function (PaymentResource) {
    return {
        getStripeLink,
        getStripeOAuthLink,
        deauthorizeStripe
    };

    function getStripeLink(){
        return PaymentResource.getStripeLink().$promise;
    }

    function getStripeOAuthLink(){
        return PaymentResource.getStripeOAuthLink().$promise;
    }

    function deauthorizeStripe(){
        return PaymentResource.deauthorizeStripe().$promise;
    }
});

