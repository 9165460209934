'use strict';

var angularModule = angular.module('t2g.common.directives.auto-expand', []);

module.exports = angularModule;

angularModule.directive('autoExpand', function () {

    return {
        restrict: 'A',
        link: function ($scope, $element) {
            $element.on('keydown', function () {
                $element[0].style.cssText = 'height:' + Math.max($element[0].scrollHeight, 62) + 'px';
            });
        }
    };
});

