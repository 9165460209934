'use strict';

var angularModule = angular.module('t2g.common', [
    require('./data-access/index').name,
    require('./directives').name,
    require('./filter').name,
    require('./services').name,
    require('./customTranslations').name
]);

module.exports = angularModule;

