var AccountCtrl = function ($scope,
                            $state,
                            $stateParams,
                            AccountDA,
                            Notification,
                            gettextCatalog,
                            UserDA,
                            languageSwitch,
                            user) {

  this._state = $state;
  this._UserDA = UserDA;
  this._Notification = Notification;
  this._languageSwitch = languageSwitch;
  this._AccountDA = AccountDA;
  this.openTab = $stateParams.openTab;
  this.gettextCatalog = gettextCatalog;
  this.showValidation = false;
  this.user = user;
  this.account = {
    currentPassword: '',
    password1: '',
    password2: ''
  };
  this.defaultLanguage = this.user.language || 'de';
  this.languages = [
    {
      code: 'de',
      label: 'Deutsch'
    },
    {
      code: 'en',
      label: 'English'
    }
  ];
};


AccountCtrl.prototype.save = function () {
  this.showValidation = true;

  if (this.openTab === 'password') {
    this.changePassword();
  } else {
    if (this.defaultLanguage !== this.user.language) {
      this._languageSwitch.set(this.user.language, false);
    }
  }
};

AccountCtrl.prototype.switchTab = function (tab) {
  this.openTab = tab;
  this._state.transitionTo('private.main.account', {
    openTab: tab
  }, { notify: false, reloadOnSearch: false });
};

AccountCtrl.prototype.changePassword = function () {
  const self = this;

  if (this.SettingsForm.$valid) {
    this._AccountDA
      .changePassword(this.account)
      .then(() => {
        self._Notification.addSuccessMessage(self.gettextCatalog.getString('Password changed'));
        return this._UserDA.logout();
      })
      .catch((error) => {
        self._Notification.addErrorMessage({ text: error.Message });
        self._Notification.addErrorMessage(self.gettextCatalog.getString('Error occured (Error: {{error}})', { error: error }));
      });
  }


};


module.exports = AccountCtrl;
