'use strict';

var angularModule = angular.module('t2g.common.data-access.subscription', [
  'ngResource',
  require('t2g.common.services.sessionStorage').name,
  require('t2g.common.data-access.user').name
]);

module.exports = angularModule;

angularModule.factory('SubscriptionResource', function ($resource,
                                                        settings) {

    var resourceUrl = settings.apiBase + '/api/v2/subscription/:subResource1/:subResource2/:subResource3';

    var resource = $resource(resourceUrl, {}, {
      getPrice: { method: 'GET', isArray: false, params: { subResource1: 'prices' } },
      getContract: { method: 'GET', isArray: false, params: { subResource1: 'contract' } },
      getInvoices: { method: 'GET', isArray: false, params: { subResource1: 'invoices' } },
      getInvoiceLink: { method: 'GET', isArray: false, params: { subResource1: 'invoiceFile' } },
      cancelContract: { method: 'POST', isArray: false, params: { subResource1: 'cancel' } },
      getCustomerToken: {
        method: 'GET',
        isArray: false,
        params: { subResource1: 'billwerk', subResource2: 'customerToken' }
      },
      upDownGrade: { method: 'POST', isArray: false }
    });

    return resource;
  }
);

angularModule.factory('SubscriptionDA', function ($q,
                                                  localStorageService,
                                                  settings,
                                                  SubscriptionResource) {

  return {
    cancelContract,
    getContract,
    get: get,
    getInvoices,
    getInvoiceLink,
    getPrice,
    getCustomerToken,
    upDownGrade
  };

  function cancelContract(data) {
    let reason = '';

    if (data.reason) {
      const keys = Object
        .keys(data.reason)
        .filter(key => data.reason[key]);

      reason = keys.join(', ');
    }

    return SubscriptionResource
      .cancelContract({ reason, comment: data.comment })
      .$promise;
  }

  function getContract() {
    return SubscriptionResource
      .getContract()
      .$promise
      .then(function (response) {
        return response.contract;
      });
  }

  function getInvoiceLink(invoiceNo) {
    return SubscriptionResource
      .getInvoiceLink({ subResource2: invoiceNo })
      .$promise
      .then(function (response) {
        return response.link;
      });
  }

  function getInvoices() {
    return SubscriptionResource
      .getInvoices()
      .$promise
      .then(function (response) {
        return response.invoices;
      });
  }

  function get() {
    return SubscriptionResource
      .get()
      .$promise
      .then(function (response) {
        var subscription = response.subscription;
        var onlyBasicFunctions = false;

        if (subscription.empty && subscription.trialDaysLeft === 0) {
          onlyBasicFunctions = true;
        } else {
          onlyBasicFunctions = subscription.package === 'basic';
        }

        subscription.onlyBasicFunctions = onlyBasicFunctions;

        return subscription;
      });
  }

  function getPrice(countryCode, employees = 0, branches = 1) {
    let params = { subResource2: countryCode };

    if (employees) {
      params.employees = employees;
    }

    if (branches) {
      params.branches = branches;
    }

    return SubscriptionResource
      .getPrice(params)
      .$promise
      .then(function (response) {
        return response.prices;
      });
  }

  function getCustomerToken() {
    return SubscriptionResource
      .getCustomerToken()
      .$promise
      .then(function (response) {
        return response.customerToken;
      });
  }

  function upDownGrade(upDownGradeDate) {
    return SubscriptionResource
      .upDownGrade(upDownGradeDate)
      .$promise
      .then(function (response) {
        return response.upDownGrade;
      });
  }
});
