'use strict';

var AppointmentModalBaseCtrl = require('./AppointmentModalBaseCtrl');

var PrivateAppointmentModalCtrl = function ($controller,
                                            $injector,
                                            $rootScope,
                                            appointment,
                                            gettextCatalog,
                                            modalInstance,
                                            subscription,
                                            ModalService,
                                            resources,
                                            office) {

  AppointmentModalBaseCtrl.call(this, modalInstance, $injector);
  var self = this;
  var initialEmployeeId = _.get(appointment, 'object._id');
  this.appointment = appointment;
  this.resources = resources;
  this.title = gettextCatalog.getString('Private Appointment');
  this.rootScope = $rootScope;
  this._ModalService = ModalService;
  this.showLog = ['plus', 'enterprise'].includes(subscription.package);
  this.showMigrateButton = appointment && appointment._id && !appointment.externUID;
  this.office = office;

  // set default resource
  if (!initialEmployeeId) {
    this.appointment.object = {
      _id: this.resources.employees[ 0 ]._id
    };
  }

  this.recurringSettingsWatcher = this.watchRecurringSettings();
};

PrivateAppointmentModalCtrl.prototype = new AppointmentModalBaseCtrl();

PrivateAppointmentModalCtrl.prototype.migrate = function () {
  this.appointment.type = 'customer';
  this.appointment.migrated = true;
  this._ModalService.openAppointmentModal(this.appointment);
  this.close();
};


module.exports = PrivateAppointmentModalCtrl;
