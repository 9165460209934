module.exports = function ($scope,
                           $timeout,
                           countryResolve,
                           GeocodeService,
                           gettextCatalog,
                           industryResolve,
                           Notification,
                           OfficeDA,
                           officeResolve,
                           settings) {

  $scope.office = officeResolve;
  $scope.industries = industryResolve;
  $scope.mapLink = null;
  $scope.showValidation = false;
  $scope.tasks = [
    {text: gettextCatalog.getString('You added your address'), weight: 15, completed: false},
    {text: gettextCatalog.getString('You added your contact data'), weight: 15, completed: false},
    {text: gettextCatalog.getString('You uploaded a logo'), weight: 20, completed: false},
    {text: gettextCatalog.getString('You activated your profile on termin2go.com'), weight: 10, completed: false},
    {text: gettextCatalog.getString('You uploaded pictures of your office'), weight: 20, completed: false},
    {text: gettextCatalog.getString('You added a description'), weight: 20, completed: false}
  ];
  $scope.countries = countryResolve;

  if ($scope.office.profile.industries && $scope.office.profile.industries.length) {
    $scope.selectedIndustry = $scope.office.profile.industries.find(industry => industry.name === $scope.office.profile.industries[0].name);
  }

  $scope.changeIndustry = function () {
    angular.forEach($scope.industries, function (value) {
      if (value.name === $scope.office.profile.industry) {
        $scope.office.settings.customLabels = value.labels;
      }
    });
  };

  $scope.getIndustryName = function (industry) {

  };

  function sumcompletedTasksWeight() {
    $scope.completedTasksWeight = 0;

    // check address
    if ($scope.office.name &&
      $scope.office.address &&
      angular.isArray($scope.office.address.location) &&
      $scope.office.address.location.length === 2) {
      $scope.tasks[0].completed = true;
    } else {
      $scope.tasks[0].completed = false;
    }

    // check contacts
    if ($scope.office.profile.contacts.phone && $scope.office.profile.contacts.email) {
      $scope.tasks[1].completed = true;
    } else {
      $scope.tasks[1].completed = false;
    }

    // check logo
    if ($scope.office.profile.logo) {
      $scope.tasks[2].completed = true;
    } else {
      $scope.tasks[2].completed = false;
    }

    // check profile active
    if ($scope.office.profile.public) {
      $scope.tasks[3].completed = true;
    } else {
      $scope.tasks[3].completed = false;
    }


    // check images
    if ($scope.office.profile.images && $scope.office.profile.images.length > 0) {
      $scope.tasks[4].completed = true;
    } else {
      $scope.tasks[4].completed = false;
    }

    // check text
    if ($scope.office.profile.text) {
      $scope.tasks[5].completed = true;
    } else {
      $scope.tasks[5].completed = false;
    }

    angular.forEach($scope.tasks, function (task) {
      if (task.completed) {
        $scope.completedTasksWeight += task.weight;
      }
    });
  }

  sumcompletedTasksWeight();

  // if address is not set
  if (!$scope.office.address) {
    $scope.office.address = {
      location: [],
      city: {
        name: ''
      }
    };
  }

  // set logo
  if ($scope.office.profile.logo) {
    $scope.logoPreview = $scope.office.profile.logo.original;
  }

  $scope.generateMapLink = function () {
    if ($scope.office.address && angular.isArray($scope.office.address.location) && $scope.office.address.location.length === 2) {
      $scope.mapLink = "//maps.googleapis.com/maps/api/staticmap?key=" + settings.google.maps + "&center=" + $scope.office.address.location[1] + "," + $scope.office.address.location[0] + "&zoom=15&size=785x300&maptype=roadmap&&markers=color:0x9BBF4D|" + $scope.office.address.location[1] + "," + $scope.office.address.location[0] + "&sensor=false";
    }
  };


  $scope.generateMapLink();

  $scope.onLogoSelect = function (files) {
    var file = files && files.length ? files[0] : null;

    if (file && window.FileReader && file.type.indexOf('image') > -1) {
      var fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onload = function (e) {
        $timeout(function () {
          $scope.logoPreview = e.target.result;
        });
      };

      OfficeDA
        .uploadLogo(file)
        .then((response) => {
          Notification.addSuccessMessage(gettextCatalog.getString('Logo uploaded'));
        })
        .catch((response) => {
          if (response.data.error.message === 'file_to_large') {
            Notification.addErrorMessage(gettextCatalog.getString('File is to large (max 10 MB)'));
          } else if (response.data.error.message === 'extension_not_allowed') {
            Notification.addErrorMessage(gettextCatalog.getString('File extension not of type [JPG,PNG]'));
          } else {
            Notification.addErrorMessage();
          }
        });
    }
  };

  $scope.deleteLogo = function () {
    OfficeDA
      .deleteLogo()
      .then(() => {
        delete $scope.office.profile.logo;
        $scope.logoPreview = null;
        Notification.addSuccessMessage(gettextCatalog.getString('Logo deleted'));
      })
      .catch(() => {
          Notification.addErrorMessage(gettextCatalog.getString('Cannot delete Logo'));
      });
  };


  $scope.onCarouselFileSelect = function (files) {
    var file = files && files.length ? files[0] : null;

    if (file && window.FileReader && file.type.indexOf('image') > -1) {
      OfficeDA
        .uploadImage(file)
        .then((response) => {
          $scope.office.profile.images = response.profile.images;
          Notification.addSuccessMessage(gettextCatalog.getString('Image uploaded'));
        })
        .catch((response) => {
          if (response.data.error.message === 'file_to_large') {
            Notification.addErrorMessage(gettextCatalog.getString('File is to large (max 10 MB)'));
          } else if (response.data.error.message === 'extension_not_allowed') {
            Notification.addErrorMessage(gettextCatalog.getString('File extension not of type [JPG,PNG]'));
          } else {
            Notification.addErrorMessage({text: response.data.error.message});
          }
        });
    }
  };

  $scope.deleteImage = function(image, imageIndex) {
    OfficeDA
      .deleteImage(image._id)
      .then(() => {
        $scope.office.profile.images.splice(imageIndex, 1);
        Notification.addSuccessMessage(gettextCatalog.getString('Image deleted'));
      })
      .catch(() => {
        Notification.addErrorMessage(gettextCatalog.getString('Cannot delete Image'));
      });
  };

  $scope.save = function () {
    $scope.showValidation = true;

    if ($scope.ProfileForm.$valid) {
      // $scope.office.profile.industries = [$scope.selectedIndustry];
      OfficeDA
        .update($scope.office)
        .then((response) => {
          Notification.addSuccessMessage(gettextCatalog.getString('Profile saved'));
          $scope.office = response;
          sumcompletedTasksWeight();
          $scope.generateMapLink();
        })
        .catch((error) => {
          Notification.addErrorMessage({text: error});
        });
    }
  };

  $scope.getEncodedUrl = function (url) {
    return encodeURIComponent(url);
  }
};
