var OpenDayModalCtrl = function (Notification,
                                 AccountingDA,
                                 gettextCatalog,
                                 office,
                                 modalInstance) {

  this._modalInstance = modalInstance;
  this._AccountingDA = AccountingDA;
  this._Notification = Notification;
  this.model = {
    amount: 0,
    note: ''
  };
  this.gettextCatalog = gettextCatalog;
  this.formSubmitted = false;
  this.office = office;
};

OpenDayModalCtrl.prototype.close = function () {
  this._modalInstance.close();
};

OpenDayModalCtrl.prototype.save = function () {
  this.formSubmitted = true;

  if (this.form.$valid) {
    this._AccountingDA
      .openDay(this.model.amount, this.model.note)
      .then((newCashBook) => {
        this._Notification.addSuccessMessage(this.gettextCatalog.getString('Cash book opened'));
        this._modalInstance.close(newCashBook);
      })
      .catch(function () {
        this._Notification.addErrorMessage(this.gettextCatalog.getString('Cant open cash book'));
      });
  }
};

module.exports = OpenDayModalCtrl;
