'use strict';

var FinalizeCtrl = function ($timeout,
                             BillwerkService,
                             LocalStorage) {
    var self = this;

    this._BillwerkService = BillwerkService;
    
    // clear subscription data
    LocalStorage.remove('SubscriptionProcess');
};

module.exports = FinalizeCtrl;