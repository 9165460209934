const configModule = angular.module('t2g.config', []);

module.exports = configModule;

configModule.factory('NavigationSettings', function (
  $state,
  $stateParams,
  $timeout,
  LocalStorage,
  gettextCatalog,
  UserSession,
  UserDA,
  OfficeDA
) {
  let navigationitems = [
    {
      icon: 'icon-calendar',
      label: gettextCatalog.getString('Calendar'),
      state: 'private.main.calendar'
    },
    {
      icon: 'icon-persons',
      label: '{{labels.customers}}',
      state: 'private.main.customer'
    },
    {
      icon: 'icon-mail',
      label: gettextCatalog.getString('Messages'),
      notification: 'bookings.length > 0',
      subMenu: [
        {
          label: gettextCatalog.getString('Inbox'),
          state: 'private.main.messages',
          notification: 'bookings.length > 0',
          stateParams: {
            box: 'inbox'
          }
        },
        {
          label: gettextCatalog.getString('Outbox'),
          state: 'private.main.messages',
          stateParams: {
            box: 'outbox'
          }
        }
      ]
    },
    {
      icon: 'icon-library',
      label: gettextCatalog.getString('Master data'),
      subMenu: [
        {
          label: gettextCatalog.getString('Services'),
          state: 'private.main.services',
          role: 'manager'
        },
        {
          label: gettextCatalog.getString('Manage Branches'),
          state: 'private.main.branches',
          role: 'admin'
        },
        {
          label: gettextCatalog.getString('Employees / Rooms'),
          state: 'private.main.resources',
          role: 'manager'
        },
        {
          label: gettextCatalog.getString('Working Hours'),
          state: 'private.main.shiftPlanning'
        },
        {
          label: gettextCatalog.getString('Company profile'),
          state: 'private.main.profile',
          role: 'manager'
        },
        {
          label: gettextCatalog.getString('Opening hours'),
          state: 'private.main.openingHours',
          role: 'manager'
        },
        {
          label: gettextCatalog.getString('Holidays and Business Holidays'),
          state: 'private.main.holidays',
          role: 'manager'
        },
        {
          label: gettextCatalog.getString('Courses'),
          state: 'private.main.courses',
          role: 'manager'
        }
      ]
    },
    {
      icon: 'icon-settings',
      label: gettextCatalog.getString('Settings'),
      state: 'private.main.settings',
      role: 'manager',
      stateParams: {
        openTab: 'booking'
      }
    },
    {
      icon: 'icon-cash-register',
      label: gettextCatalog.getString('Accounting'),
      role: 'manager',
      subMenu: [
        {
          label: gettextCatalog.getString('Open Cash Register'),
          modal: 'openCashModal',
          modalData: {
            positions: [],
            total: 0,
            cashed: new Date()
          }
        },
        {
          label: gettextCatalog.getString('Cash Book'),
          state: 'private.main.accountingCashBook',
          stateParams: {
            date: moment().format('YYYY-MM-DD')
          }
        },
        // TODO discounts
        // TODO vouchers
      ]
    },
    {
      icon: 'icon-chart',
      label: gettextCatalog.getString('Statistics'),
      role: 'manager',
      subMenu: [
        {
          label: gettextCatalog.getString('Sales'),
          state: 'private.main.statistic-sales'
        },
        {
          label: gettextCatalog.getString('Sales per Employee'),
          state: 'private.main.statistic-sales-per-employee'
        },
        {
          label: gettextCatalog.getString('Sales per Service'),
          state: 'private.main.statistic-sales-per-service'
        }
      ]
    },
    {
      icon: 'icon-add',
      label: gettextCatalog.getString('Extras'),
      role: 'manager',
      subMenu: [
        {
          label: gettextCatalog.getString('Newsletter'),
          state: 'private.main.marketing'
        },
        {
          label: gettextCatalog.getString('Log'),
          state: 'private.main.log',
          role: 'admin',
        },
        {
          label: gettextCatalog.getString('Trash'),
          state: 'private.main.trash'
        },
        {
          label: gettextCatalog.getString('Booking widget website'),
          state: 'private.main.pageWidget'
        },
        {
          label: gettextCatalog.getString('E-Mail Templates'),
          state: 'private.main.mailTemplate',
          stateParams: {
            templateName: 'acceptBooking'
          }
        },
        {
          label: gettextCatalog.getString('Custom Fields'),
          state: 'private.main.customFields'
        },
        {
          label: gettextCatalog.getString('User management'),
          state: 'private.main.user'
        },
        {
          label: gettextCatalog.getString('Data export'),
          state: 'private.main.export'
        },
        {
          label: gettextCatalog.getString('Data privacy'),
          state: 'private.main.data-privacy'
        }
      ]
    },
    {
      icon: 'icon-person',
      label: gettextCatalog.getString('Profile'),
      notification: 'subscription.trialDaysLeft < 7',
      subMenu: [
        {
          label: gettextCatalog.getString('Account Settings'),
          state: 'private.main.account',
          stateParams: {
            openTab: 'password'
          }
        },
        {
          label: gettextCatalog.getString('Connect with Google'),
          state: 'private.main.googleSync',
          role: 'admin'
        },
        {
          label: gettextCatalog.getString('Subscription'),
          notification: 'subscription.trialDaysLeft < 7',
          state: 'private.main.subscription',
          role: 'admin',
          stateParams: {
            openTab: 'plans'
          }
        },
        {
          label: gettextCatalog.getString('Logout'),
          state: 'private.main.logout'
        }
      ]
    },
    {
      icon: 'icon-help',
      label: gettextCatalog.getString('Help'),
      highlight: true,
      attributes: {
        href: 'javascript:$zopim.livechat.window.show();'
      }
    }
  ];

  function get () {
    const user = UserSession.get();
    let branchNavItem;

    if (user.branchNames && user.branchNames.length > 1 && navigationitems[1].id !== 'branches') {
      branchNavItem = {
        icon: 'icon-location',
        label: gettextCatalog.getString('Branch'),
        id: 'branches',
        subMenu: user.branchNames.map((branchName) => {
          return {
            label: branchName.selected ? `${branchName.name} (ausgewählt)` : branchName.name,
            highlight: branchName.selected,
            click () {
              UserDA.selectOffice(branchName._id)
                .then(() => {
                  LocalStorage.remove('calendarView');
                  if ($state.current.name === 'private.main.calendar') {
                    window.location.href = '/#!/private/main';
                    window.location.reload();
                  } else if ($state.current.name === 'private.main.resourcesDetails') {
                    window.location = '/#!/private/main/resources';
                    window.location.reload();
                  } else if ($state.current.name === 'private.main.shiftPlanning.workingHours') {
                    window.location = '/#!/private/main/shift-planning';
                    window.location.reload();
                  } else {
                    window.location.reload();
                  }
                });
            }
          };
        })
      };
      if (!navigationitems.find(menuItem => menuItem.id === 'branches')) {
        navigationitems.splice(1, 0, branchNavItem);
      }
    }

    return OfficeDA
      .get()
      .then((office) => {
        const isCovidTestEnabled = _.get(office, 'settings.covidTest.enabled', false) && _.get(office, 'settings.covidTest.provider') === 'termin2go';

        if (isCovidTestEnabled) {
          if (!navigationitems.find(menuItem => menuItem.state === 'private.main.testManagement')) {
            navigationitems.splice(1, 0, {
              icon: 'icon-qr',
              label: 'Test Management',
              state: 'private.main.testManagement',
              stateParams: {
                openTab: 'upcoming'
              }
            });
          }

          let statisticMenuItem = navigationitems.find(navigationitem => navigationitem.icon === 'icon-chart');

          if (!statisticMenuItem.subMenu.find(menuItem => menuItem.state === 'private.main.statistic-tests')) {
            statisticMenuItem.subMenu.push({
              label: 'Schnelltest',
              state: 'private.main.statistic-tests',
              stateParams: {
                testType: 'quickTest'
              }
            });
            statisticMenuItem.subMenu.push({
              label: 'Schnelltest (Testgrund)',
              state: 'private.main.statistic-tests',
              stateParams: {
                testType: 'quickTestWithReason'
              }
            });
            statisticMenuItem.subMenu.push({
              label: 'PCR-Tests',
              state: 'private.main.statistic-tests',
              stateParams: {
                testType: 'pcr'
              }
            });
            statisticMenuItem.subMenu.push({
              label: 'PCR-Tests (Labor)',
              state: 'private.main.statistic-tests',
              stateParams: {
                testType: 'pcrLabor'
              }
            });
          }
        }

        if (office.isHelloCashActivated) {
          let cashBookMenuItem = navigationitems
            .find(navigationitem => navigationitem.icon === 'icon-cash-register')
            .subMenu
            .find(extraMenuItem => extraMenuItem.state === 'private.main.accountingCashBook') || navigationitems[5].subMenu

          if (cashBookMenuItem) {
            cashBookMenuItem.label = gettextCatalog.getString('HelloCash Cash Book');
            cashBookMenuItem.attributes = {
              href: 'https://myhellocash.com/intern/cash-register/cashbook',
              target: '_blank',
              rel: 'noopener'
            };
            delete cashBookMenuItem.state;
            delete cashBookMenuItem.stateParams;
          }
        }

        return navigationitems;
      });
  }

  return {
    get: get
  };
});
