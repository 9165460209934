var angularModule = angular.module('t2g.common.directives.autoFillFixDirective', []);

module.exports = angularModule;

angularModule.directive('autoFillFix', function () {
    return {
        restrict: 'A',
        priority: 10,
        link: function ($scope, element, attrs) {
            element.on('submit', function (ev) {
                $('input[ng-model]', element).each(function (index, item) {
                    if (angular.element(this).attr('type') !== 'checkbox' && angular.element(this).attr('type') !== 'radio') {
                        angular.element(this).controller('ngModel').$setViewValue($(this).val());
                    }
                });
            });
        }
    }
});