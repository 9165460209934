var angularModule = angular.module('t2g.common.directives.focusDirective', []);

module.exports = angularModule;

angularModule.directive('focusLock', function ($timeout) {
    return {
        restrict: 'A',
        link: function ($scope, $element) {
            $timeout(function () {
                $element.focus();
            }, 150);

            $element.on('blur', function () {
                $element.focus();
            });

            $scope.$on('$destroy', function () {
                $element.off();
            });
        }
    };
});
