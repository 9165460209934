module.exports = function() {
    var memory = {};

    return {
        copy: copy,
        paste: paste
    };

    function copy(source) {
        memory = _.cloneDeep(source);

        if (source.type === 'default') {
            memory.type = 'custom';
        }
    }

    function paste(destination) {
        destination.type = memory.type;
        destination.available = memory.available;
        destination.times = [];
        var time0 = _.get(memory, 'times[0]');
        var time1 = _.get(memory, 'times[1]');

        if (time0) {
            destination.times.push(time0);
        }

        if (time1) {
            destination.times.push(time1);
        }

        if (memory.absence) {
            destination.absence = memory.absence;

            if (memory.reason) {
                destination.reason = memory.reason;
            } else {
                destination.reason = 'other';
            }
        }
    }
};