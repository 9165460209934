module.exports = function($interval,
                          $document,
                          CalendarSettings,
                          PixelTimeService) {
    return {
        restrict: 'E',
        template: '<div class="calendar-timeline"></div>',
        replace: true,
        link: function($scope, $element) {
            var scrollWrapper = angular.element('.calendar__scroll-wrapper');
            var initialTop = setPosition();
            var scrollToY = initialTop > 50 ? initialTop - 50 : 0;

            if (scrollWrapper && scrollWrapper[0] && initialTop) {
                scrollWrapper[0].scrollTop = scrollToY;
            }

            $interval(function() {
                setPosition();
            }, 60000);

            function setPosition() {
                var top;
                var now = new Date();

                if(now.getHours() >= CalendarSettings.get('end')) {
                    $element.css('visibility', 'hidden');
                } else if (now.getHours() >= CalendarSettings.get('offset')) {
                    top = PixelTimeService.timeToPixel(new Date());
                    $element.css('top', top + 'px');
                }

                return top;
            }
        }
    }
};
