'use strict';

var angularModule = angular.module('t2g.common.services', [
    require('t2g.common.services.billwerkService').name,
    require('t2g.common.services.cacheService').name,
    require('t2g.common.services.datePickerSettings').name,
    require('t2g.common.services.geoCodeService').name,
    require('t2g.common.services.holderService').name,
    require('t2g.common.services.industrySpecificService').name,
    require('t2g.common.services.languageSwitch').name,
    require('t2g.common.services.loading-indocator').name,
    require('t2g.common.services.mobileDetect').name,
    require('t2g.common.services.modalFactory').name,
    require('t2g.common.services.modalService').name,
    require('t2g.common.services.modalServiceNew').name,
    require('t2g.common.services.notificationService').name,
    require('t2g.common.services.sessionStorage').name,
    require('t2g.common.services.settingsHolderService').name,
    require('t2g.common.services.settingsHolderService').name,
    // require('t2g.common.services.socketService').name,
    require('t2g.common.services.subscriptionStateService').name,
    require('t2g.common.services.userSessionService').name
]);

module.exports = angularModule;

