var angularModule = angular.module('t2g.common.directives.horizontalFixed', []);

module.exports = angularModule;

angularModule.directive('horizontalFixed', function HorizontalFixedDirective() {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attributes) {

                $attributes.$observe('horizontalFixed', function (selector) {
                    var elSelector = window;

                    $(elSelector).scroll(function (event) {
                        $element.css({
                            'left': $(this).scrollLeft()
                        });
                    });
                });
            }
        };
    }
);







