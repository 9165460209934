'use strict';

var angularModule = angular.module('t2g.common.services.subscriptionStateService', []);

module.exports = angularModule;

angularModule.factory('SubscriptionState', function() {
    var subscriptionState = {
        shouldSubscribe: false
    };
    
    return {
        setShouldSubscribe: function(state) {
            subscriptionState.shouldSubscribe = state;
        },
        getShouldSubscribe: function() {
            return subscriptionState.shouldSubscribe;
        }
    };
});
