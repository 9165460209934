'use strict';

var PrivacyCtrl = function (office, settings) {
    this.office = office;
    this.officeName = office.name;
    this.link = settings.dataPrivacyContractUrl;
};

PrivacyCtrl.prototype.generateLink = function () {
    var addressString = '';
    var address = this.office.address;

    if(this.profileValid()){
        addressString = [
            this.office.name,
            address.street,
            address.postal + ' ' + address.city.name,
            address.countryCode
        ].join(', ');
    }

    return this.link
      .replace('{address}', addressString)
      .replace('{date}', moment().format('DD.MM.YYYY'));
};

PrivacyCtrl.prototype.profileValid = function () {
    var address = this.office.address;

    return this.office.name &&
        address &&
        address.city && address.city.name &&
        address.postal &&
        address.street;
}


module.exports = PrivacyCtrl;

