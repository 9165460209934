'use strict';

var CustomerListCtrl = function ($state,
                                 $scope,
                                 customers,
                                 CustomerDA,
                                 gettextCatalog,
                                 uiGridConstants,
                                 MobileDetectService) {

    var self = this;
    this.customers = customers;
    this._CustomerDA = CustomerDA;
    this._state = $state;
    this.isMergePossible = false;
    this.model = {
        data: customers,
        enableSorting: true,
        enableFiltering: true,
        enableRowHeaderSelection: true,
        enableHorizontalScrollbar: false,
        columnDefs: [
            {
                displayName: gettextCatalog.getString('Firstname'),
                field: 'firstname',
                enableColumnMenu: false,
                cellTemplate: '<div class="ui-grid-cell-contents" data-ng-click="grid.appScope.CustomerListCtrl.openCustomer(row.entity._id)">{{grid.getCellValue(row, col)}}</div>'
            },
            {
                displayName: gettextCatalog.getString('Lastname'),
                field: 'lastname',
                enableColumnMenu: false,
                sort: {
                    direction: uiGridConstants.ASC,
                    priority: 0
                },
                cellTemplate: '<div class="ui-grid-cell-contents" data-ng-click="grid.appScope.CustomerListCtrl.openCustomer(row.entity._id)">{{grid.getCellValue(row, col)}}</div>'
            },
            {
                displayName: gettextCatalog.getString('Customer ID'),
                field: 'numericId',
                enableColumnMenu: false,
                cellTemplate: '<div class="ui-grid-cell-contents" data-ng-click="grid.appScope.CustomerListCtrl.openCustomer(row.entity._id)">{{grid.getCellValue(row, col)}}</div>'
            },
        ],
        onRegisterApi: function (gridApi) {
            self.gridApi = gridApi;
        }
    };

    if (!MobileDetectService.isMobile) {
        this.model.columnDefs.push({
            displayName: gettextCatalog.getString('Telephone'),
            field: 'telephone',
            enableColumnMenu: false,
            enableSorting: false,
            cellTemplate: '<div class="ui-grid-cell-contents" data-ng-click="grid.appScope.CustomerListCtrl.openCustomer(row.entity._id)">{{grid.getCellValue(row, col)}}</div>'
        });
    }

    $scope.$watch(function () {
        return self.gridApi.selection.getSelectedRows().length;
    }, function (count) {
        if (count > 1) {
            self.isMergePossible = true;
        } else {
            self.isMergePossible = false;
        }
    });
};

CustomerListCtrl.prototype.openCustomer = function (customerId) {
    var filteredRows = this.gridApi.core.getVisibleRows(this.gridApi.grid);
    var filteredCustomers = _.map(filteredRows, function (row) {
        return row.entity._id;
    });
    this._CustomerDA.setFiltered(filteredCustomers);
    this._state.go('private.main.customerProfile', {customerId: customerId});
};

CustomerListCtrl.prototype.goToMerge = function () {
    var selectedRows = this.gridApi.selection.getSelectedRows();
    var selectedIds = _.map(selectedRows, function (selectedRow) {
        return selectedRow._id ? selectedRow._id : null;
    });

    this._state.go('private.main.merge', {customerIds: selectedIds.join(',')});
}

module.exports = CustomerListCtrl;
