'use strict';

var angularModule = angular.module('t2g.common.services.userSessionService', []);

module.exports = angularModule;

var STORAGE_KEY = 'UserSession';
var ALLOWED_ROLES = {
  admin: [ 'admin' ],
  manager: [ 'admin', 'manager' ],
  employee: [ 'admin', 'manager', 'employee' ],
  viewer: [ 'admin', 'manager', 'employee', 'viewer' ]
};

angularModule.factory('UserSession', function (localStorageService,
                                               $timeout) {

  var _isLoggedIn = false;
  var _user;

  var saveUserToSession = function () {
    localStorageService.set(STORAGE_KEY, _user);
  };

  return {
    checkRole(minimumRole) {
      const role = _.get(_user, 'role');
      let authorized = false;

      if (role && ALLOWED_ROLES[ minimumRole ].indexOf(role) !== -1) {
        authorized = true;
      }

      return authorized;

    },
    isLoggedIn: function () {
      return _isLoggedIn;
    },
    setLoggedIn: function () {
      _isLoggedIn = true;
    },
    hasRole: function (roleName) {
      return (_user.roles.indexOf(roleName) > -1);
    },
    clean: function () {
      localStorageService.remove(STORAGE_KEY);
    },
    getOffice: function () {
      if (_user) {
        if (_user.branch) {
          return _user.branch;
        } else if (_user.office) {
          return _user.office;
        }
      }

      return;
    },
    get: function () {
      // get from session if empty store on session
      _user = _user || localStorageService.get(STORAGE_KEY);

      return _user;
    },
    setBranch: function (branchId) {
      _user.branch = branchId;
      saveUserToSession();
    },
    set: function (user, callback) {
      _user = user || _user;
      saveUserToSession();

      if (callback) {
        $timeout(function () {
          callback();
        }, 400);
      }
    },
    qSet: function (user) {
      _user = user || _user;
      saveUserToSession();
      return $timeout(angular.noop, 250);
    }
  };
});
