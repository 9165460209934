var ResourceListCtrl = function ($timeout, $scope, resources, gettextCatalog) {
  this.resources = resources;
  this.showPremiumNotification = $scope.subscription.onlyBasicFunctions;
  this.gettextCatalog = gettextCatalog;

  // $timeout(() => {
  //   this.runTour();
  // }, 50);

};

ResourceListCtrl.prototype.runTour = function (standalone) {
  if (typeof introJs === 'undefined') return;

  const intro = introJs();
  let steps = [
    {
      intro: this.gettextCatalog.getString('On this site you can manage your employees and rooms.')
    },
    {
      element: document.getElementById('btnAddEmployee'),
      intro: this.gettextCatalog.getString('Click here to add an employee.'),
      position: 'bottom'
    },
    {
      element: document.getElementById('btnAddRoom'),
      intro: this.gettextCatalog.getString('Click here to add a room.'),
      position: 'bottom'
    },
    {
      element: document.querySelectorAll('.edit-icon')[ 0 ],
      intro: this.gettextCatalog.getString('To edit a resource you have to click on the pencil icon.')
    },
  ];

  if (standalone) {
    console.log('last step without redirect');
  }

  intro.setOptions({
    steps,
    nextLabel: this.gettextCatalog.getString('next'),
    prevLabel: this.gettextCatalog.getString('back'),
    skipLabel: this.gettextCatalog.getString('skip'),
    doneLabel: this.gettextCatalog.getString('done'),
    exitOnOverlayClick: false,
    exitOnEsc: false,
  });

  intro.start();
};

module.exports = ResourceListCtrl;