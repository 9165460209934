var angularModule = angular.module('t2g.common.directives.confirmButtonDirective', ['gettext']);

module.exports = angularModule;

angularModule.directive('confirmButton', function ($compile, gettextCatalog) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      action: '&',
      message: '@',
      placement: '@'
    },
    link: function ($scope, $element) {
      var settings = {
        action: $scope.action,
        placement: $scope.placement || 'top',
        message: $scope.message || gettextCatalog.getString('realy?'),
        yes: gettextCatalog.getString('yes'),
        no: gettextCatalog.getString('no')
      };

      var isOpen = false;

      /**
       * hide the popover
       */
      var hidePopover = function () {
        $element.find('.popover').css('opacity', 0);
        $element.find('.popover').css('z-index', -1);
        isOpen = false;
      };


      /**
       * custom clickhandler couse bootstrap is buggy here
       */
      var clickHandler = function () {
        var $popover = $element.find('.popover');

        if (isOpen) {
          hidePopover();
        } else {
          if ($popover.length === 0) {
            $element.popover('show');
          }

          $element.find('.popover').css('opacity', 1);
          $element.find('.popover').css('z-index', 1050);

          isOpen = true;
        }
      };


      $element
        .popover({
          title: gettextCatalog.getString('warning'),
          trigger: 'manual',
          placement: settings.placement,
          container: $element,
          html: true,
          content: $compile(`<p class="deletebutton-message">${settings.message}</p><p class="deletebutton-message"><button class="btn btn-danger" ng-click="confirm($event)">${settings.yes}</button><button class="btn" ng-click="deny($event)">${settings.no}</button></p>`)($scope)
        })
        .on('click', clickHandler);

      /**
       * close the popover if messages was denied
       */
      $scope.deny = function (event) {
        hidePopover();
        event.stopPropagation();
      };


      /**
       * run action if message was confirmed
       */
      $scope.confirm = function (event, answer) {
        hidePopover();

        if (settings.action) {
          $scope.$eval(settings.action);
        }

        event.stopPropagation();
      };


      /**
       * GC
       */
      $scope.$on('$destroy', function () {
        $element.off();
      });
    }
  }
});
