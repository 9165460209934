'use strict';

module.exports = function() {

    return {
        restrict: 'A',
        link: function($scope, $element) {
            var scrollContainer = angular.element('.calendar__scroll-wrapper');
            var calendarHeader = angular.element('.calendar__header');
            var calendarHeaderHeight = calendarHeader[0].getBoundingClientRect().height;

            $scope.$on('t2g:window:resize', function(){
                calendarHeaderHeight = calendarHeader[0].getBoundingClientRect().height;
            });

            scrollContainer.on('scroll', function(){
                var scrollTop = this.scrollTop;
                $element.css({top: calendarHeaderHeight - scrollTop + 'px'});
            });
        }
    };
};
