'use strict';

var modalServiceModule = angular.module('t2g.common.services.modalFactory', []);

module.exports = modalServiceModule;

modalServiceModule.factory('ModalFactory', function ($animate,
                                                     $compile,
                                                     $controller,
                                                     $document,
                                                     $injector,
                                                     $q,
                                                     $rootScope,
                                                     $templateRequest,
                                                     LoadingIndicatorService) {
  var openModals = 0;

  /**
   * @param {String} path
   * @return {Promise}
   */
  var getTemplate = function (path) {
    return $templateRequest(path);
  };

  /**
   * @param {Object} resolver
   * @return {Promise}
   */
  var getResolveData = function (resolver) {

    var deferred = $q.defer();
    var resolveValues = {};
    var resolveItems = [];

    angular.forEach(resolver, function (resolve) {
      if (angular.isFunction(resolve) || angular.isArray(resolve)) {
        resolveItems.push($q.resolve($injector.invoke(resolve)));
      } else if (angular.isString(resolve)) {
        resolveItems.push($q.resolve($injector.get(resolve)));
      } else {
        resolveItems.push($q.resolve(resolve));
      }
    });

    $q.all(resolveItems).then(function (resolves) {
      var index = 0;

      angular.forEach(resolver, function (promise, resolveName) {
        resolveValues[resolveName] = resolves[index];
        index++;
      });

      deferred.resolve(resolveValues);
    }, function () {
      deferred.reject();
    });

    return deferred.promise;
  };


  return {
    open: function (options) {
      var promises = [getTemplate(options.template)];
      var controller = null;
      var controllerLocals = {
        $scope: $rootScope.$new()
      };
      var bodyElement = $document.find('body');
      var closeDefer = $q.defer();
      var modalInstance = {
        closed: closeDefer.promise
      };

      // resolve promises
      if (options.resolve) {
        promises.push(getResolveData(options.resolve));
      }

      LoadingIndicatorService.show();

      // after resolving promises show modal
      $q
        .all(promises)
        .then(function (response) {
          LoadingIndicatorService.hide();

          // delete all $promise to avoid merge max call stack error
          for (var promise in response[1]) {
            var resolve = response[1][promise];

            if (resolve) {
              delete resolve.$promise;
            }
          }

          // create controller instance
          angular.merge(controllerLocals, response[1]);
          controllerLocals.modalInstance = modalInstance;
          try {
            controller = $controller(options.controller, controllerLocals);
          } catch (error) {
            console.error(error);
          }

          // inject html
          var modalElement = angular.element(response[0]);

          $animate.enter($compile(modalElement)(controllerLocals.$scope), bodyElement)
            .then(function () {
              openModals++;
              $animate.addClass(bodyElement, 'show-modal');

              if (options.forceFront) {
                $animate.addClass(bodyElement, 'show-modal modal-front');
              }
            });

          modalInstance.close = function (response) {
            openModals--;

            if (openModals === 0) {
              $animate
                .removeClass(bodyElement, 'show-modal modal-front')
                .then(() => {
                  modalElement.remove();
                  controllerLocals.$scope.$destroy();
                  controller = null;
                  closeDefer.resolve(response);
                });
            } else {
              $animate
                .removeClass(bodyElement, 'modal-front')
                .then(() => {
                  modalElement.remove();
                  controllerLocals.$scope.$destroy();
                  controller = null;
                  closeDefer.resolve(response);
                });
            }
          };
        })
        .catch(function (error) {
          LoadingIndicatorService.hide();
        });

      return modalInstance;
    }
  };
});
