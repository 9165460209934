'use strict';

var angularModule = angular.module('t2g.common.data-access.shiftPlanning', []);

module.exports = angularModule;

angularModule.factory('ShiftPlanningResource', function ($resource, settings) {
        var resourceUrl = settings.apiBase + '/api/v2/shiftPlanning/:resourceId';

        var resource = $resource(resourceUrl, {}, {
            get: {method: 'get'},
            update: {method: 'put', params: {resourceId: '@resource'}}
        });

        return resource;
    }
);

angularModule.factory('ShiftPlanningDA', function (ShiftPlanningResource,
                                                   LoadingIndicatorService) {

    return {
        get: get,
        update: update
    };

    function get(resourceId, date) {
        LoadingIndicatorService.show();
        return ShiftPlanningResource
            .get({resourceId: resourceId, date: date})
            .$promise
            .then(function (response) {
                LoadingIndicatorService.hide();
                return response;
            });
    }

    function update(shift) {
        LoadingIndicatorService.show();
        return ShiftPlanningResource
            .update(shift)
            .$promise
            .then(function (response) {
                LoadingIndicatorService.hide();
                return response;
            });
    }

});
