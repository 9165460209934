var exportModule = angular.module('t2g.export', [
    'ui.router',
    require('t2g.common.data-access.user').name
]);

module.exports = exportModule;

exportModule.config(function($stateProvider) {
    $stateProvider
        .state('private.main.export', {
            url: '/export',
            title: 'Export',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/export/ExportView.html?v=3.82.3',
            controller: 'ExportCtrl',
            resolve: {
                office(OfficeDA) {
                    return OfficeDA.get();
                },
                async employees(ResourceDA) {
                    const response = await ResourceDA.list('employee');
                    return response.resources;
                }
            }
        });
});

exportModule.controller('ExportCtrl', function($scope,
                                               office,
                                               employees,
                                               DatepickerSettings,
                                               gettextCatalog) {

    var isCovidTestEnabled = _.get(office, 'settings.covidTest.enabled', false) && _.get(office, 'settings.covidTest.provider') === 'termin2go';
    $scope.downloadDate = new Date();
    $scope.currentMonth = new Date();
    $scope.employees = employees;
    $scope.dtpIsOpen = false;
    $scope.dtpMonthOpen = false;
    $scope.dtpOptions = DatepickerSettings.minMonth;
    $scope.currentMonth = moment().toDate();
    $scope.currentEmployee = null;
    $scope.reportingTypes = [
        {
            id: 'customerList',
            label: gettextCatalog.getString('Customer List (*.csv)'),
            getLink() {
                return '/api/v2/customer/export.csv';
            }
        },
        // {
        //     id: 'appointmentsIcal',
        //     label: gettextCatalog.getString('Appointments (*.ical)'),
        //     showResourceDropdown: true
        // },
        {
            id: 'appointmentsDaily',
            label: gettextCatalog.getString('Appointments daily (*.csv)'),
            showDateInput: true,
            getLink() {
                const today = moment($scope.downloadDate).format('YYYY-MM-DD');
                return `/api/v2/appointment/exports/csv?start=${today}&end=${today}`;
            }
        },
        {
            id: 'appointmentsMonthly',
            label: gettextCatalog.getString('Appointments monthly (*.csv)'),
            showMonthInput: true,
            getLink() {
                const startOfMonth = moment($scope.currentMonth).startOf('month').format('YYYY-MM-DD');
                const endOfMonth = moment($scope.currentMonth).endOf('month').format('YYYY-MM-DD');
                return `/api/v2/appointment/exports/csv?start=${startOfMonth}&end=${endOfMonth}`;
            }
        },
        {
            id: 'cashBookMonthly',
            label: gettextCatalog.getString('Cash Book monthly (*.csv)'),
            showMonthInput: true,
            showEmployeeInput: true,
            getLink() {
                const startOfMonth = moment($scope.currentMonth).startOf('month').format('YYYY-MM-DD');
                const endOfMonth = moment($scope.currentMonth).endOf('month').format('YYYY-MM-DD');
                const employee = $scope.currentEmployee ? `&employee=${$scope.currentEmployee }` : '';
                return `/api/v2/accounting/cashBook/export/csv?start=${startOfMonth}&end=${endOfMonth}${employee}`;
            }
        }
    ];

    if (isCovidTestEnabled) {
        $scope.reportingTypes.push({
            id: 'testMonthly',
            label: gettextCatalog.getString('Tests monthly (*.csv)'),
            showMonthInput: true,
            getLink() {
                const startOfMonth = moment($scope.currentMonth).startOf('month').format('YYYY-MM-DD');
                const endOfMonth = moment($scope.currentMonth).endOf('month').format('YYYY-MM-DD');
                return `/api/v2/appointment/exports/csv?start=${startOfMonth}&end=${endOfMonth}&type=tests`;
            }
        });
        $scope.reportingTypes.push({
            id: 'positiveTestsDaily',
            label: 'Positive Tests (*.csv)',
            showDateInput: true,
            getLink() {
                const date = moment($scope.downloadDate).format('YYYY-MM-DD');
                return `/api/v2/appointment/exports/positiveTests?start=${date}`;
            }
        });
        $scope.reportingTypes.push({
            id: 'healthDepartmentMails',
            label: gettextCatalog.getString('Health Department Notifications (*.csv)'),
            showMonthInput: true,
            getLink() {
                const startOfMonth = moment($scope.currentMonth).startOf('month').format('YYYY-MM-DD');
                const endOfMonth = moment($scope.currentMonth).endOf('month').format('YYYY-MM-DD');
                return `/api/v2//message/exports/csv?start=${startOfMonth}&end=${endOfMonth}`;
            }
        });


    }

    $scope.currentReportId = 'customerList';
    $scope.currentReport = $scope.reportingTypes.find(reportingType => reportingType.id === $scope.currentReportId);
    $scope.showMonthInput = false;
    $scope.showDateInput = false;
    $scope.showEmployeeDropdown = false;

    $scope.$watch('currentReportId', function (currentReportId) {
        $scope.currentReport = $scope.reportingTypes.find(reportingType => reportingType.id === currentReportId);
        $scope.showDateInput = $scope.currentReport && $scope.currentReport.showDateInput ? $scope.currentReport.showDateInput : false;
        $scope.showMonthInput = $scope.currentReport && $scope.currentReport.showMonthInput ? $scope.currentReport.showMonthInput : false;
        $scope.downloadLink = $scope.currentReport.getLink();
        $scope.showEmployeeDropdown = $scope.currentReport && $scope.currentReport.showEmployeeInput ? $scope.currentReport.showEmployeeInput : false;
    });

    $scope.downloadDateChanged = function () {
        if($scope.downloadDate && ['positiveTestsDaily', 'appointmentsDaily'].includes($scope.currentReportId)) {
            $scope.downloadLink = $scope.currentReport.getLink();
        }
    };

    $scope.currentMonthChanged = function () {
        if($scope.currentMonth && ['testMonthly', 'appointmentsMonthly', 'healthDepartmentMails', 'cashBookMonthly'].includes($scope.currentReportId)) {
            $scope.downloadLink = $scope.currentReport.getLink();
        }
    };

    $scope.currentEmployeeChanged = function () {
        if($scope.currentMonth && ['testMonthly', 'appointmentsMonthly', 'healthDepartmentMails', 'cashBookMonthly'].includes($scope.currentReportId)) {
            $scope.downloadLink = $scope.currentReport.getLink();
        }
    };

    // $scope.getAppointmentDownloadLink = function(object) {
    //     return '/api/v2/calendar/ical/' + object.ical + '/feed.ics';
    // };
});
