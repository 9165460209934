var angularModule = angular.module('t2g.common.directives.focusDirective', []);

module.exports = angularModule;

angularModule.directive('focus', function ($timeout) {
    return {
        restrict: 'A',
        link: function ($scope, $element) {
            $timeout(function () {
                $element.focus();
            }, 250);
        }
    }
});
