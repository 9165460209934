'use strict';

var BillingDetailsCtrl = function ($state,
                                   $stateParams,
                                   BillwerkService,
                                   countries,
                                   CountryConfig,
                                   gettextCatalog,
                                   LocalStorage,
                                   Notification,
                                   subscription,
                                   office) {

  var VOUCHERS = {

  };

  this._BillwerkService = BillwerkService;
  this.office = office;
  this.countries = countries;
  this._Notification = Notification;
  this._state = $state;
  this.gettextCatalog = gettextCatalog;
  this.submitted = false;
  this.model = subscription.billingInformation;
  this._LocalStorage = LocalStorage;
  this.subscriptionProcessModel = this._LocalStorage.get('SubscriptionProcess');
  if (_.get(this.subscriptionProcessModel, 'billing')) {
    _.merge(this.model, this.subscriptionProcessModel.billing);
  }
  this.isStandalone = this.subscriptionProcessModel ? false : true;

  // if(this.model.country.toUpperCase() === 'CH') {
  //   this.model.couponCode = 'COVID19';
  // } else {
  //   this.model.couponCode = 'CORONA';
  // }

  // if ($stateParams.voucher && VOUCHERS[$stateParams.voucher]) {
  //   this.model.couponCode = VOUCHERS[$stateParams.voucher];
  // }
};

BillingDetailsCtrl.prototype.save = function (goNext) {
  var self = this;
  var country = _.find(this.countries, { code: this.model.country.toUpperCase() });
  var locale = country ? country.language[0] : 'de-DE';

  var customer = {
    emailAddress: this.model.email,
    firstName: this.model.firstName,
    lastName: this.model.lastName,
    tag: this.office._id,
    companyName: this.model.companyName,
    address: {
      street: this.model.street,
      postalCode: this.model.postal,
      city: this.model.city,
      country: this.model.country.toUpperCase()
    },
    locale: locale
  };
  var cart;
  var subscription;

  if (this.model.vatID) {
    customer.vatId = this.model.vatID;
  }

  this.submitted = true;

  if (this.BillingDetailsForm.$valid) {
    if (goNext) {
      subscription = this.subscriptionProcessModel.subscription;

      cart = {
        planVariantId: subscription.planVariantId
      };

      if (subscription.package === 'plus') {
        if (subscription.additionalLicenses > 0) {
          cart.componentSubscriptions = [{
            componentId: subscription.componentId,
            quantity: subscription.additionalLicenses
          }];
        }
      } else if (subscription.package === 'premium') {
        if (subscription.additionalEmployees > 0) {
          cart.componentSubscriptions = [{
            componentId: subscription.componentId,
            quantity: subscription.additionalEmployees
          }];
        }
      }

      if (this.model.couponCode) {
        cart.couponCode = this.model.couponCode;
      }

      this._BillwerkService
        .createOrder(cart, customer)
        .then(function (response) {
          if (response.Error) {
            self._Notification.addErrorMessage();
          } else {
            self.model.billwerkOrder = response;
            self.subscriptionProcessModel.billing = self.model;
            self._LocalStorage.set('SubscriptionProcess', self.subscriptionProcessModel);
            self._state.transitionTo('private.main.paymentDetails');
          }
        })
        .catch(function () {
          self._Notification.addErrorMessage();
        });
    } else {
      this._BillwerkService
        .changeBillingInformation(customer)
        .then(function (response) {
          if (response.Error) {
            self._Notification.addErrorMessage();
          } else {
            self._Notification.addSuccessMessage(self.gettextCatalog.getString('Changed Billing Details successfully'));
          }
        })
        .catch(function () {
          self._Notification.addErrorMessage();
        });

    }
  }
};

BillingDetailsCtrl.prototype.goBack = function () {
  this._state.go('private.main.subscription', { openTab: 'plans' });
};

module.exports = BillingDetailsCtrl;
