var angularModule = angular.module('t2g.common.directives.fakeClick', []);

module.exports = angularModule;

angularModule.directive('fakeClick', function () {
    return {
        restrict: 'A',
        link: function ($scope, $element, $attributes) {
            $element.on('click', function () {
                $($attributes.fakeClick).click();
            });

            $scope.$on('$destroy', function () {
                $element.off();
            });
        }
    }
});
