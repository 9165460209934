var angularModule = angular.module('t2g.common.directives.remoteKeyBoardDirective', []);

module.exports = angularModule;

angularModule.directive('remoteKeyBoard', function () {
    return {
        restrict: 'A',
        link: function ($scope, $element, $attributes) {
            var isActive = false;
            //console.log('remoteKeyBoardDirective');
        }
    }
});
