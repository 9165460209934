var angularModule = angular.module('t2g.common.services.cacheService', []);

module.exports = angularModule;

angularModule.factory('AppointmentCache', function($cacheFactory) {
    var Cache = $cacheFactory('AppointmentCache', {capacity: 35});
    return Cache;
});

angularModule.factory('CustomerCache', function($cacheFactory) {
    var Cache = $cacheFactory('CustomerCache', {capacity: 5});
    return Cache;
});

angularModule.factory('ObjectCache', function($cacheFactory) {
    var Cache = $cacheFactory('ObjectCache', {capacity: 10});
    return Cache;
});

angularModule.factory('OfficeCache', function($cacheFactory) {
    var Cache = $cacheFactory('OfficeCache', {capacity: 5});
    return Cache;
});








