'use strict';

var shiftPlanningModule = angular.module('t2g.shiftPlanning', [
  'gettext',
  'ui.router',
  require('t2g.common.data-access.resource').name,
  require('t2g.common.data-access.shiftPlanning').name,
  require('t2g.common.services.3rdParty').name,
  require('t2g.common.services.notificationService').name
]);

module.exports = shiftPlanningModule;

shiftPlanningModule.controller('ShiftPlanningCtrl', require('./ShiftPlanningCtrl'));
shiftPlanningModule.controller('WorkingHoursCtrl', require('./workingHours/WorkingHoursCtrl'));
shiftPlanningModule.factory('shiftClipboard', require('./workingHours/shiftClipboardService'));
shiftPlanningModule.factory('workingHoursGenerator', require('./workingHours/workingHoursGeneratorService'));

shiftPlanningModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.shiftPlanning', {
      url: '/shift-planning',
      title: 'Working Hours',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/shiftPlanning/ShiftPlanningView.html?v=3.82.3',
      controller: 'ShiftPlanningCtrl as ShiftPlanningCtrl',
      resolve: {
        employees: function (ResourceDA) {
          return ResourceDA.list()
            .then(function (response) {
              return response.resources;
            });
        }
      }
    })
    .state('private.main.shiftPlanning.workingHours', {
      url: '/:employeeId/:date/?:openTab',
      title: 'Working Hours',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/shiftPlanning/workingHours/WorkingHoursView.html?v=3.82.3',
      controller: 'WorkingHoursCtrl as WorkingHoursCtrl',
      resolve: {
        shiftPlanning: function ($q,
                                 $stateParams,
                                 CountryConfigResource,
                                 ResourceDA,
                                 ShiftPlanningDA,
                                 OfficeDA,
                                 workingHoursGenerator) {
          const year = $stateParams.date ? moment($stateParams.date).year() : moment().year();

          return OfficeDA
            .get()
            .then((office) => {
              return $q.all([
                ResourceDA.get($stateParams.employeeId),
                ShiftPlanningDA.get($stateParams.employeeId, $stateParams.date),
                CountryConfigResource.get({countryCode: office.address.countryCode, years: year}).$promise
              ]).then(function (responses) {
                var employee = responses[0].resource;
                var shifts = responses[1];
                var countryConfig = responses[2].countryConfig;
                var dates = workingHoursGenerator.get(employee, shifts, countryConfig);

                return {
                  employee: employee,
                  dates: dates
                };
              });
            });
        },
        office: function (OfficeDA) {
          return OfficeDA.get();
        }
      }
    });
});
