var StatisticSalesPerEmployeeCtrl = function ($scope,
                                              $state,
                                              $stateParams,
                                              Notification,
                                              office,
                                              salesResponse,
                                              DatepickerSettings) {
    var self = this;
    this._Notification = Notification;
    this.labels = salesResponse.labels;
    this.data = salesResponse.data;
    this.rows = salesResponse.untransformed.data;
    this.dtpOptions = DatepickerSettings.minMonth;
    this.currency = _.get(office, 'settings.currency');

    if ($stateParams.start) {
        this.currentMonth = moment($stateParams.start).toDate();
    } else {
        this.currentMonth = new Date();
    }

    this.pieChartOptions = {
        animation: {
            animateRotate: true
        },
        legend: {
            display: true,
            position: 'top'
        }
    };


    $scope.$watch(function () {
        return self.currentMonth;
    }, function (newValue, oldValue) {
        if (newValue !== oldValue) {
            var startOfMonth = moment(self.currentMonth).startOf('month').format('YYYY-MM-DD');
            var endOfMonth = moment(self.currentMonth).endOf('month').format('YYYY-MM-DD');

            $state.go('private.main.statistic-sales-per-employee', {start: startOfMonth, end: endOfMonth});
        }
    });
};

module.exports = StatisticSalesPerEmployeeCtrl;
