var angularModule = angular.module('t2g.common.directives.onEnterDirective', []);

module.exports = angularModule;

angularModule.directive('onEnter', function () {
    return {
        restrict: 'A',
        link: function ($scope, $element, $attributes) {
            $element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                    $scope.$apply(function () {
                        $scope.$eval($attributes.onEnter);
                    });

                    event.preventDefault();
                }
            });
        }
    }
});
