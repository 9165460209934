'use strict';

var angularModule = angular.module('t2g.common.data-access.mail', [
    'ngResource'
]);

module.exports = angularModule;

angularModule.factory('MailResource', function ($resource,
                                                        settings) {

      var resourceUrl = settings.apiBase + '/api/v2/mail/:templateName';

      var resource = $resource(resourceUrl, {}, {
          get: {method: 'GET', isArray: false},
          update: {method: 'PATCH'},
          remove: {method: 'DELETE'},
          verifySMTP: {method: 'POST', params: {templateName: 'smtp'}}
      });

      return resource;
  }
);

angularModule.factory('MailDA', function (localStorageService,
                                                  settings,
                                                  MailResource) {

    return {
        get: get,
        update: update,
        remove: remove,
        verifySMTP: verifySMTP
    };

    function get(templateName) {
        return MailResource
          .get({templateName: templateName})
          .$promise
          .then(function (response) {
              return response.mailTemplate;
          });
    }
    
    function update(template) {
        return MailResource
            .update(template)
            .$promise;
    }
    
    function remove(template) {
        return MailResource
            .remove({templateName: template})
            .$promise;
    }

    function verifySMTP(options){
        return MailResource
          .verifySMTP(options)
          .$promise;
    }
});
