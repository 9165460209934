var BranchesCtrl = function ($scope,
                             $window,
                             $state,
                             BranchDA,
                             UserSession,
                             UserDA,
                             Notification,
                             branches,
                             subscription,
                             companyGroupSettings,
                             gettextCatalog) {

  console.log(branches);

  this._state = $state;
  this._Notification = Notification;
  this._BranchDA = BranchDA;
  this._UserSession = UserSession;
  this._UserDA = UserDA;
  this._window = $window;
  this.gettextCatalog = gettextCatalog;
  this.branches = branches;
  this.showPremiumNotification = ($scope.subscription.onlyBasicFunctions || $scope.subscription.package === 'single' || $scope.subscription.package === 'plus');
  this.newBranch = {};
  this.showValidation = false;
  this.isPackageIsSchnelltest = ['schnelltest', 'schnelltest2', 'schnelltest_archiv'].includes(subscription.package);
  this.companyGroupSettings = companyGroupSettings;

  this.reset();
};

BranchesCtrl.prototype.reset = function () {
  this.showValidation = false;
  this.newBranch.name = '';
};

BranchesCtrl.prototype.remove = function (branch) {
  const userSession = this._UserSession.get();

  this._BranchDA
    .delete(branch._id)
    .then(() => {
      return this._UserDA.ping();
    })
    .then((user) => {
      this.branches = user.branchNames;
      this._Notification.addSuccessMessage(this.gettextCatalog.getString('Branch removed'));
      this.reset();

      if (userSession.branch === branch._id) {
        this._state.go('private.main.branch', { id: userSession.office });
      }
    })
    .catch((error) => {
      this._Notification.addErrorMessage({ text: error });
    });
};

BranchesCtrl.prototype.add = function () {
  this.showValidation = true;

  if (this.newbranchForm.$valid) {
    this._BranchDA
      .insert(this.newBranch.name)
      .then(() => {
        return this._UserDA.ping();
      })
      .then((user) => {
        this.branches = user.branchNames;
        this._Notification.addSuccessMessage(this.gettextCatalog.getString('Branch added'));
        this.reset();
        this._window.location.reload();
      })
      .catch((error) => {
        this._Notification.addErrorMessage({ text: error });
      });
  }
};

BranchesCtrl.prototype.toggleSelection = function (type, branchId) {
  const branchIndex = this.companyGroupSettings.share[type].indexOf(branchId);

  if(branchIndex > -1) {
    this.companyGroupSettings.share[type].splice(branchIndex, 1);
  } else {
    this.companyGroupSettings.share[type].push(branchId);
  }
};

BranchesCtrl.prototype.save = function () {
  this._BranchDA.updateCompanyGroupSettings(this.companyGroupSettings);
};

module.exports = BranchesCtrl;

