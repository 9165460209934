var NewAutomatedCampaignCtrl = function (campaign, MarketingDA, Notification, gettextCatalog, $state, $stateParams) {
  this.submitted = false;
  this.gettextCatalog = gettextCatalog;
  this._Notification = Notification;
  this._MarketingDA = MarketingDA;
  this._$state = $state;
  this.campaign = campaign;
  this.campaign.start = new Date();
  this.isNew = $stateParams.id === 'new';
  this.mailPlaceholder = [
    {
      value: 'CUSTOMER_SALUTATION',
      label: gettextCatalog.getString('Customer - Salutation')
    },
    {
      value: 'CUSTOMER_FIRSTNAME',
      label: gettextCatalog.getString('Customer - Firstname')
    },
    {
      value: 'CUSTOMER_LASTNAME',
      label: gettextCatalog.getString('Customer - Lastname')
    },
    {
      value: 'CUSTOMER_AGE',
      label: gettextCatalog.getString('Customer - Age')
    }
  ];
};

NewAutomatedCampaignCtrl.prototype.save = function () {
  this.submitted = true;

  if (!this.form.$valid) {
    this._Notification.addErrorMessage(this.gettextCatalog.getString('Fill out all required fields.'));
  } else if(this.isNew) {
    this
      ._MarketingDA
      .startCampaign(this.campaign)
      .then(() => {
        this._Notification.addSuccessMessage(this.gettextCatalog.getString('Campaign {{name}} started.'), { name: this.campaign.name });
        this._$state.go('private.main.marketing');
      })
      .catch(() => {
        this._Notification.addErrorMessage(this.gettextCatalog.getString('Cant start campaign'));
      });
  } else {
    this
      ._MarketingDA
      .updateCampaign(this.campaign)
      .then(() => {
        this._Notification.addSuccessMessage(this.gettextCatalog.getString('Campaign {{name}} updated.'), { name: this.campaign.name });
      })
      .catch(() => {
        this._Notification.addErrorMessage(this.gettextCatalog.getString('Cant start campaign'));
      });
  }
};

module.exports = NewAutomatedCampaignCtrl;
