var ServiceResourceMatchController = function ($scope,
                                               $state,
                                               gettextCatalog,
                                               Notification,
                                               OfficeDA,
                                               officeResolve,
                                               resourceResolve) {

  this.scope_ = $scope;
  this.scope_.model = {
    office: officeResolve,
    resources: resourceResolve
  };
  this.state_ = $state;
  this.Notification_ = Notification;
  this._OfficeDA = OfficeDA;
  this.gettextCatalog = gettextCatalog;
};


/**
 * add and remove resource to service
 * @param resourceType
 * @param service
 * @param resource
 */
ServiceResourceMatchController.prototype.changeResource = function (resourceType, service, resource) {
  service.resources = service.resources || {};
  service.resources[resourceType] = service.resources[resourceType] || [];

  var resourceIndex = service.resources[resourceType].indexOf(resource._id);

  if (resourceIndex > -1) {
    service.resources[resourceType].splice(resourceIndex, 1);
  } else {
    service.resources[resourceType].push(resource._id);
  }
};


/**
 * save resource service match
 */
ServiceResourceMatchController.prototype.save = function () {
  var self = this;

  self._OfficeDA
    .update(self.scope_.model.office)
    .then(() => {
      self.Notification_.addSuccessMessage(self.gettextCatalog.getString('Service resource match saved'));
    })
    .catch((error) => {
      self.Notification_.addErrorMessage(self.gettextCatalog.getString('Cant save (Error: {{text}})', { text: error }));
    });
};

/**
 * check if a resource is added to service
 * @param resourceType
 * @param resource
 */
ServiceResourceMatchController.prototype.isChecked = function (resourceType, service, resource) {
  return service.resources[resourceType].indexOf(resource._id) > -1;
};


/**
 * go back ot service list view
 */
ServiceResourceMatchController.prototype.backToReferrer = function () {
  this.state_.go('private.main.services');
};

module.exports = ServiceResourceMatchController;

