module.exports = function ($scope,
                           $state,
                           $timeout,
                           gettextCatalog,
                           Notification,
                           officeResolve,
                           resourceResolve,
                           SyncDA,
                           UserSession,
                           ResourceDA) {

  $scope.resource = resourceResolve;
  $scope.office = officeResolve;


  /**
   * generate private calendar address
   */
  var generateCalendarAddress = function () {
    var base = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

    if ($scope.resource._id && $scope.resource.ical) {
      $scope.icalUrl = base + '/api/v2/calendar/ical/' + $scope.resource.ical + '/feed.ics';
    }
  };

  var loadSyncs = function () {
    if ($scope.resource._id) {
      SyncDA
        .get($scope.resource._id)
        .then(function (response) {
          $scope.syncs = response.syncList;
        });
    }
  };

  $scope.save = function () {
    if (!$scope.resource._id) {
      $scope.resource.type = 'room';

      ResourceDA
        .insert($scope.resource)
        .then((response) => {
          loadSyncs();
          $scope.resource = response;
          $state.go('private.main.resourcesDetails', {
            type: $scope.resource.type,
            resourceId: $scope.resource._id
          });
          Notification.addSuccessMessage(gettextCatalog.getString('{{name}} created', { name: $scope.resource.name }));
        })
        .catch(() => {
          Notification.addErrorMessage(gettextCatalog.getString('Cant add resource'));
        });
    } else {
      ResourceDA
        .update($scope.resource)
        .then(() => {
          Notification.addSuccessMessage(gettextCatalog.getString('{{name}} updated', { name: $scope.resource.name }));
        })
        .catch(() => {
          Notification.addErrorMessage(gettextCatalog.getString('Cant save resource'));
        });
    }
  };

  $scope.delete = function () {
    ResourceDA
      .remove($scope.resource._id)
      .then(() => {
        Notification.addSuccessMessage(gettextCatalog.getString('{{name}} removed', {name: $scope.resource.name}));
        $state.go('private.main.resources');
      })
      .catch(() => {
        Notification.addErrorMessage({text: gettextCatalog.getString('Cant remove resource')});
      });
  };


  if ($scope.resource.ical) {
    $scope.privateCalendarAddressEnabled = true;
    generateCalendarAddress();
  }

  loadSyncs();


  /**
   * enable/disable private calendar address
   */
  $scope.changePrivateCalendarAddressEnabledState = function () {
    if ($scope.privateCalendarAddressEnabled) {
      $scope.resetPrivateAddress();
    } else {
      $scope.resource.ical = null;
      $scope.icalUrl = '';
    }
  };


  /**
   * get new private calendar address from API
   */
  $scope.resetPrivateAddress = function () {
    ResourceDA
      .refreshCalendarAddress($scope.resource._id)
      .then(function (response) {
        $scope.resource.ical = response.ical;
        generateCalendarAddress();
        Notification.addSuccessMessage(gettextCatalog.getString('calendar address resetted'));
      })
      .catch(function () {
        Notification.addErrorMessage(gettextCatalog.getString('Cant reset private calendar address'));
      });
  };

  /**
   * add new external calendar
   */
  $scope.addCalendarUrl = function () {
    var calendarSync = {
      type: 'ical',
      address: $scope.externalIcalAddress,
      copyDescription: $scope.copyDescription,
      resourceType: 'room'
    };

    SyncDA
      .insert($scope.resource._id, calendarSync)
      .then(function (response) {
          $scope.externalIcalAddress = '';
          $scope.syncs.push(response.sync);
          Notification.addSuccessMessage(gettextCatalog.getString('successfully subscribed to calendar'));
        },
        // TODO: implement a helper function or a global error handler
        function (response) {
          var errorMsg = _.get(response, 'data.error[0].msg');
          var error;

          if (errorMsg) {
            error = gettextCatalog.getString(errorMsg);
          } else {
            error = gettextCatalog.getString('Unknown');
          }

          Notification.addErrorMessage(gettextCatalog.getString('Cant subscribe to calendar (Error: {{error}})', { error: error }));
        }
      );
  };

  $scope.removeSync = function (sync) {
    SyncDA
      .delete($scope.resource._id, sync._id)
      .then(function () {
          var index = $scope.syncs.indexOf(sync);
          $scope.syncs.splice(index, 1);
          Notification.addSuccessMessage(gettextCatalog.getString('Calendar subscription removed'));
        },
        function (error) {
          Notification.addErrorMessage(gettextCatalog.getString('Cant remove calendar subscription (Error: {{error}})', { error: error }));
        }
      );
  };

};
