module.exports = function($timeout) {

    var active = false;

    return {
        activate,
        deactivate,
        isActive
    };

    function activate(){
        active = true;
    }

    function deactivate(){
        $timeout(function(){
            active = false;
        });
    }

    function isActive(){
        return active;
    }

};
