'use strict';

module.exports = function ($scope,
                           $state,
                           $timeout,
                           gettextCatalog,
                           Notification,
                           officeResolve,
                           resourceResolve,
                           SyncDA,
                           UserSession,
                           ResourceDA) {

  $scope.resource = resourceResolve;
  $scope.office = officeResolve;
  $scope.weekDays = [];

  if ($scope.resource.image) {
    $scope.imagePreview = $scope.resource.image.m ? $scope.resource.image.m : $scope.resource.image;
  }

  $scope.reasons = [
    {
      key: 'other',
      text: gettextCatalog.getString('other')
    },
    {
      key: 'holiday',
      text: gettextCatalog.getString('holiday')
    },
    {
      key: 'ill',
      text: gettextCatalog.getString('ill')
    },
    {
      key: 'pd',
      text: gettextCatalog.getString('pd')
    }
  ];


  /**
   * generate private calendar address
   */
  var generateCalendarAddress = function () {
    var base = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

    if ($scope.resource._id && $scope.resource.ical) {
      $scope.icalUrl = base + '/api/v2/calendar/ical/' + $scope.resource.ical + '/feed.ics';
    }
  };

  var loadSyncs = function () {
    if ($scope.resource._id) {
      SyncDA
        .get($scope.resource._id)
        .then(function (response) {
          $scope.syncs = response.syncList;
        });
    }
  };

  /**
   * generate resource image preview
   * @param $files
   */
  $scope.onResourceImageSelect = function (files) {
    var file = files && files.length ? files[0] : null;

    if (file && window.FileReader && file.type.indexOf('image') > -1) {
      var fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onload = function (e) {
        $timeout(function () {
          $scope.imagePreview = e.target.result;
        });
      };

      ResourceDA
        .uploadImage($scope.resource._id, file)
        .then((response) => {
          $scope.resource.image = response.image;
          $scope.imagePreview = response.image.m;
          Notification.addSuccessMessage(gettextCatalog.getString('Image uploaded'));
        })
        .catch((response) => {
          if (response.data.error.message === 'file_to_large') {
            Notification.addErrorMessage(gettextCatalog.getString('File is to large (max 10 MB)'));
          } else if (response.data.error.message === 'extension_not_allowed') {
            Notification.addErrorMessage(gettextCatalog.getString('File extension not of type [JPG,PNG]'));
          } else {
            Notification.addErrorMessage({text: response.data.error});
          }
        });
    }
  };

  $scope.deleteImage = function () {
    ResourceDA
      .deleteImage($scope.resource._id)
      .then((response) => {
        $scope.resource.image = response.image;
        $scope.imagePreview = response.image.m;
        Notification.addSuccessMessage(gettextCatalog.getString('Image deleted'));
      })
      .catch(() => {
        Notification.addErrorMessage(gettextCatalog.getString('Cannot delete image'));
      });
  };

  /**
   * concat firstname and lastname to "lastname, firstname"
   */
  $scope.concatName = function () {
    var nameParts = [];

    if ($scope.resource.lastname) {
      nameParts.push($scope.resource.lastname);
    }

    if ($scope.resource.firstname) {
      nameParts.push($scope.resource.firstname);
    }

    $scope.resource.name = nameParts.join(', ');
  };


  /**
   * generate an array with n elements for ng-repeat loop
   * @param n
   * @returns {Array}
   */
  $scope.getNumber = function (n) {
    return new Array(n);
  };


  /**
   * add/remove a second column to working hour table
   * @param day
   */
  $scope.adjustWorkingTime = function (day) {
    if ($scope.resource.workingHours[day].times.length === 1) {
      var firstEndTime = $scope.resource.workingHours[day].times[0].end;

      $scope.resource.workingHours[day].times.push({
        start: ((firstEndTime + 60) > 1440) ? 1440 : firstEndTime + 60,
        end: ((firstEndTime + 240) > 1440) ? 1440 : firstEndTime + 240
      });
    } else {
      $scope.resource.workingHours[day].times.splice(1, 1);
    }
  };

  $scope.save = function () {
    if (!$scope.resource._id) {
      $scope.resource.type = 'employee';

      ResourceDA
        .insert($scope.resource)
        .then((response) => {
          loadSyncs();
          $scope.resource = response;
          $state.go('private.main.resourcesDetails', {
            type: $scope.resource.type,
            resourceId: $scope.resource._id
          });
          Notification.addSuccessMessage(gettextCatalog.getString('{{name}} created', {name: $scope.resource.name}));
        })
        .catch(() => {
          Notification.addErrorMessage(gettextCatalog.getString('Cant add resource'));
        });
    } else {
      ResourceDA
        .update($scope.resource)
        .then((resource) => {
          $scope.resource = resource;
          Notification.addSuccessMessage(gettextCatalog.getString('{{name}} updated', {name: $scope.resource.name}));
        })
        .catch(() => {
          Notification.addErrorMessage(gettextCatalog.getString('Cant save resource'));
        });
    }
  };

  $scope.delete = function () {
    ResourceDA
      .remove($scope.resource._id)
      .then(() => {
        Notification.addSuccessMessage(gettextCatalog.getString('{{name}} removed', {name: $scope.resource.name}));
        $state.go('private.main.resources');
      })
      .catch((error) => {
        if (error.data.error === 'open_appointments') {
          Notification.addErrorMessage({text: gettextCatalog.getString('Cant remove resource because of open appointments (count: {{count}})',{ count: error.data.count })});
        } else {
          Notification.addErrorMessage({text: gettextCatalog.getString('Cant remove resource')});
        }
      });
  };


  /**
   * copy opening hours to resource working hours
   */
  $scope.copyOpeningHours = function () {
    for (var i = 0, ohLen = $scope.office.openingHours.regular.length; i < ohLen; i++) {
      if ($scope.office.openingHours.regular[i].open) {
        $scope.resource.workingHours[i].available = true;

        $scope.resource.workingHours[i].times[0].start = $scope.office.openingHours.regular[i].times[0].start;
        $scope.resource.workingHours[i].times[0].end = $scope.office.openingHours.regular[i].times[0].end;

        if ($scope.office.openingHours.regular[i].times.length > 1) {
          if (!$scope.resource.workingHours[i].times[1]) {
            $scope.resource.workingHours[i].times[1] = {};
          }

          $scope.resource.workingHours[i].times[1].start = $scope.office.openingHours.regular[i].times[1].start;
          $scope.resource.workingHours[i].times[1].end = $scope.office.openingHours.regular[i].times[1].end;
        }
      } else {
        $scope.resource.workingHours[i].available = false;
      }
    }
  };


  /**
   * add an absent day
   */
  $scope.addAbsentRow = function () {
    $scope.resource.absent.push({
      start: moment().startOf('day'),
      end: moment().endOf('day'),
      reason: 'OTHER'
    });
  };


  /**
   * remove an absent day
   * @param absentDay
   */
  $scope.removeAbsentRow = function (absentDay) {
    var index = $scope.resource.absent.indexOf(absentDay);

    if (index > -1) {
      $scope.resource.absent.splice(index, 1);
    }
  };

  if ($scope.resource.ical) {
    $scope.privateCalendarAddressEnabled = true;
    generateCalendarAddress();
  }

  loadSyncs();


  /**
   * enable/disable private calendar address
   */
  $scope.changePrivateCalendarAddressEnabledState = function () {
    if ($scope.privateCalendarAddressEnabled) {
      $scope.resetPrivateAddress();
    } else {
      $scope.resource.ical = null;
      $scope.icalUrl = '';
    }
  };


  /**
   * get new private calendar address from API
   */
  $scope.resetPrivateAddress = function () {
    ResourceDA
      .refreshCalendarAddress($scope.resource._id)
      .then(function (response) {
        $scope.resource.ical = response.ical;
        generateCalendarAddress();
        Notification.addSuccessMessage(gettextCatalog.getString('calendar address resetted'));
      })
      .catch(function () {
        Notification.addErrorMessage(gettextCatalog.getString('Cant reset private calendar address'));
      });
  };

  /**
   * add new external calendar
   */
  $scope.addCalendarUrl = function () {
    var calendarSync = {
      type: 'ical',
      address: $scope.externalIcalAddress,
      copyDescription: $scope.copyDescription,
      resourceType: 'employee'
    };

    SyncDA
      .insert($scope.resource._id, calendarSync)
      .then(function (response) {
          $scope.externalIcalAddress = '';
          $scope.syncs.push(response.sync);
          Notification.addSuccessMessage(gettextCatalog.getString('successfully subscribed to calendar'));
        },
        function (response) {
          Notification.addErrorMessage(gettextCatalog.getString('Cant subscribe to calendar'));
        }
      );
  };

  $scope.removeSync = function (sync) {
    SyncDA
      .delete($scope.resource._id, sync._id)
      .then(function () {
          var index = $scope.syncs.indexOf(sync);
          $scope.syncs.splice(index, 1);
          Notification.addSuccessMessage(gettextCatalog.getString('calendar subscription removed'));
        },
        function (error) {
          Notification.addErrorMessage(gettextCatalog.getString('Cant remove calendar subscription'));
        }
      );
  };

};
