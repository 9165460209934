var angularModule = angular.module('t2g.common.services.holderService', []);

module.exports = angularModule;

angularModule.factory('AppointmentHolder', function() {
    var _appointment = null;

    return {
        setAppointment: function(appointment) {
            _appointment = appointment;
        },
        getAppointment: function() {
            return _appointment;
        }
    };
});
