'use strict';

var angularModule = angular.module('t2g.common.filter.dateFilter', []);

module.exports = angularModule;

angularModule.filter('time', function() {
    return function(time, format) {
        switch (format) {
            case 'mm-to-hh:mm':
                var hours = Math.floor(time / 60);
                var minutes = time % 60;

                return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
        }
    };
});
