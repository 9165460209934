'use strict';

var angularModule = angular.module('t2g.common.filter', [
    require('./dateFilter').name,
    require('./momentFilter').name,
    require('./propsFilter').name,
    require('./regExFilter').name,
    require('./orFilterNew').name,
    require('./truncateFilter').name,
    require('./uniqueFilter').name
]);

module.exports = angularModule;
