let angularModule = angular.module('t2g.common.data-access.covid-test', []);

module.exports = angularModule;

angularModule.factory('CovidTestResource', function ($resource, settings) {
  const resourceUrl = settings.apiBase + '/api/v2/covid-tests';
  const resource = $resource(resourceUrl, {}, {
    list: { method: 'GET' }
  });

  return resource;
});

angularModule.factory('CovidTestDA', function (CovidTestResource) {
  return {
    list
  };

  function list() {
    return CovidTestResource
      .list()
      .$promise
      .then(response => response.tests);
  }
});

