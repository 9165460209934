'use strict';

var angularModule = angular.module('t2g.common.data-access.test', []);

module.exports = angularModule;

angularModule.factory('TestResource', function ($resource,
                                                settings) {

  var resourceUrl = settings.apiBase + '/api/v2/test/:subResource1/:subResource2';

  return $resource(resourceUrl, {}, {
    getTest: {method: 'get' },
    getUpcoming: {method: 'get', params: {subResource1: 'upcoming'}},
    getInProgress: {method: 'get', params: {subResource1: 'inProgress'}},
    getDone: {method: 'get', params: {subResource1: 'done'}}
  });
});

angularModule.factory('TestDA', function ($q,
                                          TestResource) {

  return {
    getTest,
    getUpcoming,
    getInProgress,
    getDone,
    start,
    correctResult,
    finishLaborTest,
    stop
  };

  function getTest(appointmentId) {
    return TestResource
      .getUpcoming({subResource1: appointmentId})
      .$promise
      .then(function (response) {
        return response.test;
      });
  }

  function start(appointmentId) {
    return TestResource
      .getUpcoming({subResource1: appointmentId, subResource2: 'start'})
      .$promise
      .then(function (response) {
        return response.appointment;
      });
  }

  function correctResult(appointmentId) {
    return TestResource
      .getUpcoming({subResource1: appointmentId, subResource2: 'correctResult'})
      .$promise
      .then(function (response) {
        return response.appointment;
      });
  }

  function finishLaborTest(appointmentId) {
    return TestResource
      .getUpcoming({subResource1: appointmentId, subResource2: 'finishLaborTest'})
      .$promise
      .then(function (response) {
        return response.appointment;
      });
  }

  function stop(appointmentId) {
    return TestResource
      .getUpcoming({subResource1: appointmentId, subResource2: 'stop'})
      .$promise
      .then(function (response) {
        return response.appointment;
      });
  }

  function getUpcoming(date) {
    return TestResource
      .getUpcoming({date})
      .$promise
      .then(function (response) {
        return response;
      });
  }

  function getInProgress() {
    return TestResource
      .getInProgress()
      .$promise
      .then(function (response) {
        return response;
      });
  }

  function getDone(date) {
    return TestResource
      .getDone({date})
      .$promise
      .then(function (response) {
        return response;
      });
  }

});


