module.exports = function ($rootScope, $scope, userResolve, ModalService) {
  $scope.users = userResolve;

  /**
   * delete a employee if delete-button clicked in modal
   */
  $rootScope.$on('User:delete', function (event, user) {
    for (var i = 0; i < $scope.users.length; i++) {
      if ($scope.users[ i ]._id == user._id) {
        $scope.users.splice(i, 1);
        break;
      }
    }
  });


  $scope.openUserModal = function (user) {
    let userId = user ? user._id : null;

    ModalService.openUserModal(userId, () => {
    });
  };
};
