'use strict';

var CashEntryCancelModalCtrl = function (Notification,
                                         AccountingDA,
                                         cashBookEntry,
                                         office,
                                         gettextCatalog,
                                         modalInstance) {
    
    this._modalInstance = modalInstance;
    this._AccountingDA = AccountingDA;
    this._Notification = Notification;
    this.model = cashBookEntry;
    this.gettextCatalog = gettextCatalog;
    this.office = office;
    this.formSubmitted = false;
};

CashEntryCancelModalCtrl.prototype.close = function () {
    this._modalInstance.close();
};

CashEntryCancelModalCtrl.prototype.save = function () {
    var self = this;

    this.model.cancelled = new Date();

    this._AccountingDA
        .updateCashBookEntry(this.model)
        .then(function () {
            self.close();
            self._Notification.addSuccessMessage(self.gettextCatalog.getString('Cashbook Entry was cancelled'));
        })
        .catch(function (error) {
            self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant cancel Cashbook Entry (Error: {{error}})', {error: error}));
        });
};

module.exports = CashEntryCancelModalCtrl;