'use strict';

var MailTemplateCtrl = function ($scope,
                                 $state,
                                 $stateParams,
                                 MailDA,
                                 Notification,
                                 gettextCatalog,
                                 office,
                                 template,
                                 placeholder) {

  this.showPremiumNotification = ($scope.subscription.onlyBasicFunctions || $scope.subscription.package === 'single');
  this._state = $state;
  this._MailDA = MailDA;
  this._Notification = Notification;
  this.office = office;
  this.isCovidTestEnabled = _.get(this.office, 'settings.covidTest.enabled', false) && _.get(this.office, 'settings.covidTest.provider') === 'termin2go';

  // APPOINTMENT_QR_CODE
  this.templates = [
    {
      id: 'acceptBooking',
      name: gettextCatalog.getString('Accept Booking')
    },
    {
      id: 'declineBooking',
      name: gettextCatalog.getString('Decline Booking')
    },
    {
      id: 'remindAppointment',
      name: gettextCatalog.getString('Appointment Reminder')
    },
    {
      id: 'appointmentChanged',
      name: gettextCatalog.getString('Appointment Changed')
    },
    {
      id: 'appointmentDeleted',
      name: gettextCatalog.getString('Appointment Removed')
    },
    {
      id: 'signature',
      name: gettextCatalog.getString('signature')
    }
  ];

  if (this.isCovidTestEnabled) {
    this.templates.push({
      id: 'covidTestResult',
      name: gettextCatalog.getString('COVID Test Result')
    });
  }

  this.template = template;
  this.placeholder = placeholder;
  this.gettextCatalog = gettextCatalog;
  this.currentTemplate = $stateParams.templateName;

  if(['acceptBooking', 'remindAppointment'].includes(this.template.name) &&
    !placeholder.find(p => p.value === 'APPOINTMENT_QR_CODE')) {
    placeholder.push({
      value: 'APPOINTMENT_QR_CODE',
      isHTML: true,
      label: 'Test - QR-Code'
    });
  }
};

MailTemplateCtrl.prototype.save = function () {
  var self = this;

  self._MailDA
    .update({
      name: self.template.name,
      content: self.template.content
    })
    .then(function () {
      self._Notification.addSuccessMessage(self.gettextCatalog.getString('E-Mail Template saved'));
    })
    .catch(function (error) {
      self._Notification.addErrorMessage({ text: error });
    });
};

MailTemplateCtrl.prototype.reset = function () {
  var self = this;

  self._MailDA
    .remove(self.template.name)
    .then(function () {
      self._Notification.addSuccessMessage(self.gettextCatalog.getString('E-Mail Template resetted'));
      self._state.reload();
    })
    .catch(function (error) {
      self._Notification.addErrorMessage({ text: error });
    });
};

MailTemplateCtrl.prototype.templateChanged = function () {
  this._state.go('private.main.mailTemplate', { templateName: this.currentTemplate });
};

module.exports = MailTemplateCtrl;

