'use strict';

var PinModalCtrl = function ($scope, $cookies, office, user, modalInstance) {

  this._modalInstance = modalInstance;
  this.pin = '';
  this._offce = office;
  this._user = user;
  this._cookies = $cookies;
  this.submitted = false;
  this.error = {
    pin: false
  };
};

PinModalCtrl.prototype.save = function () {
  const pinSettings = _.get(this._offce, 'settings.employeePIN');
  const employeePin = Object.keys(pinSettings.pins).find(key => pinSettings.pins[key] === this.pin);

  this.submitted = true;

  if (employeePin) {
    this.error.pin = false;
    this._cookies.putObject('t2g-employeePin', employeePin, { expires: moment().add(30, 'seconds').toDate()})
    this._modalInstance.close('save');
  } else {
    this.error.pin = true;
  }
};

PinModalCtrl.prototype.abort = function () {
  this._modalInstance.close('abort');
};

module.exports = PinModalCtrl;
