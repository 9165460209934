'use strict';

var angularModule = angular.module('t2g.common.directives.colorpicker', []);

module.exports = angularModule;

angularModule.directive('colorpicker', function ($timeout) {
    return {
        restrict: 'E',
        require: '?ngModel',
        replace: true,
        scope: {
            parentModel: '=ngModel',
            disablePicker: '=disable'
        },
        template: '<div class="input-group"><input type="text" class="form-control" name="foreground" id="foreground" ng-model="parentModel" ng-disabled="disablePicker"><span class="input-group-addon"><i></i></span></div>',
        link: function ($scope, $element, $attributes, $ngModel) {
            $element
                .colorpicker()
                .on('changeColor', function (event) {
                    $scope.ngModel = event.color.toHex();
                    $ngModel.$setViewValue($scope.ngModel);
                });

            $timeout(function () {
                if ($ngModel.$modelValue) {
                    $element.colorpicker('setValue', $ngModel.$modelValue)
                }
            });
        }
    }
});