'use strict';

var angularModule = angular.module('t2g.common.filter.orFilterNew', []);

module.exports = angularModule;

angularModule.filter('orFilterNew', function() {
  return function(items, attributes, filterString, limit) {
    var matches = [];
    var filterStringRegEx = new RegExp(filterString, 'gi');

    _.forEach(items, function(item) {
      _.forEach(attributes, function(attribute) {
        var value = item[attribute];

        if (value && value.match(filterStringRegEx)) {
          matches.push(item);
          return false;
        }
      });

      if (matches.length === limit) {
        return false;
      }
    });

    return matches;
  };
});
