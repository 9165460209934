module.exports = function($scope, $rootScope, CourseModal, officeResolve) {
    $scope.office = officeResolve;

    /**
     *  open course modal
     * @param course
     */
    $scope.openCourseModal = function(course) {
        CourseModal.open(course);
    };


    /**
     * update office model
     * @type {*}
     */
    var updateOffice = $rootScope.$on('Office:update', function(event, response) {
        $scope.office = response;
    });


    /**
     * remove event listener if day has changed (CalendarCtrl destroyed)
     */
    $scope.$on('$destroy', function() {
        updateOffice();
    });

};
