var TrashListCtrl = function (items, DatepickerSettings, TrashDA, Notification, UserSession, gettextCatalog) {
  this.items = items;
  this.dtpfilterDeleteDateOpened = false;
  this.dtpfilterAppointmentDateOpened = false;
  this.dtpOptions = angular.copy(DatepickerSettings.default);
  this.filter = {
    type: undefined,
    deleteDate: undefined,
    appointmentDate: undefined,
    customerFirstname: undefined,
    customerLastname: undefined
  };
  this._TrashDA = TrashDA;
  this._Notification = Notification;
  this.isAllowedRole = UserSession.checkRole('admin');
  this.gettextCatalog = gettextCatalog;
  this.actionMenu = {
    event: 'click',
    items: [
      {
        label: gettextCatalog.getString('Restore from Trash'),
        action: this.restore.bind(this),
        icon: 'icon-refresh'
      },
      {
        label: gettextCatalog.getString('Delete forever'),
        action: this.remove.bind(this),
        icon: 'icon-delete',
        seperator: true
      },
      {
        label: gettextCatalog.getString('Cancel'),
        action: angular.noop,
      },
    ]
  };
  this.typeSelectItems = [
    {
      value: 'appointment',
      label: gettextCatalog.getString('Appointment')
    },
    {
      value: 'customer',
      label: gettextCatalog.getString('Customer')
    }
  ];
};

TrashListCtrl.prototype.resetFilter = function () {
  this.filter = {
    type: undefined,
    deleteDate: undefined,
    appointmentDate: undefined,
    customerFirstname: undefined,
    customerLastname: undefined
  };
};

TrashListCtrl.prototype.search = function () {
  this._TrashDA
    .list(this.filter)
    .then((items) => {
      this.items = items;
    })
    .catch((error) => {
      console.error(error);
    });
};

TrashListCtrl.prototype.restore = function (event, originalEvent, eventData) {
  this._TrashDA
    .restore(eventData.item._id)
    .then(() => {
      const itemIndex = this.items.findIndex(item => item._id === eventData.item._id);
      if(itemIndex > -1) {
        this.items.splice(itemIndex, 1);
      }

      this._Notification.addSuccessMessage(this.gettextCatalog.getString('Item was restored successfully'));
    })
    .catch((error) => {
      this._Notification.addErrorMessage({text: error});
    });
};

TrashListCtrl.prototype.remove = function (event, originalEvent, eventData) {
  this._TrashDA
    .remove(eventData.item._id)
    .then(() => {
      const itemIndex = this.items.findIndex(item => item._id === eventData.item._id);

      if(itemIndex > -1) {
        this.items.splice(itemIndex, 1);
      }

      this._Notification.addSuccessMessage(this.gettextCatalog.getString('Item was removed successfully'));
    })
    .catch((error) => {
      this._Notification.addErrorMessage({text: error});
    });
};

TrashListCtrl.prototype.removeAll = function () {
  this._TrashDA
    .removeAll()
    .then(() => {
      this._TrashDA
        .list()
        .then((response) => {
          this.items = response;
          this._Notification.addSuccessMessage(this.gettextCatalog.getString('Trash was emptied successfully'));
        });
    })
    .catch((error) => {
      this._Notification.addErrorMessage({text: error});
    });
};

module.exports = TrashListCtrl;