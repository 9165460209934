'use strict';

var TestLayoverCtrl = function ($scope,
                                $timeout,
                                Notification,
                                ModalService,
                                AppointmentDA,
                                TestDA,
                                DocumentDA,
                                gettextCatalog,
                                test,
                                employees,
                                office,
                                customLabels,
                                modalInstance) {
  const covidTests = _.get(office, 'settings.covidTest.tests');
  let officeService;

  if (test.service && test.service._id) {
    office.serviceGroups.some((serviceGroup) => {
      officeService = serviceGroup.services.find(service => service._id === test.service._id);
      return !!officeService;
    });

    if (officeService.resources && officeService.resources.employee && officeService.resources.employee.length) {
      this.employees = employees.filter(employee => {
        return officeService.resources.employee.includes(employee._id);
      });
    }
  }

  this._modalInstance = modalInstance;
  this._Notification = Notification;
  this._ModalService = ModalService;
  this._TestDA = TestDA;
  this._AppointmentDA = AppointmentDA;
  this._DocumentDA = DocumentDA;
  this._timeout = $timeout;
  this.submitted = false;
  this.gettextCatalog = gettextCatalog;
  this.test = test;
  this.timeOffset = moment().diff(this.test.now);
  this.office = office;
  this.submitted = false;
  this.customerLabel = gettextCatalog.getString(customLabels.customer.toLowerCase());
  this.isCWAEnabled = _.get(office, 'settings.covidTest.cwa.enabled', false);
  this.isDocumentLoading = false;
  this.isDocumentError = false;
  this.initEmployee = test.employee._id;
  this.documentInFuture = false;
  this.missingDocumentParams = [];
  this.sendDocumentToCustomer = test.customer && test.customer.email ? true : false;
  this.sendDocumentToHealthDepartment = true;
  this.testType = test.testType;
  this.initTab = 'test';
  this.testTypes = {
    pcr: 'PCR',
    quickTest: 'Schnelltest',
    pcrLabor: 'PCR (Labor)'
  };
  this.resultObject = {
    covidResult: _.get(this.test, 'meta.covidResult', 'negative'),
    selfPay: _.get(this.test, 'meta.selfPay', false)
  };
  this.checks = test.checks || {};
  this.testReasons = [
    // {
    //   id: 9,
    //   label: 'Angehörige von Infizierten (kostenfrei)'
    // },
    {
      id: 10,
      label: 'Pflegende Angehörige sowie Menschen mit Behinderungen und deren Betreuungskräfte (kostenfrei)'
    },
    {
      id: 11,
      label: 'Besucher von Kliniken und Pflegeheimen (kostenfrei)'
    },
    // {
    //   id: 12,
    //   label: 'Menschen die sich nicht impfen lassen können (kostenfrei)'
    // },
    // {
    //   id: 13,
    //   label: 'Frauen zu Beginn der Schwangerschaft (kostenfrei)'
    // },
    // {
    //   id: 14,
    //   label: 'Kinder bis fünf Jahre (kostenfrei)'
    // },
    // {
    //   id: 15,
    //   label: 'Teilnahme an klinischen Studien zu Medikamenten gegen COVID-19 (kostenfrei)'
    // },
    // {
    //   id: 16,
    //   label: 'Freitestung (kostenfrei)'
    // },
    {
      id: 19,
      label: 'Leistungsberechtigte, die im Rahmen eines Persönlichen Budgets nach dem § 29 SGB IX Personen beschäftigen, sowie Personen, die bei Leistungsberechtigten im Rahmen eines Persönlichen Budgets beschäftigt sind (kostenfrei)'
    },
    // {
    //   id: 17,
    //   label: 'Voraufenthalt in Virusvariantengebieten'
    // },
    // {
    //   id: 18,
    //   label: 'Bürgertestung (3,00€ Eigenbeteiligung nur für berechtigten Personengruppe)'
    // },
    {
      id: 99,
      label: `keine der genannten Gründe (${this.office.settings.covidTest.prices.rapid}€)`
    },
  ];
  this.testReasons2 = [
    {
      id: 0,
      label: 'Ich werde am Tag, an dem die Testung erfolgt eine Veranstaltung in einem Innenraum besuchen.'
    },
    {
      id: 1,
      label: 'Ich werde am Tag, an dem die Testung erfolgt Kontakt zu einer Person haben, die das 60. Lebensjahr vollendet hat.'
    },
    {
      id: 2,
      label: 'Ich werde am Tag, an dem die Testung erfolgt zu einer Person Kontakt haben, die ein hohes Risiko hat, schwer zu erkranken.'
    },
    {
      id: 3,
      label: 'Aktuell wird in meiner Corona-Warn-App der Status "erhöhtes Risiko" angezeigt.'
    },
  ];

  this.testReasonsPCR = [
    {
      id: 1,
      label: 'Kontaktperson oder Corona-Warn-App (§ 2 TestV)'
    },
    {
      id: 3,
      label: 'Ausbruchsgeschehen (§ 3 TestV)'
    },
    {
      id: 2,
      label: 'Bürgertestung (§ 4a TestV)'
    },
    {
      id: 4,
      label: 'Verhütung der Verbreitung (§ 4 Abs. 1 Nr. 1 und 2 TestV)'
    },
    {
      id: 8,
      label: 'bestätigende Testung (§ 4b  Satz 1 und 2 TestV)'
    },
    {
      id: 9,
      label: 'variantenspezifische Testung (§ 4b  Satz 3)'
    }
  ];
  this.stayReasons = [
    {
      id: 0,
      label: 'keine Angabe'
    },
    {
      id: 1,
      label: 'betreut/untergebracht'
    },
    {
      id: 2,
      label: 'Tätigkeit in Einrichtung'
    }
  ];
  this.facilityTypes = [
    {
      id: 0,
      label: 'keine Angabe'
    },
    {
      id: 1,
      label: 'Medizinische Einrichtung'
    },
    {
      id: 2,
      label: 'Gemeinschaftseinrichtung (z.B. Kitas, Schulen)'
    },
    {
      id: 3,
      label: 'Pflege- und andere Wohneinrichtung'
    },
    {
      id: 4,
      label: 'Sonstige Einrichtungen'
    }
  ];
  this.testMethods = {
    nasopharyngealabstrich: 'Nasopharyngealabstrich',
    rachenabstrich: 'Rachenabstrich',
    nasenabstrich: 'Nasenabstrich',
    speichel: 'Speichel'
  };
  this.testReason = _.get(this.test, 'meta.testReason');
  this.testReason2 = _.get(this.test, 'meta.testReason2');
  this.stayReason = _.get(this.test, 'meta.stayReason');
  this.facilityType = _.get(this.test, 'meta.facilityType');
  this.chargennummer = _.get(this.test, 'meta.chargennummer', '');
  this.testId = _.get(this.test, 'meta.testId', '');
  this.ct = _.get(this.test, 'meta.ct', '');

  if (this.testType === 'pcr') {
    this.pcrTests = [];
    [
      'nasopharyngealabstrich',
      'rachenabstrich',
      'nasenabstrich'
    ].forEach((sampling) => {
      this.pcrTests.push({
        manufacturer: _.get(this.office, 'settings.covidTest.pcrManufactor', ''),
        name: _.get(this.office, 'settings.covidTest.pcrName', ''),
        sampling
      });
    });

    if (this.test.meta && this.test.meta.test) {
      this.selectedTest = this.pcrTests.find((pcrTest) => {
        return pcrTest.manufacturer === this.test.meta.test.manufacturer &&
          pcrTest.name === this.test.meta.test.name &&
          pcrTest.sampling === this.test.meta.test.sampling;
      }) || this.pcrTests[0];
    } else {
      this.selectedTest = this.pcrTests[0];
    }
  } else {
    if (this.test.meta && this.test.meta.test) {
      this.selectedTest = covidTests.find((pcrTest) => {
        return pcrTest.manufacturer === this.test.meta.test.manufacturer &&
          pcrTest.name === this.test.meta.test.name &&
          pcrTest.sampling === this.test.meta.test.sampling &&
          pcrTest.testId === this.test.meta.test.testId;
      }) || (covidTests && covidTests.length ? covidTests[0] : null);
    } else {
      this.selectedTest = covidTests && covidTests.length ? covidTests[0] : null;
    }
  }

  if (this.test.status === 'inProgress') {
    this.startTimer();
  } else {
    this.stopTimer();
  }
};

TestLayoverCtrl.prototype.close = function () {
  this._modalInstance.close();
};

TestLayoverCtrl.prototype.startTest = function () {
  if (this.test.appointmentId) {
    this._TestDA
      .start(this.test.appointmentId)
      .then(() => {

        if (this.office.settings.covidTest.printLabelWithTestStart) {
          window.open(`/api/v2/appointment/${this.test.appointmentId}/qrcode/print`);
        }

        this._Notification.addSuccessMessage('Test wurde gestartet');
        this._TestDA
          .getTest(this.test.appointmentId)
          .then((test) => {
            this.test = test;
            this.startTimer();
          });
      });
  }
};

TestLayoverCtrl.prototype.finishLaborTest = function () {
  if (this.test.appointmentId) {
    this._TestDA
      .finishLaborTest(this.test.appointmentId)
      .then(() => {
        this._Notification.addSuccessMessage('Test wurde abgeschlossen');
        this._TestDA
          .getTest(this.test.appointmentId)
          .then((test) => {
            this.test = test;
          });
      });
  }
};

TestLayoverCtrl.prototype.stopTest = function () {
  if (this.test.appointmentId) {
    this._TestDA
      .stop(this.test.appointmentId)
      .then(() => {
        this._Notification.addSuccessMessage('Test wurde gestoppt');
        this._TestDA
          .getTest(this.test.appointmentId)
          .then((test) => {
            this.test = test;
            this.stopTimer();
          });
      });
  }
};

TestLayoverCtrl.prototype.correctResult = function () {
  if (this.test.appointmentId) {
    this._TestDA
      .correctResult(this.test.appointmentId)
      .then(() => {
        this._Notification.addSuccessMessage('Ergebnis wurde zurückgesetzt');
        this._TestDA
          .getTest(this.test.appointmentId)
          .then((test) => {
            this.test = test;
            this.stopTimer();
          });
      });
  }
};

TestLayoverCtrl.prototype.stopTimer = function () {
  if (this.timer) {
    this._timeout.cancel(this.timer);
    this.timer = undefined;
  }
};

TestLayoverCtrl.prototype.startTimer = function () {
  this.timer = this._timeout(function () {
    this.checkTimeLeft();
  }.bind(this), 1000);
};

TestLayoverCtrl.prototype.checkTimeLeft = function () {
  const now = moment().subtract(this.timeOffset, 'ms');

  const testStarted = moment(this.test.testStarted);
  const diff = now.diff(testStarted);
  const isOver = diff > 900000;
  this.test.timeLeft = moment.utc(diff).format('HH:mm:ss');
  this.test.isOver = isOver;

  this.timer = this.timer = this._timeout(function () {
    this.checkTimeLeft();
  }.bind(this), 1000);
};

TestLayoverCtrl.prototype.getErrorTranslation = function (errorKey) {
  const customer = this.customerLabel;
  const office = this.gettextCatalog.getString('Office');
  const errorTranslations = {
    'customer.email': `${this.gettextCatalog.getString('E-Mail')} (${customer})`,
    'customer.firstname': `${this.gettextCatalog.getString('Firstname')} (${customer})`,
    'customer.lastname': `${this.gettextCatalog.getString('Lastname')} (${customer})`,
    'customer.street': `${this.gettextCatalog.getString('Street')} (${customer})`,
    'customer.postal': `${this.gettextCatalog.getString('Postal')} (${customer})`,
    'customer.city': `${this.gettextCatalog.getString('City')} (${customer})`,
    'customer.birthday': `${this.gettextCatalog.getString('Birthday')} (${customer})`,
    'office.name': `${this.gettextCatalog.getString('Company name')} (${office})`,
    'office.address.street': `${this.gettextCatalog.getString('Street')} (${office})`,
    'office.address.postal': `${this.gettextCatalog.getString('Postal')} (${office})`,
    'office.address.city.name': `${this.gettextCatalog.getString('City')} (${office})`,
    'office.profile.contacts.email': `${this.gettextCatalog.getString('E-Mail')} (${office})`,
    'office.profile.contacts.phone': `${this.gettextCatalog.getString('Telephone')} (${office})`
  };

  return errorTranslations[errorKey] ? errorTranslations[errorKey] : errorKey;
};

TestLayoverCtrl.prototype.generateDocument = function (open) {
  this.submitted = true;

  if (this.form.$valid) {
    this.isDocumentLoading = true;
    this.isDocumentError = false;
    this.documentInFuture = false;
    this.missingDocumentParams = [];
    const meta = {
      testReason: this.testReason,
      stayReason: this.stayReason,
      facilityType: this.facilityType,
      chargennummer: this.chargennummer,
      testId: this.testId,
      ct: this.ct
    };

    if (this.testReason === 18) {
      meta.testReason2 = this.testReason2;
    }

    this._DocumentDA
      .create(
        this.test.appointmentId,
        this.sendDocumentToCustomer,
        this.resultObject.covidResult === 'negative',
        this.sendDocumentToHealthDepartment,
        this.selectedTest,
        this.checks,
        this.resultObject.selfPay,
        true,
        this.initEmployee !== this.test.employee._id ? this.test.employee._id : '',
        this.testType,
        meta
      )
      .then((response) => {
        this.isDocumentLoading = false;
        this.resultObject.resultCreated = new Date();
        this.resultObject.document = response._id;
        if (this.sendDocumentToCustomer || this.sendDocumentToHealthDepartment) {
          this._Notification.addSuccessMessage(this.gettextCatalog.getString('Document created and send via E-Mail.'));
          this.resultObject.resultSent = new Date();
          if (open) {
            window.open(`/api/v2/document/${response._id}?original=true`);
          }
        } else {
          if (open) {
            window.open(`/api/v2/document/${response._id}?original=true`);
          }
          this._Notification.addSuccessMessage(this.gettextCatalog.getString('Document created and saved in customer profile.'));
        }

        this._TestDA
          .getTest(this.test.appointmentId)
          .then((test) => {
            this.test = test;
            this.startTimer();
          });
      })
      .catch((error) => {
        this.missingDocumentParams = _.get(error, 'data.error.missingParams', []);
        this.isDocumentLoading = false;
        this.isDocumentError = true;
        this._Notification.addErrorMessage(this.gettextCatalog.getString('An Error occured!'));
      });
  }
};

TestLayoverCtrl.prototype.openDSGVO = function () {
  const pcr = this.testType === 'quickTest' ? 0 : 1;
  window.open(`/api/v2/document/create/covid/dsgvo?customerId=${this.test.customer._id}&pcr=${pcr}`);
};


TestLayoverCtrl.prototype.openTestInfo = function () {
  window.open(`/api/v2/document/create/covid/testInfo?testId=${this.test.appointmentId}`);
};

TestLayoverCtrl.prototype.openResult2go = function () {
  window.open(`/api/v2/document/create/covid/result2go?testId=${this.test.appointmentId}`);
};

TestLayoverCtrl.prototype.openSelfDisclosure = function () {
  window.open(`/api/v2/document/create/covid/selfDisclosure?testId=${this.test.appointmentId}`);
};

TestLayoverCtrl.prototype.save = function () {
  this.submitted = true;
  if (this.form.$valid) {
    const update = {
      _id: this.test.appointmentId,
      employee: this.test.employee._id,
      object: {
        _id: this.test.employee._id
      },
      meta: {
        covidResult: this.resultObject.covidResult,
        selfPay: this.resultObject.selfPay,
        test: this.selectedTest,
        testReason: this.testReason,
        stayReason: this.stayReason,
        facilityType: this.facilityType,
        chargennummer: this.chargennummer,
        testId: this.testId,
        ct: this.ct,
        testType: this.testType
      },
      checks: this.checks,
      note: this.test.note
    };

    if (this.test.testStarted) {
      update.meta.testStarted = new Date(this.test.testStarted);
    }

    if (this.testReason === 18) {
      update.meta.testReason2 = this.testReason2;
    }

    if (this.test.service) {
      if (this.testReason === 18) {
        const service = this.test.service;
        service.price = '3';
        update.price = '3';
        update.services = [this.test.service];
      } else if (this.testReason === 99) {
        const service = this.test.service;
        service.price = this.office.settings.covidTest.prices.rapid;
        update.price = this.office.settings.covidTest.prices.rapid;
        update.services = [this.test.service];
      } else {
        const service = this.test.service;
        service.price = '0';
        update.price = '0';
        update.services = [this.test.service];
      }
    }

    this._AppointmentDA
      .update(update)
      .then(() => {
        this.close();
        this._Notification.addSuccessMessage(this.gettextCatalog.getString('Test wurde aktualisiert'));
      })
      .catch(() => {
        this._Notification.addSuccessMessage(this.gettextCatalog.getString('Der Test konnte nicht aktualisiert werden.'));
      });
  }
};

TestLayoverCtrl.prototype.getString = function (key, value) {
  const item = this[key].find(i => i.id === value);

  return item ? item.label : '';
};

module.exports = TestLayoverCtrl;
