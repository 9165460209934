'use strict';

var CashEntryModalCtrl = function (Notification,
                                   AccountingDA,
                                   gettextCatalog,
                                   type,
                                   date,
                                   office,
                                   modalInstance) {

    this._modalInstance = modalInstance;
    this._AccountingDA = AccountingDA;
    this._Notification = Notification;
    this.model = {
        type: type,
        cashed: date ? date : Date.now()
    };
    this.gettextCatalog = gettextCatalog;
    this.type = type;
    this.office = office;
    this.dialogTitle = gettextCatalog.getString('CASH_ENTRY_' + type);
    this.formSubmitted = false;

    console.log(date);
};

CashEntryModalCtrl.prototype.close = function () {
    this._modalInstance.close();
};

CashEntryModalCtrl.prototype.save = function () {
    var self = this;

    this.formSubmitted = true;

    if (this.type === 'withdrawal') {
        this.model.type = 'cashOut';
    } else {
        this.model.type = 'cashUp';
    }

    if (this.form.$valid) {
        this._AccountingDA
            .createCashBookEntry(this.model)
            .then(function () {
                self.close();
                self._Notification.addSuccessMessage(self.gettextCatalog.getString('Cashbook Entry created'));
            })
            .catch(function (error) {
                self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant create Cashbook Entry (Error: {{error}})', {error: error}));
            });
    }
};

module.exports = CashEntryModalCtrl;