var angularModule = angular.module('t2g.common.data-access.industry', []);

module.exports = angularModule;

angularModule.factory('IndustryResource', function ($resource, settings) {
    var resourceUrl = settings.apiBase + '/api/v2/industry';
    
    var resource = $resource(resourceUrl, {}, {
        list: {method: 'GET', isArray: false}
    });
    
    return resource;
});

angularModule.factory('IndustryDA', function ($q, IndustryResource) {
    var industries;
    
    return {
        list: function () {
            if (industries) {
                return $q.when(industries);
            } else {
                return IndustryResource
                    .list()
                    .$promise
                    .then(function (response) {
                        industries = response.industries;
                        return industries;
                    });
            }
        }
    };
});

