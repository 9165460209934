'use strict';

var angularModule = angular.module('t2g.common.directives.trackErrors', []);

module.exports = angularModule;

angularModule.directive('trackErrors', function ($window) {
    return {
        restrict: 'E',
        link: function () {

            $window.addEventListener('error', function (error) {

                var ie = window.event || {};
                var errMsg = error.message || ie.errorMessage;
                var errSrc = (error.filename || ie.errorUrl) + ': ' + (error.lineno || ie.errorLine);

                if ($window.ga) {
                    ga('send', 'exception', {
                        'exDescription': errSrc + ': ' + errMsg,
                        'exFatal': false
                    });
                }

            }, false);

        }
    };
});
