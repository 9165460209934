module.exports = function ($scope,
                           gettextCatalog,
                           CourseModal,
                           OfficeDA,
                           Notification) {
    var newCourse = false;
    $scope.showValidation = false;

    this.setCurrentItem = function (course) {
        OfficeDA
            .get()
            .then(function (office) {
                $scope.office = office;
            });

        if (!course) {
            newCourse = true;

            course = {
                name: '',
                duration: 60,
                offers: [
                    {
                        name: gettextCatalog.getString('Price for single course'),
                        price: 0
                    }
                ]
            };

            $scope.currentCourse = course;
            $scope.backupItem = angular.copy($scope.currentCourse);
        } else {
            $scope.currentCourse = course;
        }
    };

    $scope.setCurrentItem = this.backupCurrentItem;


    /**
     * save course to database
     */
    $scope.save = function () {
        $scope.showValidation = true;
        if (newCourse) {
            if (!$scope.office.courses) {
                $scope.office.courses = [];
            }

            $scope.office.courses.push($scope.currentCourse);
        }

        if ($scope.CourseOffersForm.$valid && $scope.CourseCommonForm.$valid) {
            OfficeDA
              .update($scope.office)
              .then(() => {
                  Notification.addSuccessMessage(gettextCatalog.getString('Course saved'));
                  CourseModal.close();
              })
              .catch((error) => {
                  Notification.addErrorMessage(gettextCatalog.getString('Cant save course'));
              });
        }
    };


    /**
     * delete course from database
     */
    $scope.delete = function () {
        var index = -1;

        for (var i = 0; i < $scope.office.courses.length; i++) {
            if (angular.equals($scope.office.courses[i], $scope.currentCourse)) {
                index = i;
                break;
            }
        }


        if (index > -1) {
            $scope.office.courses.splice(index, 1);

            OfficeDA
              .update($scope.office)
              .then(() => {
                  Notification.addSuccessMessage(gettextCatalog.getString('Course removed'));
                  CourseModal.close();
              })
              .catch((error) => {
                    Notification.addErrorMessage(gettextCatalog.getString('Cant remove course'));
              });
        } else {
            Notification.addErrorMessage(gettextCatalog.getString({text: 'Unknown'}));
        }
    };


    $scope.addOffer = function () {
        if (!$scope.currentCourse.offers || !angular.isArray($scope.currentCourse.offers)) {
            $scope.currentCourse.offers = [];
        }

        $scope.currentCourse.offers.push({
            name: '',
            price: 0
        });
    };


    $scope.removeOffer = function (index) {
        $scope.currentCourse.offers.splice(index, 1);
    };

    /**
     * close course modal
     */
    $scope.abort = function () {
        for (var prob in $scope.backupItem) {
            if ($scope.currentCourse.hasOwnProperty(prob)) { // check if property exists in master data
                $scope.currentCourse[prob] = $scope.backupItem[prob];
            }
        }

        CourseModal.close();
    };

};




