'use strict';

var angularModule = angular.module('t2g.common.data-access.office', [
  'ngResource',
  require('t2g.common.services.cacheService').name,
  require('t2g.common.services.sessionStorage').name,
  require('t2g.common.data-access.user').name
]);

module.exports = angularModule;

angularModule.factory('OfficeResource', function ($resource, settings) {
    var resourceUrl = settings.apiBase + '/api/v2/office/:subResource1/:subResource2';

    var resource = $resource(resourceUrl, {}, {
      get: { method: 'GET' },
      uploadLogo: {method: 'POST', params: {subResource1: 'logo'}},
      deleteLogo: {method: 'DELETE', params: {subResource1: 'logo'}},
      uploadImage: {method: 'DELETE', params: {subResource1: 'image'}},
      deleteImage: {method: 'DELETE', params: {subResource1: 'image'}},
      patch: {method: 'PATCH'},
      update: {method: 'PUT'},
    });

    return resource;
  }
);

angularModule.factory('OfficeDA', function ($q,
                                            $rootScope,
                                            gettextCatalog,
                                            OfficeResource,
                                            OfficeCache,
                                            Upload,
                                            UserSession) {

  return {
    get: get,
    update,
    patch,
    uploadLogo,
    deleteLogo,
    uploadImage,
    deleteImage
  };

  function patch(officePatch) {
    return OfficeResource
      .patch(officePatch)
      .$promise
      .then((response) => {
        OfficeCache.put('office', response.office);
        return response.office;
      });
  }

  function uploadLogo(file) {
    return Upload
      .upload({
        url: '/api/v2/office/logo',
        data: {},
        file
      })
      .then((response) => {
        OfficeCache.put('office', response.data.office);
        return response.data.office;
      });
  }

  function deleteLogo() {
    return OfficeResource
      .deleteLogo()
      .$promise
      .then((response) => {
        OfficeCache.put('office', response.office);
        return response.office;
      });
  }

  function uploadImage(file) {
    return Upload
      .upload({
        url: '/api/v2/office/image',
        data: {},
        file
      })
      .then((response) => {
        OfficeCache.put('office', response.data.office);
        return response.data.office;
      });
  }

  function deleteImage(id) {
    return OfficeResource
      .deleteImage({subResource2: id})
      .$promise
      .then((response) => {
        OfficeCache.put('office', response.office);
        return response.office;
      });
  }

  function get() {
    var cachedOffice = OfficeCache.get('office');

    if (cachedOffice === undefined) {
      return OfficeResource
        .get()
        .$promise
        .then(function (response) {
          var userSession = UserSession.get();

          OfficeCache.put('office', response);

          if (response.billing) {
            userSession.account = response.billing.account;
          }

          UserSession.set(userSession);

          return response;
        })
        .catch(function (error) {
          return $q.reject('cant load office data: ' + error);
        });
    } else {
      return $q.when(cachedOffice);
    }
  }

  function update(office) {
    return OfficeResource
      .update(office)
      .$promise
      .then(function (response) {
        OfficeCache.remove('office');
        OfficeCache.put('office', response.office);
        $rootScope.$broadcast('Office:update', response.office);

        return response.office;
      })
      .catch(function (error) {
        return $q.reject('cant update office data: ' + error);
      });
  }
});
