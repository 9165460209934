'use strict';

module.exports = function($scope,
                          gettextCatalog,
                          Notification,
                          OfficeDA,
                          officeResponse) {

    $scope.weekDays = [
        {name: gettextCatalog.getString('Monday')},
        {name: gettextCatalog.getString('Tuesday')},
        {name: gettextCatalog.getString('Wednesday')},
        {name: gettextCatalog.getString('Thursday')},
        {name: gettextCatalog.getString('Friday')},
        {name: gettextCatalog.getString('Saturday')},
        {name: gettextCatalog.getString('Sunday')}
    ];

    $scope.showValidation = false;
    $scope.office = officeResponse;
    $scope.openingHours = $scope.office.openingHours;

    $scope.$watch('openingHours', function(){
        console.log('changed');
    }, true);


    /**
     * save to rest webservice
     */
    $scope.save = function() {
        $scope.showValidation = true;

        if ($scope.openingHoursForm.$valid) {
          OfficeDA
            .patch({openingHours: {
                regular: $scope.openingHours.regular
              }})
            .then(() => {
                Notification.addSuccessMessage(gettextCatalog.getString('Opening hours updated'));
            })
            .catch((error) => {
                Notification.addErrorMessage({text: error});
            });
        }
    };


    /**
     * add a second column to enable two time slots per day ...
     * @param day
     */
    $scope.addSecondOpeningTime = function(day) {
        // get closing hour of first opening time
        var firstClosingTime = $scope.openingHours.regular[day].times[0].end;

        $scope.openingHours.regular[day].times.push({
            start: ((firstClosingTime + 60) > 1440) ? 1440 : firstClosingTime + 60,
            end: ((firstClosingTime + 240) > 1440) ? 1440 : firstClosingTime + 240
        });
    };


    /**
     * ... end make it removeable
     * @param day
     */
    $scope.removeSecondOpeningTime = function(day) {
        $scope.openingHours.regular[day].times.splice(1, 1);
    };

  $scope.runTour = function (standalone) {
    if (typeof introJs === 'undefined') return;

    const intro = introJs();
    let steps = [
      {
        intro: gettextCatalog.getString('Setup your opening hours on this site (Master data > Opening Hours). Beware: For calculation of bookable time slots the system uses the resources availability and not the opening hours.')
      },
      {
        element: document.querySelectorAll('.checkbox-open-closed')[ 0 ],
        intro: gettextCatalog.getString('Activate the checkbox if your business is open on this day of week.'),
        position: 'bottom'
      },
      {
        element: document.querySelectorAll('.button-add-afternoon-times')[ 0 ],
        intro: gettextCatalog.getString('If you have closed during lunch time add opening hours for the afternoon with a click on the plus icon.'),
        position: 'bottom'
      },
      {
        element: document.querySelectorAll('.button-remove-afternoon-times  ')[ 0 ],
        intro: gettextCatalog.getString('Click on the minus icon if you want to remove the additional afternoon opening hours.'),
        position: 'bottom'
      }
    ];

    if (standalone) {
      console.log('last step without redirect');
    }

    intro.setOptions({
      nextLabel: this._gettextCatalog.getString('next'),
      prevLabel: this._gettextCatalog.getString('back'),
      skipLabel: this._gettextCatalog.getString('skip'),
      doneLabel: this._gettextCatalog.getString('done'),
      exitOnOverlayClick: false,
      exitOnEsc: false,
      steps
    });

    intro.start();
  };
};
