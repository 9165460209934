'use strict';

var angularModule = angular.module('t2g.common.filter.momentFilter', []);

module.exports = angularModule;

angularModule.filter('moment', function() {
    return function(date, format) {
        return moment(date).format(format);
    };
});

angularModule.filter('momentCalendar', function() {
    return function(date) {
        return moment(date).calendar();
    };
});

