'use strict';

var angularModule = angular.module('t2g.common.data-access.appointment', []);

module.exports = angularModule;

angularModule.factory('AppointmentResource', function ($resource,
                                                       settings) {

  var resourceUrl = settings.apiBase + '/api/v2/appointment/:appointmentId/:subResource1/:subResource2';

  return $resource(resourceUrl, {}, {
    get: { method: 'get' },
    getCashBookEntry: { method: 'get', params: { subResource1: 'cashBookEntry' } },
    insert: { method: 'post' },
    remove: { method: 'delete' },
    update: { method: 'put', params: { appointmentId: '@_id' } },
    addParticipant: { method: 'post', params: { subResource1: 'participants' } },
    removeParticipant: { method: 'delete', params: { subResource1: 'participants' } }
  });
});

angularModule.factory('AppointmentDA', function ($q,
                                                 AppointmentResource,
                                                 CalendarDA,
                                                 UserSession,
                                                 OfficeDA,
                                                 gettextCatalog,
                                                 ModalService,
                                                 Notification) {

  return {
    addParticipant,
    removeParticipant,
    get: get,
    insert,
    remove,
    update,
    transformResponse,
    getCashBookEntry
  };

  function getCashBookEntry(appointmentId) {
    return AppointmentResource
      .getCashBookEntry({ appointmentId: appointmentId })
      .$promise
      .then(function (response) {
        return response.cashBookEntry;
      });
  }


  /**
   * parse appointment start and end als date times
   * @param {Object} response
   * @returns {Object}
   */
  function transformResponse(response) {
    var appointments = response.appointments || response.appointment || response;

    _.map(appointments, function (appointment) {
      appointment.start = new Date(appointment.start);
      appointment.end = new Date(appointment.end);
      appointment.preparationStart = appointment.preparationStart ? new Date(appointment.preparationStart) : appointment.start;
      appointment.followUpEnd = appointment.followUpEnd ? new Date(appointment.followUpEnd) : appointment.end;
      return appointment;
    });

    return appointments;
  }


  /**
   * add a participant to a course appointment
   *
   * @param {String} appointmentId
   * @param {String} customerId
   * @returns {promise}
   */
  function addParticipant(appointmentId, customerId) {
    return AppointmentResource
      .addParticipant({ appointmentId: appointmentId }, { participant: customerId })
      .$promise
      .then(transformResponse)
      .then(function (updatedAppointment) {
        Notification.addSuccessMessage(gettextCatalog.getString('Appointment updated'));
        return CalendarDA.editAppointment(updatedAppointment);
      })
      .catch(function () {
        Notification.addErrorMessage(gettextCatalog.getString('Cant update appointment'));
      });
  }


  /**
   * remove a participant to a course appointment
   *
   * @param {String} appointmentId
   * @param {String} customerId
   * @returns {promise}
   */
  function removeParticipant(appointmentId, customerId) {
    return AppointmentResource
      .removeParticipant({ appointmentId: appointmentId, subResource2: customerId })
      .$promise
      .then(transformResponse)
      .then(function (updatedAppointment) {
        Notification.addSuccessMessage(gettextCatalog.getString('Appointment updated'));
        return CalendarDA.editAppointment(updatedAppointment);
      })
      .catch(function () {
        Notification.addErrorMessage(gettextCatalog.getString('Cant update appointment'));
      });
  }


  /**
   * get an appointment
   * @param {String} appointmentId
   * @returns {Object}
   */
  function get(appointmentId, type, migrated = false) {
    return AppointmentResource
      .get({ appointmentId: appointmentId })
      .$promise
      .then(function (response) {
        var appointment = response.appointment;
        appointment.start = new Date(appointment.start);
        appointment.end = new Date(appointment.end);
        appointment.preparationStart = new Date(appointment.preparationStart);
        appointment.followUpEnd = new Date(appointment.followUpEnd);

        if (migrated) {
          appointment.migrated = migrated;
        }

        if (type) {
          appointment.type = type;
        }

        return appointment;
      });
  }


  /**
   * save appointment
   * @param appointment
   * @returns {Object}
   */
  function insert(appointment) {
    return ModalService
      .openPINModal()
      .then((data) => {
        if (data === 'abort') {
          return $q.reject('pin_aborted');
        } else {
          return AppointmentResource
            .insert(appointment)
            .$promise
            .then(transformResponse)
            .then(function (insertedAppointment) {
              Notification.addSuccessMessage(gettextCatalog.getString('Appointment saved'));
              return CalendarDA.insertAppointment(insertedAppointment);
            })
            .then(function (insertedAppointments) {
              var firstAppointment = insertedAppointments[0];

              if (firstAppointment && firstAppointment.customerNotified) {
                Notification.addSuccessMessage(gettextCatalog.getString('Notification email send to {{customerEmail}}', { customerEmail: firstAppointment.customer.email }));
              }

              return insertedAppointments;
            })
            .catch(function (error) {
              Notification.addErrorMessage(gettextCatalog.getString('Cant save appointment'));
            });
        }
      });
  }


  /**
   * delete an appointment
   *
   * @param {String} appointmentId
   * @param {Boolean} sendNotification
   * @param {Object} appointment
   * @param {Boolean} type
   * @returns {Object}
   */
  function remove(appointmentId, sendNotification, appointment, type = 'one') {
    return ModalService
      .openPINModal()
      .then((data) => {
        if (data === 'abort') {
          return $q.reject('pin_aborted');
        } else {
          return AppointmentResource
            .remove({ appointmentId: appointmentId, sendNotification: sendNotification === true ? 'true' : false, type })
            .$promise
            .then(function () {
              Notification.addSuccessMessage(gettextCatalog.getString('Appointment deleted'));

              if (sendNotification) {
                Notification.addSuccessMessage(gettextCatalog.getString('Notification email send to {{customerEmail}}', { customerEmail: _.get(appointment, 'customer.email') }));
              }

              return CalendarDA.removeAppointment(appointmentId);
            })
            .catch(function () {
              Notification.addErrorMessage(gettextCatalog.getString('Cant delete appointment'));
            });
        }
      });
  }


  /**
   * update an appointment
   *
   * @param {Object} appointment
   * @param {Boolean} sendNotification
   * @returns {Object}
   */
  function update(appointment) {
    return ModalService
      .openPINModal()
      .then((data) => {
        if (data === 'abort') {
          return $q.reject('pin_aborted');
        } else {
          return AppointmentResource
            .update(appointment)
            .$promise
            .then(transformResponse)
            .then(function (updatedAppointments) {
              Notification.addSuccessMessage(gettextCatalog.getString('Appointment updated'));

              if (updatedAppointments && updatedAppointments.length && updatedAppointments[0].notifiedCustomer) {
                Notification.addSuccessMessage(gettextCatalog.getString('Notification email send to {{customerEmail}}', { customerEmail: updatedAppointments[0].notifiedCustomer.email }));
              }

              return CalendarDA.editAppointment(updatedAppointments);
            })
            .catch(function (error) {
              console.log(error);
              Notification.addErrorMessage(gettextCatalog.getString('Cant update appointment'));
            });
        }
      });
  }
});


