var angularModule = angular.module('t2g.common.data-access.booking', []);

module.exports = angularModule;

angularModule.factory('BookingResource',
  function ($resource,
            settings) {

    var resourceUrl = settings.apiBase + '/api/v2/booking/:id/:action';

    var resource = $resource(resourceUrl, {}, {
      accept: { method: 'POST', isArray: false, params: { action: 'accept', id: '@_id' } },
      decline: { method: 'POST', isArray: false, params: { action: 'decline', id: '@_id' } },
      get: { method: 'GET', isArray: false }
    });

    return resource;
  }
);

angularModule.factory('BookingDA', function (BookingResource,
                                             CustomerDA,
                                             UserSession,
                                             CalendarDA) {
  return {
    get,
    accept,
    decline
  };

  function get(date) {
    return BookingResource.get().$promise;
  }

  function accept(booking) {
    return BookingResource
      .accept(booking)
      .$promise
      .then((response) => {
        CalendarDA.editAppointment(response.appointments);

        response.appointments.forEach((appointment) => {
          if (appointment.customer && appointment.customer._id) {
            CustomerDA.insertIntoCache(appointment.customer);
          }
        });
      });
  }

  function decline(booking) {
    return BookingResource.decline(booking).$promise;
  }
});
