'use strict';

var angularModule = angular.module('t2g.common.data-access.statistic', []);

module.exports = angularModule;

angularModule.factory('StatisticResource', function ($resource, settings) {
    var resourceUrl = settings.apiBase + '/api/v2/statistic/:kpi';

    var resource = $resource(resourceUrl, {}, {});

    return resource;
});

angularModule.factory('StatisticDA', function (StatisticResource) {
    return {
        getTests,
        getSales,
        getSalesPerEmployee,
        getSalesPerService
    };

    function getStatistic(type, start, end, resolution){
        return StatisticResource.get({kpi: type, start: start, end: end, resolution: resolution}).$promise;
    }

    function getTests(date, resolution, testType) {
        return StatisticResource.get({kpi: 'tests', date, resolution, testType}).$promise;
    }

    function getSales(start, end, resolution) {
        return getStatistic('sales', start, end, resolution);
    }

    function getSalesPerEmployee(start, end, resolution) {
        return getStatistic('salesPerEmployee', start, end, resolution);
    }

    function getSalesPerService(start, end, resolution) {
        return getStatistic('salesPerService', start, end, resolution);
    }
});

