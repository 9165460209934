module.exports = function (AppointmentDA,
                           CalendarDA,
                           MobileDetectService,
                           PixelTimeService) {
  'use strict';

  return {
    restrict: 'E',
    template: '<div class="calendar__appointment-column">' +
      '<appointment data-ng-repeat="appointment in resource.appointments"></appointment>' +
      '<absent-times working-hours="resource.workingHours"></absent-times>' +
      '</div>',
    replace: true,
    controller: 'AppointmentsCtrl as AppointmentsCtrl',
    link: function ($scope, $element, $attributes) {
      var date = $attributes.date;
      var resource = $scope.resource;

      if (!MobileDetectService.isMobile) {
        $element.droppable({
          drop: function (event, ui) {
            var contextElement = angular.element(ui.draggable.context);
            var contextAppointment = contextElement.length > 0 ? contextElement.scope().appointment : null;
            var top = _.get(ui, 'position.top');

            if (contextAppointment) {
              var duration = contextAppointment.end.getTime() - contextAppointment.start.getTime();
              var timeParts = PixelTimeService.pixelToTime(top, true);
              var newStart = moment(date);
              newStart.set('hour', timeParts.hours);
              newStart.set('minute', timeParts.roundedMinutes);
              contextAppointment.start = newStart.toDate();
              contextAppointment.end = moment(newStart).add(duration).toDate();

              if (resource.type === 'room') {
                contextAppointment.room = resource._id;
              } else {
                contextAppointment.employee = resource._id;
                contextAppointment.object = contextAppointment.object || {};
                contextAppointment.object._id = resource._id;
              }

              AppointmentDA.update(contextAppointment);
            }
          }
        });
      }


    }
  };
};
