'use strict';

var stateTitleModule = angular.module('t2g.common.directives.stateTitle', []);

module.exports = stateTitleModule;

stateTitleModule.directive('stateTitle', function($transitions,
                                                  gettextCatalog,
                                                  settings) {
    return {
        restrict: 'A',
        link: function($scope, $element){

            $transitions.onSuccess({}, function(transition){
                var titleParts = [settings.titlePrefix];
                var toState = transition.to();

                if(toState.title){
                    titleParts.push(settings.titleSeperator);
                    titleParts.push(gettextCatalog.getString(toState.title));
                }
                $element.html(titleParts.join(' '));
            });

        }
    };
});
