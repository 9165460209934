'use strict';

var CustomFieldsModalCtrl = function(Notification,
                                     customField,
                                     gettextCatalog,
                                     modalInstance) {

  this._modalInstance = modalInstance;
  this._Notification = Notification;
  this.model = customField.field;
  this.customField = customField;
  this.gettextCatalog = gettextCatalog;
  this.formSubmitted = false;
  this.optionTranslation_ = gettextCatalog.getString('Select');
  this.fieldTypes = [
    {
      name: gettextCatalog.getString('Textfield'),
      type: 'textfield'
    },
    {
      name: gettextCatalog.getString('Textarea'),
      type: 'textarea'
    },
    {
      name: gettextCatalog.getString('Select'),
      type: 'select'
    },
    {
      name: gettextCatalog.getString('Radio'),
      type: 'radio'
    }
  ];
};

/**
 * fired if field type selectbox has changed
 */
CustomFieldsModalCtrl.prototype.changeTypeHandler = function() {
  var multiOptionFields = {
    radio: true,
    select: true
  };

  if (multiOptionFields[this.model.type]) {
    this.model.options = this.model.options || [];

    if (this.model.options.length === 0) {
      this.model.options.push({
        label: this.optionTranslation_ + ' 1'
      });

      this.model.options.push({
        label: this.optionTranslation_ + ' 2'
      });
    }

  } else {
    this.model.options = null;
  }
};


/**
 * @param {object} option
 */
CustomFieldsModalCtrl.prototype.removeOption = function(option) {
  var index = this.model.options.indexOf(option);

  if (this.model.options.length > 2) {
    this.model.options.splice(index, 1);
  }
};


/**
 * add an option to radio option list
 */
CustomFieldsModalCtrl.prototype.addOption = function() {
  this.model.options.push({
    label: this.optionTranslation_
  });
};

CustomFieldsModalCtrl.prototype.close = function() {
  this._modalInstance.close({
    type: 'close',
    data: this.customField
  });
};

CustomFieldsModalCtrl.prototype.save = function() {
  this._modalInstance.close({
    type: 'save',
    data: this.customField
  });
};

CustomFieldsModalCtrl.prototype.delete = function() {
  this._modalInstance.close({
    type: 'delete',
    data: this.customField
  });
};

module.exports = CustomFieldsModalCtrl;
