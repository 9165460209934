'use strict';

var settingsModule = angular.module('t2g.settings', [
  'gettext',
  'ui.router',
  require('t2g.common.services.industrySpecificService').name,
  require('t2g.common.services.notificationService').name
]);

module.exports = settingsModule;

settingsModule.controller('SettingsCtrl', require('./settingsCtrl'));

settingsModule.config(function ($stateProvider) {
  $stateProvider.state('private.main.settings', {
    url: '/settings/?:openTab',
    params: {
      openTab: {
        dynamic: true
      }
    },
    title: 'Settings',
    templateUrl: 'https://cdn.termin2go.com/app/app/modules/settings/SettingsView.html?v=3.82.3',
    controller: 'SettingsCtrl',
    resolve: {
      officeResolve(OfficeDA) {
        return OfficeDA.get();
      },
      employees(ResourceDA) {
        return ResourceDA
          .list('employee')
          .then(resources => _.filter(resources.resources, { type: 'employee' }));
      },
      covidTests(CovidTestDA) {
        return CovidTestDA.list();
      },
      userResolve: function (UserDA) {
        return UserDA
          .list()
          .catch(() => {
            return [];
          })
      }
    }
  });
});
