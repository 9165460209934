'use strict';

var VatIDModalCtrl = function(modalInstance) {

  this._modalInstance = modalInstance;
};

VatIDModalCtrl.prototype.close = function() {
  this._modalInstance.close();
};

module.exports = VatIDModalCtrl;
