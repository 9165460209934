'use strict';

var InvoiceListCtrl = function($window, invoices, SubscriptionDA, Notification, gettextCatalog) {
    this.invoices = invoices;
    this._SubscriptionDA = SubscriptionDA;
    this._window = $window;
    this._Notification = Notification;
    this.gettextCatalog = gettextCatalog;
};

InvoiceListCtrl.prototype.loadInvoice = function(invoice) {
    var self = this;
    
    this._SubscriptionDA
      .getInvoiceLink(invoice.id)
      .then(function(invoiceLink) {
          self._window.open(invoiceLink);
      })
      .catch(function(error) {
          self
            ._Notification
            .addErrorMessage(self.gettextCatalog.getString('Cant load invoice ({{error}})', {error: error}));
      });
};

module.exports = InvoiceListCtrl;
