'use strict';

var angularModule = angular.module('t2g.common.filter.regExFilter', []);

module.exports = angularModule;

angularModule.filter('regExFilter', function() {
  return function(items, field, filterString) {
    filterString = filterString.toLowerCase();

    const filtered = items.filter((item) => {
        return item[field].toLowerCase().indexOf(filterString) > -1;
    });

    return filtered;
  };
});