'use strict';

var angularModule = angular.module('t2g.common.services.billwerkService', []);

module.exports = angularModule;


angularModule.factory('BillwerkService', function ($q,
                                                   settings,
                                                   SubscriptionDA) {
    
    return {
        load: load,
        subscribe: subscribe,
        createOrder: createOrder,
        createPayment: createPayment,
        changeBillingInformation: changeBillingInformation,
        changePaymentInformation: changePaymentInformation,
        finalize: finalize
    };
    
    function _getPortalService() {
        return SubscriptionDA
            .getCustomerToken()
            .then(function (customerToken) {
                var portalService = new BillwerkJS.Portal(customerToken);
                return portalService;
            });
    }
    
    /**
     * get a payment service instance
     *
     * @return {BillwerkJS.Payment}
     * @private
     */
    function _getPaymentService() {
        var deferred = $q.defer();
        
        var paymentService = new BillwerkJS.Payment({
                publicApiKey: settings.billwerk.key,
                providerReturnUrl: 'https://app.termin2go.com/#/private/main/subscription/finalize'
            },
            function () {
                deferred.resolve(paymentService);
            },
            function (error) {
                deferred.reject(error);
            }
        );
        
        return deferred.promise;
    }
    
    
    /**
     * get a signup service instance
     *
     * @return {BillwerkJS.Signup}
     * @private
     */
    function _getSignupService() {
        var signupService = new BillwerkJS.Signup();
        return $q.when(signupService);
    }
    
    
    /**
     * add billwerk script to buttom of body element
     */
    function load() {
        var deferred = $q.defer();
        
        if (!document.getElementById('billwerk')) {
            var billwerkScript = document.createElement('script');
            billwerkScript.type = 'text/javascript';
            billwerkScript.src = settings.billwerk.libUrl;
            billwerkScript.id = 'billwerk';
            billwerkScript.onreadystatechange = billwerkScript.onload = function () {
                deferred.resolve({});
            };
            document.body.appendChild(billwerkScript);
        } else {
            deferred.resolve({});
        }
        
        return deferred.promise;
    }
    
    
    /**
     * create an order
     *
     * @param {object} cart
     * @param {object} customer
     * @return {promise}
     */
    function createOrder(cart, customer) {
        var deferred = $q.defer();
        
        _getSignupService()
            .then(function (signupService) {
                signupService.createOrder(cart, customer,
                    function (order) {
                        deferred.resolve(order);
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );
            })
            .catch(function (error) {
                deferred.reject(error);
            });
        
        return deferred.promise;
    }
    
    
    /**
     * create a payment
     *
     * @param {object} paymentData
     * @param {object} order
     * @return {promise}
     */
    function createPayment(paymentData, order) {
        var deferred = $q.defer();
        
        $q.all([
            _getPaymentService(),
            _getSignupService()
        ]).then(function (responses) {
            var paymentService = responses[0];
            var signupService = responses[1];
            
            signupService.paySignupInteractive(paymentService, paymentData, order,
                function (result) {
                    if (!result.Url) {
                        deferred.resolve(result);
                    } else {
                        window.location.href = result.Url;
                    }
                },
                function (error) {
                    deferred.reject(error);
                }
            );
        }).catch(function (error) {
            deferred.reject(error);
        });
        
        return deferred.promise;
    }
    
    
    /**
     * change billing information of a customer
     *
     * @param {object} customer
     * @return {*}
     */
    function changeBillingInformation(customer) {
        var deferred = $q.defer();
        
        _getPortalService()
            .then(function (portalService) {
                portalService.customerChange(customer,
                    function (result) {
                        deferred.resolve(result);
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );
            })
            .catch(function (error) {
                deferred.reject(error);
            });
        
        return deferred.promise;
    }
    
    
    /**
     * change payment information of a customer
     *
     * @param {Object} paymentData
     * @return {promise}
     */
    function changePaymentInformation(paymentData) {
        var deferred = $q.defer();
        
        $q.all([
            _getPaymentService(),
            _getPortalService()
        ]).then(function (responses) {
            var paymentService = responses[0];
            var portalService = responses[1];
            
            portalService.paymentChange(paymentService, paymentData,
                function (result) {
                    if (!result.Url) {
                        deferred.resolve(result);
                    } else {
                        window.location.href = result.Url;
                    }
                },
                function (error) {
                    deferred.reject(error);
                }
            );
        }).catch(function (error) {
            deferred.reject(error);
        });
        
        return deferred.promise;
    }
    
    
    /**
     * finalize a payment
     *
     * @return {promise}
     */
    function finalize() {
        var deferred = $q.defer();
        
        BillwerkJS.finalize(
            function (result) {
                deferred.resolve(result);
            }, function (error) {
                deferred.reject(error);
            });
        
        return deferred.promise;
    }
    
    
    /**
     * subscribe process all in one
     *
     * @param {object} cart
     * @param {object} customer
     * @param {object} paymentData
     * @return {promise}
     */
    function subscribe(cart, customer, paymentData) {
        var deferred = $q.defer();
        
        $q.all([
            _getPaymentService(),
            _getSignupService()
        ]).then(function (responses) {
            var paymentService = responses[0];
            var signupService = responses[1];
            
            signupService.subscribe(paymentService, cart, customer, paymentData,
                function (result) {
                    if (!result.Url) {
                        deferred.resolve(result);
                    } else {
                        window.location.href = result.Url;
                    }
                },
                function (error) {
                    deferred.reject(error);
                }
            );
        }).catch(function (error) {
            deferred.reject(error);
        });
        
        return deferred.promise;
    }
});
