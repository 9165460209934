'use strict';

require('angular-toastr');

var angularModule = angular.module('t2g.common.services.notificationService', [
    'gettext',
    'toastr'
]);

module.exports = angularModule;

angularModule.config(function(toastrConfig) {
    angular.extend(toastrConfig, {
        autoDismiss: false,
        containerId: 'toast-container',
        maxOpened: 0,
        newestOnTop: true,
        positionClass: 'toast-bottom-left',
        target: 'body',
        closeButton: false,
        timeOut: 4000
    });
});

angularModule.factory('Notification', function(gettextCatalog,
                                               toastr) {

        return {
            addErrorMessage: function(message) {
                if (_.isObject(message)) {
                    toastr.error(gettextCatalog.getString('An unknown error occured (Error: {{error}})', {error: message.text}));
                } else {
                    toastr.error(message);
                }
            },
            addSuccessMessage: function(message) {
                toastr.success(message);
            }
        };
    }
);
