var StatisticSalesCtrl = function($scope,
                                  $state,
                                  $stateParams,
                                  Notification,
                                  salesResponse,
                                  DatepickerSettings) {
    var self = this;
    this._Notification = Notification;
    this.labels = salesResponse.date;
    this.data = salesResponse.sales;
    this.colors = [
      '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20',
      '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20',
      '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20',
      '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20', '#1b5e20',
    ];
    this.dtpOptions = DatepickerSettings.minMonth;
    this._state = $state;

    if ($stateParams.start) {
        this.currentMonth = moment($stateParams.start).toDate();
    } else {
        this.currentMonth = new Date();
    }

    this.barChartOptions = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    };


    $scope.$watch(function() {
        return self.currentMonth;
    }, function(newValue, oldValue) {
        if (newValue !== oldValue) {
            var startOfMonth = moment(self.currentMonth).startOf('month').format('YYYY-MM-DD');
            var endOfMonth = moment(self.currentMonth).endOf('month').format('YYYY-MM-DD');

            $state.go('private.main.statistic-sales', {start: startOfMonth, end: endOfMonth});
        }
    });
};

StatisticSalesCtrl.prototype.getColor = function() {
    return '#4CAF50';
};

module.exports = StatisticSalesCtrl;
