var ShiftPlanningCtrl = function ($state,
                                  employees,
                                  UserSession,
                                  gettextCatalog,
                                  moment) {

  this.employees = employees;
  this._state = $state;
  this._moment = moment;
  this.currentEmployeeId = _.get(this._state, 'params.employeeId');
  this.user = UserSession.get();
  this.showDropdown = true;
  this.gettextCatalog = gettextCatalog;

  const editRights2 = _.get(this.user, 'rights.workingHours.edit');

  if (editRights2 !== 'all') {
    this.currentEmployeeId = editRights2;
    this.employeeChanged();
    this.showDropdown = false;
  } else if (!this.currentEmployeeId && this.employees && this.employees.length) {
    this.currentEmployeeId = this.employees[0]._id;
    this.employeeChanged();
  }

};

ShiftPlanningCtrl.prototype.employeeChanged = function () {
  var date = _.get(this._state, 'params.date', this._moment().startOf('month').format('YYYY-MM-DD'));
  var openTab = _.get(this._state, 'params.openTab', 'weeklyPlanning');

  this._state.go('private.main.shiftPlanning.workingHours', {
    employeeId: this.currentEmployeeId,
    date: date,
    openTab: openTab
  });
};

ShiftPlanningCtrl.prototype.runTour = function (standalone) {
  if (typeof introJs === 'undefined') return;

  const intro = introJs();
  let steps = [
    {
      intro: this.gettextCatalog.getString('Setup your opening hours on this site (Master data > Opening Hours). Beware: For calculation of bookable time slots the system uses the resources availability and not the opening hours.')
    },
    {
      element: document.querySelectorAll('.checkbox-open-closed')[0],
      intro: this.gettextCatalog.getString('Activate the checkbox if your business is open on this day of week.'),
      position: 'bottom'
    },
    {
      element: document.querySelectorAll('.button-add-afternoon-times')[0],
      intro: this.gettextCatalog.getString('If you have closed during lunch time add opening hours for the afternoon with a click on the plus icon.'),
      position: 'bottom'
    },
    {
      element: document.querySelectorAll('.button-remove-afternoon-times  ')[0],
      intro: this.gettextCatalog.getString('Click on the minus icon if you want to remove the additional afternoon opening hours.'),
      position: 'bottom'
    }
  ];

  if (standalone) {
    console.log('last step without redirect');
  }

  intro.setOptions({
    steps
  });

  intro.start();
};

module.exports = ShiftPlanningCtrl;
