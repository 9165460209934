'use strict';

var CashBookCtrl = function ($state,
                             $scope,
                             $stateParams,
                             gettextCatalog,
                             cashBook,
                             office,
                             ModalService,
                             AccountingDA,
                             DatepickerSettings) {

  if (office.isHelloCashActivated) {
    $state.go('private.main.calendar');
    return;
  }

  var self = this;
  this.dtpOpened = false;
  this.dtpOptions = DatepickerSettings.default;
  this.date = $stateParams.date ? moment($stateParams.date).toDate() : new Date();
  this.showNotFoundError = cashBook.message === 'not_found';
  this.cashBook = cashBook;
  this.office = office;
  this._AccountingDA = AccountingDA;
  this._state = $state;
  this._stateParams = $stateParams;
  this._ModalService = ModalService;
  this.paymentMethods = {
    apple: 'Apple Pay',
    cash: gettextCatalog.getString('Cash'),
    ec: gettextCatalog.getString('EC-Card'),
    es: gettextCatalog.getString('Einzahlungsschein'),
    cc: gettextCatalog.getString('Credit Card'),
    dd: gettextCatalog.getString('Direct Debit'),
    coupon: gettextCatalog.getString('Coupon'),
    pp: 'PayPal',
    pc: gettextCatalog.getString('Post-Card'),
    invoice: gettextCatalog.getString('Invoice'),
    twint: gettextCatalog.getString('TWINT'),
  };

  $scope.paymentsMethods = [
    { value: 'apple', label: 'Apple Pay' },
    { value: 'cash', label: gettextCatalog.getString('Cash') },
    { value: 'ec', label: gettextCatalog.getString('EC-Card') },
    { value: 'es', label: gettextCatalog.getString('Einzahlungsschein') },
    { value: 'cc', label: gettextCatalog.getString('Credit Card') },
    { value: 'dd', label: gettextCatalog.getString('Direct Debit') },
    { value: 'coupon', label: gettextCatalog.getString('Coupon') },
    { value: 'pp', label: 'PayPal' },
    { value: 'pc', label: gettextCatalog.getString('Post-Card') },
    { value: 'invoice', label: gettextCatalog.getString('Invoice') },
    { value: 'twint', label: gettextCatalog.getString('TWINT') },
  ];

  if (this.cashBook && this.cashBook.success === false) {
    if (this.cashBook.error === 'future_chashbook_not_allowed') {
      this.inFuture = true;
    } else if (this.cashBook.error === 'not_found') {
      this.notFound = true;
    }
  }

  if(this.cashBook.showOpen) {
    this._ModalService.openDayModal((newCashBook) => {
      this.cashBook = newCashBook;
    });
  }

  $scope.$watch(function () {
    return self.date;
  }, function (newDate, oldDate) {
    var newDateTime = newDate && newDate.getTime ? newDate.getTime() : 0;
    var oldDateTime = oldDate && oldDate.getTime ? oldDate.getTime() : 0;

    if (newDateTime !== oldDateTime) {
      self._state.transitionTo('private.main.accountingCashBook', { date: moment(newDate).format('YYYY-MM-DD') });
    }
  });
};

CashBookCtrl.prototype.changeDate = function (days) {
  this.date = moment(this.date).add(days, 'day').toDate();
};

CashBookCtrl.prototype.addCashBookEntry = function (type) {
  this._ModalService.openCashEntryModal(type, this.date, this.reload.bind(this));
};

CashBookCtrl.prototype.openCashModal = function () {
  this._ModalService.openCashModal(null, this.reload.bind(this));
};

CashBookCtrl.prototype.openAppointment = function (appointmentId) {
  this._ModalService.openAppointmentModal(
    {  _id: appointmentId },
    'appointment',
    this.reload.bind(this));
};

CashBookCtrl.prototype.getTotal = function () {
  var total = 0;

  for (var paymentMethod in this.cashBook.total) {
    if (this.cashBook.total.hasOwnProperty(paymentMethod)) {
      total += this.cashBook.total[paymentMethod];
    }
  }

  return total;
};

CashBookCtrl.prototype.getSubTotal = function (method) {
  return this.cashBook.total && this.cashBook.total[method] ? this.cashBook.total[method] : 0;
};

CashBookCtrl.prototype.cancelEntry = function (cashBookEntry) {
  this._ModalService.openCashEntryCancelModal(cashBookEntry, this.reload.bind(this));
};

CashBookCtrl.prototype.reload = function () {
  var self = this;
  var date = this._stateParams.date ? this._stateParams.date : moment().format('YYYY-MM-DD');

  this._AccountingDA
    .getCashBook(date)
    .then(function (response) {
      self.cashBook = response;
    });
};

CashBookCtrl.prototype.isFABVisible = function () {
 return moment().isSame(this.date, 'day');
};

module.exports = CashBookCtrl;
