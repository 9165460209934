'use strict';

var angularModule = angular.module('t2g.common.directives.priceInputDirective', []);

module.exports = angularModule;

angularModule.directive('priceInput', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            min: '=',
            max: '=',
        },
        link: function ($scope, $element, $attributes, ngModelController) {
            ngModelController.$parsers.push(function(data) {
                var floatyValue = data.toString().trim().replace(',', '.').replace(/[^\d.,]/g,'');
                var floatValue = parseFloat(floatyValue);
                var stringValue = !isNaN(floatValue) ? floatyValue : '';
                var modelValue = !isNaN(floatValue) ? floatValue : undefined;

                if ($scope.min && $scope.min !== 0 && modelValue && modelValue < $scope.min) {
                    modelValue = $scope.min;
                    stringValue = parseFloat(modelValue);
                }

                if ($scope.max && $scope.max !== 0 && modelValue && modelValue > $scope.max) {
                    modelValue = $scope.max;
                    stringValue = parseFloat(modelValue);
                }

                ngModelController.$setViewValue(stringValue);
                ngModelController.$commitViewValue();
                ngModelController.$render();
                return modelValue;

            });
        }
    };
});