'use strict';

var AppointmentModalBaseCtrl = require('./AppointmentModalBaseCtrl');

var CourseAppointmentModalCtrl = function($rootScope,
                                          $controller,
                                          $injector,
                                          AppointmentDA,
                                          appointment,
                                          customers,
                                          customLabels,
                                          gettextCatalog,
                                          modalInstance,
                                          subscription,
                                          resources,
                                          office) {

  AppointmentModalBaseCtrl.call(this, modalInstance, $injector);
  var initialEmployeeId = _.get(appointment, 'object._id');

  this.appointment = appointment;
  this.customerLabel = gettextCatalog.getString(customLabels.customer.toLowerCase());
  this.customers = customers;
  this.resources = resources;
  this.rootScope = $rootScope;
  this.office = office;
  this.showMembershipForm = false;
  this.membershipSubmitted = false;
  this.membershipFormMode;
  this._AppointmentDA = AppointmentDA;
  this.showLog = ['plus', 'enterprise'].includes(subscription.package);
  var courseName = _.get(appointment, 'name');

  if (courseName) {
    this.title = gettextCatalog.getString('Course') + ': ' + courseName;
  } else {
    this.title = gettextCatalog.getString('Course');
  }

  // set default resource
  if (!initialEmployeeId) {
    this.appointment.object = {
      _id: this.resources.employees[0]._id
    };
  }

  this.recurringSettingsWatcher = this.watchRecurringSettings();
};

CourseAppointmentModalCtrl.prototype = new AppointmentModalBaseCtrl();


/**
 * handler will be called if selection in course dropdown has been changed
 */
CourseAppointmentModalCtrl.prototype.courseChanged = function() {
  var selectedCourse = _.find(this.office.courses, {_id: this.appointment.course});
  if (!selectedCourse) {
    return;
  }

  this.appointment.end = moment(this.appointment.start).add(selectedCourse.duration, 'minutes').toDate();
  this.appointment.name = selectedCourse.name;
  this.appointment.partMax = selectedCourse.partMax;
};


/**
 * add customer to membership list
 */
CourseAppointmentModalCtrl.prototype.addMembership = function() {
  this.showMembershipForm = true;
  this.currentMembership = {
    startType: 'startOfCourse',
    endType: 'endOfCourse'
  };
  this.membershipFormMode = 'add';
};


/**
 * abort the add/edit process of membership
 */
CourseAppointmentModalCtrl.prototype.abortMembershipEntry = function() {
  this.showMembershipForm = false;
  this.currentMembership = null;
  this.membershipFormMode = null;
};


/**
 * edit selected membership
 *
 * @param {Number} index
 */
CourseAppointmentModalCtrl.prototype.editMembership = function(index) {
  this.showMembershipForm = true;
  this.currentMembership = this.appointment.membershipSettings[index];
  this.membershipFormMode = 'edit';
};


/**
 * save/update new/selected membership
 */
CourseAppointmentModalCtrl.prototype.saveMembership = function() {
  this.membershipSubmitted = true;
  // check dublicate && update selected
  if (this.MembershipForm.$valid) {
    if (_.get(this.currentMembership, 'customer._id')) {
      if (this.membershipFormMode === 'add') {
        this.appointment.membershipSettings.push(this.currentMembership);
      }

      this.abortMembershipEntry();
    }
  }
};


CourseAppointmentModalCtrl.prototype.addParticipant = function() {
  var self = this;

  if (this.appointment._id && this.currentParticipant && this.currentParticipant._id) {
    this._AppointmentDA
      .addParticipant(this.appointment._id, this.currentParticipant._id)
      .then(function(response) {
        self.appointment = response[0];
        self.currentParticipant = null;
      });
  }
};

CourseAppointmentModalCtrl.prototype.checkParticipantValidity = function() {
  var isValid = true;
  var currentParticipantId = _.get(this.currentParticipant, '_id');

  if (!currentParticipantId) {
    return false;
  } else {
    _.forEach(this.appointment.participants, function(participant) {
      if (participant.customer && participant.customer._id === currentParticipantId) {
        isValid = false;
        return false;
      }
    });
  }

  return isValid;
};

CourseAppointmentModalCtrl.prototype.removeParticipants = function(index) {
  var self = this;
  var customerId = _.get(self.appointment, 'participants[' + index + '].customer._id', index);

  this._AppointmentDA
    .removeParticipant(this.appointment._id, customerId)
    .then(function() {
      self.appointment.participants.splice(index, 1);
    });
};

/**
 * remove a membership
 *
 * @param {Number} index
 */
CourseAppointmentModalCtrl.prototype.removeMembership = function(index) {
  this.appointment.membershipSettings.splice(index, 1);
};


module.exports = CourseAppointmentModalCtrl;
