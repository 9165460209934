var angularModule = angular.module('t2g.common.filter.truncateFilter', []);

module.exports = angularModule;

angularModule.filter('truncate', function() {
    return function(string, length, end) {
        if (!string || !length) return;

        var string = string.substring(0, length);

        if (end) {
            string += end;
        }

        return string;
    }
});