'use strict';

var angularModule = angular.module('t2g.common.data-access.customer', [
  'ngFileUpload',
]);

module.exports = angularModule;

angularModule.factory('CustomerResource',
  function ($resource, settings) {
    var resourceUrl = settings.apiBase + '/api/v2/customer/:customerId/:subResource';

    var resource = $resource(resourceUrl, {}, {
      delete: { method: 'delete' },
      get: { method: 'get', isArray: false},
      insert: { method: 'post' },
      merge: { method: 'post', params: { customerId: 'merge' } },
      update: { method: 'put', params: { customerId: '@_id' } },
      importCSV: { method: 'post', params: { customerId: 'import' } },
      uploadCSV: { method: 'post', params: { customerId: 'upload' } },
      getAppointments: { method: 'get', params: { subResource: 'appointments' }, isArray: false },
      getDocuments: { method: 'get', params: { subResource: 'documents' }, isArray: false },
      deleteImage: { method: 'delete', params: { subResource: 'image' }, isArray: false }
    });

    return resource;
  }
);


angularModule.factory('CustomerDA', function ($q,
                                              Upload,
                                              ModalService,
                                              CustomerResource) {

  var customerCache = [];
  var filteredCustomerCache = [];

  return {
    uploadImage,
    deleteImage,
    get: get,
    getFromCache,
    getAppointments,
    getDocuments,
    getFiltered,
    insert,
    list,
    remove,
    setFiltered,
    update,
    merge,
    importCSV,
    uploadCSV,
    purgeCache,
    insertIntoCache
  };

  function purgeCache() {
    customerCache = [];
    filteredCustomerCache = [];
  }

  function insertIntoCache(customer) {
    const customerFromCache = customerCache.find((cacheCustomer) => cacheCustomer._id === customer._id);

    if(!customerFromCache) {
      customerCache.push(customer);
    }
  }

  function getFromCache(customerId) {
    const customerFromCache = customerCache.find((cacheCustomer) => cacheCustomer._id === customerId);
    return customerFromCache;
  }

  function deleteImage(customerId) {
    return CustomerResource
      .deleteImage({customerId})
      .$promise
      .then((response) => {
        return response.customer;
      });
  }

  function uploadImage(customerId, file) {
    return Upload
      .upload({
        url: `/api/v2/customer/${customerId}/image`,
        file
      })
      .then((response) => {
        return response.data.customer;
      });
  }

  function uploadCSV(data) {
    return CustomerResource
      .uploadCSV(data)
      .$promise
      .then(function (response) {
        return response.customers;
      });

  }

  function importCSV(data) {
    return CustomerResource
      .importCSV(data)
      .$promise
      .then(function (response) {
        return response.customers;
      });
  }

  /**
   * get one customer profile
   * @param {String} customerId
   * @returns {Promise}
   */
  function get(customerId) {
    var params = {
      customerId: customerId
    };

    return CustomerResource
      .get(params)
      .$promise
      .then(function (response) {
        return response.customer;
      });
  }


  /**
   * get all appointments of a customer
   * @param {String} customerId
   * @returns {Promise}
   */
  function getAppointments(customerId, start, end) {
    var params = {
      customerId: customerId
    };

    if (start && end && moment(start).isValid() && moment(end).isValid()) {
      params.start = moment(start).format('YYYY-MM-DD');
      params.end = moment(end).format('YYYY-MM-DD');
    }

    return CustomerResource
      .getAppointments(params)
      .$promise
      .then(function (response) {
        return response.appointments;
      });
  }


  /**
   * get all documents of a customer
   * @param {String} customerId
   * @returns {Promise}
   */
  function getDocuments(customerId) {
    var params = {
      customerId: customerId
    };

    return CustomerResource
      .getDocuments(params)
      .$promise
      .then(function (response) {
        return response.documents;
      });
  }


  /**
   * get filtered customer list
   * @returns {Array}
   */
  function getFiltered() {
    return filteredCustomerCache;
  }


  /**
   * save customer
   * @param {Object} customer object
   * @returns {Promise}
   */
  function insert(customer) {
    return ModalService
      .openPINModal()
      .then((data) => {
        if (data === 'abort') {
          return $q.reject('pin_aborted');
        } else {
          return CustomerResource
            .insert(customer)
            .$promise
            .then(function (response) {
              customerCache.push(response.customer);
              return response.customer;
            });
        }
      });
  }


  /**
   * get list of all customers
   * @returns {Promise}
   */
  function list() {
    if (customerCache.length > 0) {
      return $q.when(customerCache);
    } else {
      return CustomerResource
        .get()
        .$promise
        .then(function (response) {
          customerCache = response.customers;
          return response.customers;
        });
    }
  }


  /**
   * remove a customer
   * @param {String} customerId
   * @returns {Promise}
   */
  function remove(customerId) {
    return ModalService
      .openPINModal()
      .then((data) => {
        if (data === 'abort') {
          return $q.reject('pin_aborted');
        } else {
          return CustomerResource
            .delete({ customerId: customerId })
            .$promise
            .then(function () {
              _.remove(customerCache, { _id: customerId });
              return true;
            });
        }
      });
  }


  /**
   * merge customers
   * @param {String} customerIds
   * @returns {Promise}
   */
  function merge(mergeObj) {
    return ModalService
      .openPINModal()
      .then((data) => {
        if (data === 'abort') {
          return $q.reject('pin_aborted');
        } else {
          return CustomerResource
            .merge(mergeObj)
            .$promise
            .then(function (response) {
              customerCache = response.customers;
              return true;
            });
        }
      });
  }


  /**
   * set filtered customer list
   * @param filteredCustomer
   */
  function setFiltered(filteredCustomer) {
    filteredCustomerCache = filteredCustomer;
  }


  /**
   * update a customer
   * @param {Object} customer object
   * @returns {Promise}
   */
  function update(customer) {
    return ModalService
      .openPINModal()
      .then((data) => {
        if (data === 'abort') {
          return $q.reject('pin_aborted');
        } else {
          return CustomerResource
            .update(customer)
            .$promise
            .then(function (response) {
              var cachedCustomerIndex = _.findIndex(customerCache, { _id: customer._id });

              if (response.customer.birthday) {
                response.customer.birthday = new Date(response.customer.birthday);
              }

              customerCache[cachedCustomerIndex] = response.customer;
              return response.customer;
            });
        }
      });
  }
});
