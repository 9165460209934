'use strict';

var CalendarCtrl = function ($scope,
                             $state,
                             $stateParams,
                             $window,
                             gettextCatalog,
                             ModalService,
                             PixelTimeService,
                             DatepickerSettings,
                             UserSession,
                             CalendarSettings,
                             settings) {

  var self = this;
  const start = typeof CalendarSettings.get('offset') === 'number' ? CalendarSettings.get('offset') : 7;
  const end = typeof CalendarSettings.get('end') === 'number' ? CalendarSettings.get('end') : 23;
  var contextMenuItems = {
    appointment: {
      label: gettextCatalog.getString('Appointment'),
      action: this.openAppointmentModal.bind(this, 'customer'),
      icon: 'icon-account'
    },
    course: {
      label: gettextCatalog.getString('Course'),
      action: this.openAppointmentModal.bind(this, 'course'),
      icon: 'icon-account-multiple'
    },
    private: {
      label: gettextCatalog.getString('Private'),
      action: this.openAppointmentModal.bind(this, 'private'),
      icon: 'icon-lock',
      seperator: true
    },
    abort: {
      label: gettextCatalog.getString('Abort'),
      action: angular.noop
    }
  };

  this.hours = [];

  for(let i = start; i < end; i++) {
    this.hours.push(`0${i}`.slice(-2));
  }

  this._scope = $scope;
  this._PixelTimeService = PixelTimeService;
  this._ModalService = ModalService;
  this._stateParams = $stateParams;
  this._state = $state;
  this._window = $window;
  this._resourceType = 'employee';
  this.viewModel = {
    date: Date.parse(this._stateParams.date),
    resource: this._stateParams.resource,
    view: this._stateParams.view
  };
  this.currentDate = new Date();
  this.dtpOptions = DatepickerSettings.default;
  this.user = UserSession.get();
  this.views = [
    {
      label: gettextCatalog.getString('Day'),
      id: 'day'
    },
    {
      label: gettextCatalog.getString('Week'),
      id: 'week'
    },
    {
      label: gettextCatalog.getString('4 Days'),
      id: '4days'
    },
    {
      label: gettextCatalog.getString('List'),
      id: 'list'
    }
  ];
  this.dtpCurrentOpened = false;
  this.dtpFormat = this.viewModel.view === 'week' ? 'dd.MM.yyyy (\'KW\' w)' : 'dd.MM.yyyy (EEE, \'KW\' w)';
  this.dtpSize = this.viewModel.view === 'week' ? 10 : 16;
  this.contextMenuModel = {
    event: 'click',
    items: []
  };
  this.showResourceDropdown = false;
  this.resourceCount = 0;

  const editRights = _.get(this.user, 'rights.appointments.edit');

  if(editRights === 'all') {
    _.forEach($scope.resources, function (resource) {
      if (resource._id !== 'all-employees' && resource._id !== 'all-rooms') {
        self.resourceCount++;

        if (self.resourceCount > 1) {
          self.showResourceDropdown = true;
          return false;
        }
      }
    });
  }

  _.forEach(contextMenuItems, function (contextMenuItem, key) {
    if (settings.calendar.contextMenu[ key ]) {
      self.contextMenuModel.items.push(contextMenuItem);
    }
  });

  $scope.$watch(function () {
    return self.viewModel;
  }, function (newValue, oldValue) {
    if (!angular.equals(newValue, oldValue)) {
      var date = moment(newValue.date);
      self._state.go('private.main.calendar', {
        resource: newValue.resource,
        date: date.format('YYYY-MM-DD'),
        view: newValue.view
      });
    }

  }, true);

};


CalendarCtrl.prototype.changeDate = function (direction) {
  var difference;

  switch (this.viewModel.view) {
    case 'day':
    case 'list':
      difference = 1;
      break;
    case '4days':
      difference = 4;
      break;
    case 'week':
      difference = 7;
      break;
  }

  difference = difference * direction;
  var date = moment(this.viewModel.date).add(difference, 'days');
  this.viewModel.date = date.toDate();
};

CalendarCtrl.prototype.openAppointmentModal = function (type, event, originalEvent, metaData) {
  var clickedTime = this._PixelTimeService.pixelToTime(originalEvent.offsetY, true);
  var start = new Date(metaData.date);
  start.setHours(clickedTime.hours);
  start.setMinutes(clickedTime.roundedMinutes);
  var end = new Date(start);
  end.setHours(clickedTime.hours + 1);

  var appointment = {
    type: type,
    start: start,
    end: end
  };

  if (metaData.type === 'room') {
    appointment.room = metaData.resource;
  } else {
    appointment.employee = metaData.resource,
    appointment.object = {
      _id: metaData.resource
    };
  }

  this._ModalService.openAppointmentModal(appointment);
};

CalendarCtrl.prototype.setToday = function () {
  this.viewModel.date = moment().startOf('day').toDate();
};

CalendarCtrl.prototype.print = function () {
  this._window.print();
};

CalendarCtrl.prototype.getResourceImageStyle = function (resource) {
  var defImage = 'https://cdn.termin2go.com/app/img/employee_man.png';

  if (resource.salutation === 'f') {
    defImage = 'https://cdn.termin2go.com/app/img/employee_woman.png';
  }

  return {
    'background-image': 'url(' + resource.image + '), url(' + defImage + ')'
  };
};

CalendarCtrl.prototype.getCustomerNames = function (customers) {
  let customerNames = '';

  customers.forEach((customer) => {
    customerNames += `${customer.fullname}<br>`;
  });

  return customerNames;
};
module.exports = CalendarCtrl;

