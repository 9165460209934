var CancelAccountModalCtrl = function (gettextCatalog, SubscriptionDA, Notification, modalInstance) {

  this._modalInstance = modalInstance;
  this._SubscriptionDA = SubscriptionDA;
  this._Notification = Notification;
  this.deleteType = 'one';
  this.gettextCatalog = gettextCatalog;
  this.reason = {
  };
  this.comment = '';
};

CancelAccountModalCtrl.prototype.close = function () {
  this._modalInstance.close();
};

CancelAccountModalCtrl.prototype.delete = function () {
  this._modalInstance.close(this.deleteType);
};

CancelAccountModalCtrl.prototype.save = function () {
  this._SubscriptionDA
    .cancelContract({reason: this.reason, comment: this.comment})
    .then(() => {
      this._Notification.addSuccessMessage(this.gettextCatalog.getString('Your contract will be terminated at the next possible date and all your data will be deleted. We will inform you via email if this is done.'));
      this.close();
    })
    .catch((error) => {
      this._Notification.addErrorMessage({ text: error });
    })
};

module.exports = CancelAccountModalCtrl;
