'use strict';

var ServiceModalCtrlNew = function ($scope,
                                    service,
                                    taxes,
                                    subscription,
                                    office,
                                    resources,
                                    Notification,
                                    gettextCatalog,
                                    OfficeDA,
                                    DatepickerSettings,
                                    modalInstance) {


  $scope.currentService = service;
  $scope.backupGroups = {};
  $scope.groups = [];
  $scope.pickedGroup = '';
  $scope.resources = [];
  $scope.needPremium = false;
  $scope.isSecondTimeWeekly = false;
  $scope.isCovidTestEnabled = _.get(office, 'settings.covidTest.enabled', false) && _.get(office, 'settings.covidTest.provider') === 'termin2go';
  $scope.colors = [
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#03A9F4',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#9E9E9E',
    '#607D8B',
    '#999999',
    '#000000'
  ];
  $scope.office = office;
  $scope.resources = resources;
  $scope.currentTariffService = null;
  $scope.groupSizes = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
  ];
  $scope.testTypes = [
    {
      id: 'quickTest',
      label: 'Schnelltest'
    },
    {
      id: 'pcr',
      label: 'PCR-Test (eigenes Gerät)'
    },
    {
      id: 'pcrLabor',
      label: 'PCR-Test (Labor)'
    },
    {
      id: 'none',
      label: 'nicht zuordnen'
    },
  ];
  $scope.locations = [];
  var serviceMoved = false;
  var initWatchFired = false;

  $scope.filter = {
    start: moment().subtract(6, 'month').toDate(),
    end: new Date()
  };
  $scope.dtpOptionsDateRangeStart = angular.copy(DatepickerSettings.default);
  $scope.dtpOptionsDateRangeEnd = angular.copy(DatepickerSettings.default);
  $scope.showPremiumNotification = subscription.onlyBasicFunctions;
  $scope.taxes = taxes;
  $scope.currentService = service;

  function init() {
    var count = 0;

    if ($scope.office.serviceGroups instanceof Array) {
      for (var i = 0, groups = $scope.office.serviceGroups.length; i < groups; i++) {
        $scope.groups.push($scope.office.serviceGroups[i].name);

        count += $scope.office.serviceGroups[i].services.length;
      }
    }

    // new service
    if (!service) {
      service = {
        price: undefined,
        duration: 30,
        preparation: 0,
        followup: 0,
        color: 'default',
        accounting: {
          tax: taxes[0].value
        },
        resources: {
          employee: [],
          room: [],
          other: []
        }
      };

      if($scope.isCovidTestEnabled) {
        service.covid = {};
      }

      // get resources for service:employee matching
      for (var i = 0, resLen = $scope.resources.length; i < resLen; i++) {
        var resource = $scope.resources[i];

        service.resources[resource.type].push(resource._id);
      }

      $scope.office.serviceGroups[0].services.push(service);
      $scope.pickedGroup = $scope.office.serviceGroups[0].name;
    } else {
      var index = -1;

      service = service || {};

      for (var i = 0; i < $scope.office.serviceGroups.length; i++) {
        index = _.findIndex($scope.office.serviceGroups[i].services, { _id: service._id });

        if (index > -1) {
          $scope.pickedGroup = $scope.office.serviceGroups[i].name;
          break;
        }
      }

      service.accounting = service.accounting || {};
      service.accounting.tax = service.accounting.tax === undefined ? taxes[0].value : service.accounting.tax;
      $scope.deepLink = $scope.office.profile.deepLink.replace('{serviceId}', $scope.currentService._id);
    }

    service.timeOffer = service.timeOffer || { mode: 'disabled' };

    if (service.timeOffer.start) {
      service.timeOffer.start = new Date(service.timeOffer.start);
    }

    if (service.timeOffer.end) {
      service.timeOffer.end = new Date(service.timeOffer.end);
    }

    service.color = service.color || 'default';
    service.preparation = service.preparation || 0;
    service.followup = service.followup || 0;
    $scope.currentService = service;
    $scope.backupGroups = angular.copy($scope.office.serviceGroups);


    $scope.currentService.location = $scope.currentService.location || office.serviceLocations[0]._id;

    if ($scope.currentService && $scope.isCovidTestEnabled && !_.get($scope.currentService, 'covid.testType')) {
      $scope.currentService.covid = $scope.currentService.covid || {};
      $scope.currentService.covid.testType = 'quickTest';
    }

  }

  /**
   * reset the data
   */
  $scope.abort = function () {
    for (var prob in $scope.backupGroups) {
      if ($scope.office.serviceGroups.hasOwnProperty(prob)) {
        $scope.office.serviceGroups[prob] = $scope.backupGroups[prob];
      }
    }

    $scope.close();
  };

  /**
   * move service to selected group
   */
  $scope.$watch('pickedGroup', function (newValue, oldValue) {
    if (newValue !== oldValue) {
      serviceMoved = true;
      moveService();
    }
  });

  /**
   * save current appointment to database
   */
  $scope.save = function () {
    var groupIndex = -1;
    var serviceIndex = -1;
    $scope.showValidation = true;

    if ($scope.currentService.price === '') {
      delete $scope.currentService.price;
    }

    if ($scope.currentService.color === 'default') {
      delete $scope.currentService.color;
    }

    if (!$scope.currentService.freeTime && $scope.currentService.freeTimeOffset) {
      delete $scope.currentService.freeTimeOffset;
    } else if (($scope.currentService.freeTime + $scope.currentService.freeTimeOffset) > $scope.currentService.duration) {
      Notification.addErrorMessage(gettextCatalog.getString('The exposure time must not be longer than the duration of the service.'));
    } else if (shorthandExists()) {
      Notification.addErrorMessage(gettextCatalog.getString('shorthand already used'));
    } else if ($scope.serviceForm.$valid) {

      // replace existing service
      if ($scope.currentService._id) {
        for (var i = 0; i < $scope.office.serviceGroups.length; i++) {
          groupIndex = i;
          serviceIndex = _.findIndex($scope.office.serviceGroups[i].services, { _id: $scope.currentService._id });

          if (serviceIndex > -1) {
            break;
          }
        }

        $scope.office.serviceGroups[groupIndex].services[serviceIndex] = $scope.currentService;
      }

      OfficeDA
        .update($scope.office)
        .then(() => {
          $scope.showValidation = false;
          Notification.addSuccessMessage(gettextCatalog.getString('Service "{{name}}" saved', { name: $scope.currentService.name }));
          $scope.close();
        })
        .catch((error) => {
          Notification.addErrorMessage(gettextCatalog.getString({ text: error }));
        });
    }
  }


  /**
   * delete current appointment
   */
  $scope.delete = function () {
    var index = -1;
    // find old group and delete service
    for (var i = 0; i < $scope.office.serviceGroups.length; i++) {
      index = _.findIndex($scope.office.serviceGroups[i].services, { _id: $scope.currentService._id });

      for (var j = 0; j < $scope.office.serviceGroups[i].services.length; j++) {
        if ($scope.office.serviceGroups[i].services[j]._id === $scope.currentService._id) {
          index = j;
          break;
        }
      }

      if (index > -1) {
        $scope.office.serviceGroups[i].services.splice(index, 1);
        break;
      }
    }

    if (index > -1) {
      OfficeDA
        .update($scope.office)
        .then(() => {
          Notification.addSuccessMessage(gettextCatalog.getString('Service "{{name}}" deleted', { name: $scope.currentService.name }));
          $scope.close();
        })
        .catch((error) => {
          Notification.addErrorMessage(gettextCatalog.getString({ text: error }));
        });
    } else {
      Notification.addErrorMessage(gettextCatalog.getString({ text: error }));
    }
  };

  $scope.getNumber = function (num) {
    return new Array(num);
  };

  $scope.addWeeklyTime = function (index) {
    var firstEndTime = $scope.currentService.timeOffer.weekPlan[index].times[0].end;

    $scope.currentService.timeOffer.weekPlan[index].times.push({
      start: ((firstEndTime + 60) > 1440) ? 1440 : firstEndTime + 60,
      end: ((firstEndTime + 240) > 1440) ? 1440 : firstEndTime + 240
    });

    $scope.isSecondTimeWeekly = true;
  };

  $scope.removeWeeklyTime = function (index) {
    $scope.currentService.timeOffer.weekPlan[index].times.splice(1, 1);
    $scope.isSecondTimeWeekly = checkSecondTimeWeekly();
  };


  /**
   * move the service to chosen service group
   */
  function moveService() {
    var index = -1;
    // find old group and delete service
    for (var i = 0; i < $scope.office.serviceGroups.length; i++) {

      if ($scope.currentService._id) {
        index = _.findIndex($scope.office.serviceGroups[i].services, { _id: $scope.currentService._id });
      } else {
        index = _.findIndex($scope.office.serviceGroups[i].services, $scope.currentService);
      }

      if (index > -1) {
        $scope.office.serviceGroups[i].services.splice(index, 1);
        break;
      }
    }

    // move to new group
    for (var i = 0; i < $scope.office.serviceGroups.length; i++) {
      if ($scope.office.serviceGroups[i].name === $scope.pickedGroup) {
        $scope.office.serviceGroups[i].services.push($scope.currentService);
      }
    }
  }

  /**
   * check if a shorthand already is taken
   * @returns {boolean}
   */
  function shorthandExists() {
    var count = 0;

    if ($scope.currentService.shorthand) {
      for (var i = 0; i < $scope.office.serviceGroups.length; i++) {
        for (var j = 0; j < $scope.office.serviceGroups[i].services.length; j++) {
          if ($scope.office.serviceGroups[i].services[j].shorthand === $scope.currentService.shorthand) {
            count++;
          }
        }
      }
    }

    if (count < 2) {
      return false;
    } else {
      return true;
    }
  }

  function setMinMaxDate() {
    $scope.dtpOptionsDateRangeStart.maxDate = $scope.currentService.timeOffer.end || moment().add(1, 'month').toDate();
    $scope.dtpOptionsDateRangeEnd.minDate = $scope.currentService.timeOffer.start || new Date();
  }

  function checkSecondTimeWeekly() {
    var secondTimeIndex = _.findIndex(_.get($scope, 'currentService.timeOffer.weekPlan', []), function (day) {
      return day.times.length > 1;
    });

    return secondTimeIndex > -1 ? true : false;
  }

  $scope.$watch('currentService.timeOffer', function (newValue) {

    switch (newValue.mode) {
      case 'disabled':
        delete $scope.currentService.timeOffer.weekPlan;
        delete $scope.currentService.timeOffer.start;
        delete $scope.currentService.timeOffer.end;
        break;
      case 'dateRange':
        if (!$scope.currentService.timeOffer.start ||
          !moment($scope.currentService.timeOffer.start).isValid()) {
          $scope.currentService.timeOffer.start = moment().startOf('day').toDate();
        }

        if (!$scope.currentService.timeOffer.end ||
          !moment($scope.currentService.timeOffer.end).isValid()) {
          $scope.currentService.timeOffer.end = moment().add(1, 'month').endOf('day').toDate();
        }

        delete $scope.currentService.timeOffer.weekPlan;
        break;
      case 'time':
        if (!_.isArray($scope.currentService.timeOffer.weekPlan)) {
          $scope.currentService.timeOffer.weekPlan = [];

          for (var i = 0; i < 7; i++) {
            $scope.currentService.timeOffer.weekPlan.push({
              available: false,
              times: []
            });
          }

          _.forEach($scope.office.openingHours.regular, function (openingHour, index) {
            var day = $scope.currentService.timeOffer.weekPlan[index];
            day.available = openingHour.open;
            day.times = openingHour.times;
          });
        }

        delete $scope.currentService.timeOffer.start;
        delete $scope.currentService.timeOffer.end;
        break;
    }

    if (newValue.mode === 'time') {
      $scope.isSecondTimeWeekly = checkSecondTimeWeekly();
    }

    setMinMaxDate();
    initWatchFired = true;
  }, true);

  $scope.close = function () {
    modalInstance.close();
  };

  $scope.getEncodedUrl = function(url) {
    return encodeURIComponent(url);
  };

  $scope.getLabel = function(locationLabel) {
    let typeTranslation = '';
    let label = locationLabel.label ? `: ${locationLabel.label}` : '';

    if(locationLabel.type === 'address') {
      typeTranslation = gettextCatalog.getString('Address');
    } if(locationLabel.type === 'at-customer') {
      typeTranslation = gettextCatalog.getString('On-site with the customer');
    } else if(locationLabel.type === 'remote') {
      typeTranslation = gettextCatalog.getString('Online meeting');
    }
    return `${typeTranslation}${label}`;
  };

  init();
};

module.exports = ServiceModalCtrlNew;
