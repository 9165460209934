var DeleteSeriesModalCtrl = function (appointment,
                                      modalInstance) {

  this._modalInstance = modalInstance;
  this.deleteType = 'one';
};

DeleteSeriesModalCtrl.prototype.close = function () {
  this._modalInstance.close();
};

DeleteSeriesModalCtrl.prototype.delete = function () {
  this._modalInstance.close(this.deleteType);
};

module.exports = DeleteSeriesModalCtrl;