'use strict';

var angularModule = angular.module('t2g.common.services.loading-indocator', []);

module.exports = angularModule;

angularModule.factory('LoadingIndicatorService', function($rootScope) {
    hide();

    return {
        hide: hide,
        show: show
    };

    function hide() {
        $rootScope.showLoadingIndicator = false;
    }

    function show() {
        $rootScope.showLoadingIndicator = true;
    }

});
