let angularModule = angular.module('t2g.common.data-access.marketing', []);

module.exports = angularModule;

angularModule.factory('MarketingResource', function ($resource, settings) {
  const resourceUrl = settings.apiBase + '/api/v2/marketing/:subResource1/:subResource2';
  const resource = $resource(resourceUrl, {}, {
    startCampaign: { method: 'POST', params: { subResource1: 'campaign' } },
    updateCampaign: { method: 'PUT', params: { subResource1: 'campaign', subResource2: '@_id' } },
    listCampaigns: { method: 'GET', params: { subResource1: 'campaign' }, isArray: false }
  });

  return resource;
});

angularModule.factory('MarketingDA', function (MarketingResource) {
  return {
    listCampaigns,
    startCampaign,
    updateCampaign,
  };

  function listCampaigns(id, type) {
    let query = {};


    if (type) {
      query.type = type;
    }

    if (id) {
      query.id = id;
    }

    return MarketingResource
      .listCampaigns(query)
      .$promise
      .then(response => response.campaigns);
  }

  function startCampaign(data) {
    return MarketingResource
      .startCampaign(data)
      .$promise
      .then(response => response.campaign);
  }

  function updateCampaign(data) {
    return MarketingResource
      .updateCampaign(data)
      .$promise
      .then(response => response.campaign);
  }
});

