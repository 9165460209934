'use strict';

var angularModule = angular.module('t2g.common.data-access.google-sync', []);

module.exports = angularModule;

angularModule.factory('GoogleSyncResource', function ($resource, settings) {
        var resourceUrl = settings.apiBase + '/api/v2/google-sync/:office';

        var resource = $resource(resourceUrl, {}, {
            get: {method: 'get'},
            update: {method: 'PUT'}
        });

        return resource;
    }
);


angularModule.factory('GoogleSyncDA', function ($q,
                                                GoogleSyncResource,
                                                LoadingIndicatorService) {

    return {
        getCalendarList: getCalendarList,
        update: update,
        revokeAccess: revokeAccess
    };

    function getCalendarList() {
        LoadingIndicatorService.show();

        return GoogleSyncResource
            .get()
            .$promise
            .then(function (response) {
                LoadingIndicatorService.hide();
                return response;
            }, function (response) {
                LoadingIndicatorService.hide();
                return response.data;
            });
    }


    function update(sync) {
        LoadingIndicatorService.show();

        return GoogleSyncResource
            .update(sync)
            .$promise
            .then(function (savedSync) {
                    LoadingIndicatorService.hide();
                    return savedSync;
                },
                function (error) {
                    LoadingIndicatorService.hide();
                    return $q.reject(error);
                });
    }

    function revokeAccess() {
        LoadingIndicatorService.show();

        return GoogleSyncResource
            .delete()
            .$promise
            .then(function (response) {
                LoadingIndicatorService.hide();
                return response;
            }, function (response) {
                LoadingIndicatorService.hide();
                return response;
            });
    }

});
