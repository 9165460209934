module.exports = function($scope,
                          $timeout,
                          CustomerDA,
                          gettextCatalog,
                          IndustrySpecific,
                          Notification,
                          OfficeDA,
                          Upload) {

    $scope.fileTypes = ['CSV'];
    $scope.fileFormats = ['ISO-8859-1', 'UTF8', 'UCS2', 'ASCII', 'Binary', 'Base64'];
    $scope.delimitters = [
        {
            label: gettextCatalog.getString('Semicolon'),
            value: ';'
        },
        {
            label: gettextCatalog.getString('Comma'),
            value: ','
        }
    ];

    $scope.fields = [
        {
            label: gettextCatalog.getString('Firstname'),
            name: 'firstname'
        },
        {
            label: gettextCatalog.getString('Lastname'),
            name: 'lastname'
        },
        {
            label: gettextCatalog.getString('Salutation'),
            name: 'salutation'
        },
        {
            label: gettextCatalog.getString('Telephone'),
            name: 'telephone'
        },
        {
            label: gettextCatalog.getString('Email'),
            name: 'email'
        },
        {
            label: gettextCatalog.getString('Street'),
            name: 'street'
        },
        {
            label: gettextCatalog.getString('Nr'),
            name: 'no'
        },
        {
            label: gettextCatalog.getString('Postal'),
            name: 'postal'
        },
        {
            label: gettextCatalog.getString('City'),
            name: 'city'
        },
        {
            label: gettextCatalog.getString('Birthday'),
            name: 'birthday'
        },
        {
            label: gettextCatalog.getString('Email reminder'),
            type: 'reminder',
            name: 'email'
        },
        {
            label: gettextCatalog.getString('SMS reminder'),
            type: 'reminder',
            name: 'sms'
        }
    ];

    $scope.settings = {
        fileType: 'CSV',
        fileFormat: 'ISO-8859-1',
        delimiter: ';'
    };

    $scope.$files = [];

    IndustrySpecific
        .getLabels()
        .then(function(labels) {
            $scope.customerLabel = gettextCatalog.getString(labels.customer.toLowerCase());
            $scope.customersLabel = gettextCatalog.getString(labels.customers.toLowerCase());
        });


    /**
     * get office data for custom customer fields
     */
    OfficeDA
      .get()
      .then((office) => {
          if ('customFields' in office.settings) {
              if (angular.isArray(office.settings.customFields.customer)) {
                  office.settings.customFields.customer.forEach(function(customField) {
                      $scope.fields.push({
                          label: customField.label,
                          name: customField.name,
                          type: 'custom'
                      });
                  });
              }
          }
      });

    /**
     * generate logo preview
     * @param $files
     */
    $scope.onFileSelect = function($files) {
        $scope.$files = $files;
    };

    /**
     * upload file and return the two first lines
     */
    $scope.upload = function() {
        // upload all files
        Upload.upload({
            url: '/api/v2/customer/upload',
            data: $scope.settings,
            file: $scope.$files
        }).progress(function(evt) {
            console.log('upload:' + parseInt(100.0 * evt.loaded / evt.total));
        }).success(function(response) {
            var data = response.customers;
            $scope.csvFields = [];

            for (var i = 0; i < data[0].length; i++) {
                $scope.csvFields.push(null);
            }

            $scope.sampleData = data;
        }).error(function(error) {
            if (error.message === 'file_to_large') {
                Notification.addErrorMessage(gettextCatalog.getString('File is too big (max. 1MB)'));
            } else if (error.message === 'extension_not_allowed') {
                Notification.addErrorMessage(gettextCatalog.getString('File type is not allowed'));
            } else {
                Notification.addErrorMessage(gettextCatalog.getString('An unknown error occured'));
            }
        });
    };


    /**
     * upload the file again and start import
     */
    $scope.import = function() {
        Upload.upload({
            url: '/api/v2/customer/import',
            data: {settings: $scope.settings, fields: $scope.csvFields},
            file: $scope.$files
        }).progress(function(evt) {
            console.log('upload:' + parseInt(100.0 * evt.loaded / evt.total));
        }).success(function(response) {
            var data = response.customers;
            Notification.addSuccessMessage(gettextCatalog.getString('{{count}} data rows imported', {count: data.length}));
            CustomerDA.purgeCache();
        }).error(function(error) {
            if (error.message === 'file_to_large') {
                Notification.addErrorMessage(gettextCatalog.getString('File is too big (max. 1MB)'));
            } else if (error.message === 'extension_not_allowed') {
                Notification.addErrorMessage(gettextCatalog.getString('File type is not allowed'));
            } else {
                Notification.addErrorMessage(gettextCatalog.getString('An unknown error occured'));
            }
        });
    };

    $scope.isValid = function() {
        var lastnamePicked = false;
        var firstnamePicked = false;

        // check for first and lastname
        $scope.csvFields.forEach(function(csvField) {
            if (csvField) {
                if (csvField.name === 'lastname') {
                    lastnamePicked = true;
                }
                if (csvField.name === 'lastname') {
                    firstnamePicked = true;
                }
            }
        });


        if (lastnamePicked && firstnamePicked) {
            return true;
        } else {
            return false;
        }
    }
};
