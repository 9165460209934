var configModule = angular.module('t2g.config.placeholder', []);

module.exports = configModule;

configModule.factory('placeholderSettings', function (gettextCatalog) {
  return {
    acceptBooking: [
      {
        value: 'OFFICE_NAME',
        label: gettextCatalog.getString('Business - Name')
      },
      {
        value: 'OFFICE_STREET',
        label: gettextCatalog.getString('Business - Street')
      },
      {
        value: 'OFFICE_POSTAL',
        label: gettextCatalog.getString('Business - Postal')
      },
      {
        value: 'OFFICE_CITY',
        label: gettextCatalog.getString('Business - City')
      },
      {
        value: 'OFFICE_PHONE',
        label: gettextCatalog.getString('Business - Phone')
      },
      {
        value: 'OFFICE_EMAIL',
        label: gettextCatalog.getString('Business - E-Mail')
      },
      {
        value: 'OFFICE_WEB',
        label: gettextCatalog.getString('Business - Web')
      },
      {
        value: 'CUSTOMER_FIRSTNAME',
        label: gettextCatalog.getString('Customer - Firstname')
      },
      {
        value: 'CUSTOMER_LASTNAME',
        label: gettextCatalog.getString('Customer - Lastname')
      },
      {
        value: 'CUSTOMER_BIRTHDAY',
        label: gettextCatalog.getString('Customer - Birthday')
      },
      {
        value: 'APPOINTMENT_CANCELLATION_LINK',
        label: gettextCatalog.getString('Appointment - Cancellation Limit')
      },
      {
        value: 'APPOINTMENT_CANCELLATION_LIMIT',
        label: gettextCatalog.getString('Appointment - Cancellation Link')
      },
      {
        value: 'APPOINTMENT_SERVICES',
        label: gettextCatalog.getString('Appointment - Services')
      },
      {
        value: 'APPOINTMENT_SERVICE_TEXT',
        isHTML: true,
        label: gettextCatalog.getString('Appointment - Service Text')
      },
      {
        value: 'APPOINTMENT_DATE',
        label: gettextCatalog.getString('Appointment - Start Date')
      },
      {
        value: 'APPOINTMENT_TIME',
        label: gettextCatalog.getString('Appointment - Start Time')
      },
      {
        value: 'EMPLOYEES',
        label: gettextCatalog.getString('Employee Names')
      },
      {
        value: 'NOTE',
        label: gettextCatalog.getString('Note (from manual booking confirmation)')
      },
      {
        value: 'APPOINTMENT_LOCATION',
        isHTML: true,
        label: gettextCatalog.getString('Service Location')
      },
      {
        value: 'FIELDS',
        isHTML: true,
        label: gettextCatalog.getString('Custom Fields')
      }
    ],
    declineBooking: [
      {
        value: 'OFFICE_NAME',
        label: gettextCatalog.getString('Business - Name')
      },
      {
        value: 'OFFICE_LINK',
        label: gettextCatalog.getString('Business - Profile Link')
      },
      {
        value: 'CUSTOMER_FIRSTNAME',
        label: gettextCatalog.getString('Customer - Firstname')
      },
      {
        value: 'CUSTOMER_LASTNAME',
        label: gettextCatalog.getString('Customer - Lastname')
      },
      {
        value: 'NOTE',
        label: gettextCatalog.getString('Note')
      },
      {
        value: 'APPOINTMENT_SERVICES',
        label: gettextCatalog.getString('Appointment - Services')
      },
      {
        value: 'FIELDS',
        isHTML: true,
        label: gettextCatalog.getString('Custom Fields')
      }
    ],
    remindAppointment: [
      {
        value: 'OFFICE_NAME',
        label: gettextCatalog.getString('Business - Name')
      },
      {
        value: 'OFFICE_STREET',
        label: gettextCatalog.getString('Business - Street')
      },
      {
        value: 'OFFICE_POSTAL',
        label: gettextCatalog.getString('Business - Postal')
      },
      {
        value: 'OFFICE_CITY',
        label: gettextCatalog.getString('Business - City')
      },
      {
        value: 'OFFICE_PHONE',
        label: gettextCatalog.getString('Business - Phone')
      },
      {
        value: 'OFFICE_EMAIL',
        label: gettextCatalog.getString('Business - E-Mail')
      },
      {
        value: 'OFFICE_WEB',
        label: gettextCatalog.getString('Business - Web')
      },
      {
        value: 'CUSTOMER_FIRSTNAME',
        label: gettextCatalog.getString('Customer - Firstname')
      },
      {
        value: 'CUSTOMER_LASTNAME',
        label: gettextCatalog.getString('Customer - Lastname')
      },
      {
        value: 'APPOINTMENT_SERVICES',
        label: gettextCatalog.getString('Appointment - Services')
      },
      {
        value: 'APPOINTMENT_DATE',
        label: gettextCatalog.getString('Appointment - Start Date')
      },
      {
        value: 'APPOINTMENT_TIME',
        label: gettextCatalog.getString('Appointment - Start Time')
      },
      {
        value: 'APPOINTMENT_SERVICE_TEXT',
        isHTML: true,
        label: gettextCatalog.getString('Appointment - Service Text')
      },
      {
        value: 'APPOINTMENT_LOCATION',
        isHTML: true,
        label: gettextCatalog.getString('Service Location')
      },
      {
        value: 'FIELDS',
        isHTML: true,
        label: gettextCatalog.getString('Custom Fields')
      }
    ],
    appointmentDeleted: [
      {
        value: 'OFFICE_NAME',
        label: gettextCatalog.getString('Business - Name')
      },
      {
        value: 'CUSTOMER_FIRSTNAME',
        label: gettextCatalog.getString('Customer - Firstname')
      },
      {
        value: 'CUSTOMER_LASTNAME',
        label: gettextCatalog.getString('Customer - Lastname')
      },
      {
        value: 'APPOINTMENT_DATE',
        label: gettextCatalog.getString('Appointment - Start Date')
      },
      {
        value: 'APPOINTMENT_TIME',
        label: gettextCatalog.getString('Appointment - Start Time')
      }
    ],
    appointmentChanged: [
      {
        value: 'OFFICE_NAME',
        label: gettextCatalog.getString('Business - Name')
      },
      {
        value: 'CUSTOMER_FIRSTNAME',
        label: gettextCatalog.getString('Customer - Firstname')
      },
      {
        value: 'CUSTOMER_LASTNAME',
        label: gettextCatalog.getString('Customer - Lastname')
      },
      {
        value: 'APPOINTMENT_DATE',
        label: gettextCatalog.getString('Appointment - Start Date')
      },
      {
        value: 'APPOINTMENT_TIME',
        label: gettextCatalog.getString('Appointment - Start Time')
      }
    ]
  };
});
