'use strict';

var angularModule = angular.module('t2g.common.data-access.resource', []);

module.exports = angularModule;

angularModule.factory('ResourceResource', function ($resource, settings) {
    var resourceUrl = settings.apiBase + '/api/v2/resource/:resourceId/:subResource1';

    var resource = $resource(resourceUrl, {}, {
      insert: {method: 'post'},
      remove: {method: 'delete'},
      list: {method: 'get'},
      get: {method: 'get'},
      getAll: {method: 'get', params: {resourceId: 'all'}},
      update: {method: 'put', params: {resourceId: '@_id'}},
      refreshCalendarAddress: {method: 'post', params: {resourceId: 'refreshCalendarAddress'}},
      deleteImage: {method: 'DELETE', params: {subResource1: 'image'}}
    });

    return resource;
  }
);

angularModule.factory('ResourceDA', function ($q,
                                              ResourceResource,
                                              Upload) {

  var resourceCache = [];

  return {
    clearCache,
    getAll,
    get: get,
    insert,
    list,
    remove,
    update,
    refreshCalendarAddress,
    uploadImage,
    deleteImage
  };

  function uploadImage(resourceId, file) {
    return Upload
      .upload({
        url: `/api/v2/resource/${resourceId}/image`,
        data: {},
        file
      })
      .then((response) => {
        return response.data.resource;
      });
  }

  function deleteImage(resourceId) {
    return ResourceResource
      .deleteImage({resourceId})
      .$promise
      .then((response) => {
        return response.resource;
      });
  }

  function refreshCalendarAddress(resourceId) {
    return ResourceResource
      .refreshCalendarAddress({resourceId: resourceId})
      .$promise
      .then(function (response) {
        _.remove(resourceCache, {_id: resourceId});
        resourceCache.push(response.resource);
        return response.resource;
      });
  }

  function clearCache() {
    resourceCache = [];
  }

  function getAll() {
    return ResourceResource.getAll().$promise;
  }

  function get(resourceId) {
    return ResourceResource.get({resourceId: resourceId}).$promise;
  }

  function insert(resource) {
    return ResourceResource
      .insert(resource)
      .$promise
      .then(function (response) {
        resourceCache.push(response.resource);
        return response.resource;
      });
  }

  function list(type) {
    var filter = {};

    if (type) {
      filter.type = type;
    }

    if (resourceCache.length > 0) {
      return $q.when({
        resources: _.filter(resourceCache, filter)
      });
    } else {
      return ResourceResource
        .list()
        .$promise
        .then(function (response) {
          resourceCache = response.resources;
          return {
            resources: _.filter(response.resources, filter)
          };
        });
    }
  }

  function remove(resourceId) {
    return ResourceResource
      .remove({resourceId: resourceId})
      .$promise
      .then(function () {
        _.remove(resourceCache, {_id: resourceId});
        return true;
      });
  }

  function update(resource) {
    return ResourceResource
      .update(resource)
      .$promise
      .then((response) => {
        const resourceIndex = resourceCache.findIndex(item => item._id === resource._id);
        resourceCache[resourceIndex] = response.resource
        return response.resource;

      });
  }

});

