var TestManagementCtrl = function (
  $state,
  $stateParams,
  $scope,
  $timeout,
  Notification,
  DatepickerSettings,
  ModalService,
  LoadingIndicatorService,
  CustomerDA,
  TestDA,
  tests,
  office,
  gettextCatalog
) {
  console.log($scope.subscription);
  this._timeout = $timeout;
  this._state = $state;
  this._stateParams = $stateParams;
  this._Notification = Notification;
  this._ModalService = ModalService;
  this._CustomerDA = CustomerDA;
  this._TestDA = TestDA;
  this._LoadingIndicatorService = LoadingIndicatorService;
  this.gettextCatalog = gettextCatalog;
  this.openTab = $stateParams.openTab;
  this.appointments = tests.appointments;
  this.counts = tests.counts;
  this.stats = tests.stats;
  this.timeOffset = moment().diff(tests.now);
  this.showBranchNames = this.appointments.some(test => test.office && test.office.name)
  this.timer = undefined;
  this.office = office;
  this.date = $stateParams.date ? moment($stateParams.date).toDate() : new Date();
  this.dtpCurrentOpened = false;
  this.dtp2CurrentOpened = false;
  this.dtpFormat = 'dd.MM.yyyy (EEE, \'KW\' w)';
  this.dtpOptions = DatepickerSettings.default;
  this.filter = {
    testId: '',
    testType: 'none',
    result: 'none',
    customer: ''
  };
  this.testTypes = [
    {
      id: 'none',
      label: 'Alle anzeigen'
    },
    {
      id: 'quickTest',
      label: 'Schnelltest'
    },
    {
      id: 'pcr',
      label: 'PCR-Test (eigenes Gerät)'
    },
    {
      id: 'pcrLabor',
      label: 'PCR-Test (Labor)'
    }
  ];
  this.results = [
    {
      id: 'none',
      label: 'Alle anzeigen'
    },
    {
      id: 'negative',
      label: 'Negativ'
    },
    {
      id: 'positive',
      label: 'Positiv'
    }
  ];
  this.testTypestMap = {
    pcr: 'PCR',
    quickTest: 'Schnelltest',
    pcrLabor: 'PCR (Labor)'
  };
  this.upcomingActionMenu = {
    event: 'click',
    items: [
      {
        label: 'Test öffnen',
        action: this.openTest.bind(this),
        icon: 'icon-qr'
      },
      {
        label: 'Termin öffnen',
        action: this.openAppointment.bind(this),
        icon: 'icon-calendar'
      },
      {
        label: 'Einverständniserklärung',
        action: this.openDSGVO.bind(this),
        icon: 'icon-note',
      },
      {
        label: 'Laufzettel',
        action: this.openTestInfo.bind(this),
        icon: 'icon-note',
      },
      {
        label: 'Test/Timer starten',
        action: this.startTest.bind(this),
        icon: 'icon-play',
        seperator: true
      },
      {
        label: gettextCatalog.getString('Cancel'),
        action: angular.noop,
      },
    ]
  };
  this.inProgressActionMenu = {
    event: 'click',
    items: [
      {
        label: 'Test öffnen',
        action: this.openTest.bind(this),
        icon: 'icon-qr'
      },
      {
        label: 'Termin öffnen',
        action: this.openAppointment.bind(this),
        icon: 'icon-calendar'
      },
      {
        label: 'Einverständniserklärung',
        action: this.openDSGVO.bind(this),
        icon: 'icon-note'
      },
      {
        label: 'Laufzettel',
        action: this.openTestInfo.bind(this),
        icon: 'icon-note',
        seperator: true
      },
      {
        label: gettextCatalog.getString('Cancel'),
        action: angular.noop,
      },
    ]
  };
  this.doneActionMenu = {
    event: 'click',
    items: [
      {
        label: 'Test öffnen',
        action: this.openTest.bind(this),
        icon: 'icon-qr',
      },
      {
        label: 'Termin öffnen',
        action: this.openAppointment.bind(this),
        icon: 'icon-calendar'
      },
      {
        label: 'Einverständniserklärung',
        action: this.openDSGVO.bind(this),
        icon: 'icon-note'
      },
      {
        label: 'Laufzettel',
        action: this.openTestInfo.bind(this),
        icon: 'icon-note'
      },
      {
        label: 'Bescheinigung öffnen',
        action: this.openDocument.bind(this),
        icon: 'icon-note',
        seperator: true
      },
      {
        label: gettextCatalog.getString('Cancel'),
        action: angular.noop,
      },
    ]
  };

  if (this.openTab === 'inProgress') {
    this.startTimer();
  } else {
    this.stopTimer();
  }

  if ($stateParams.testId && $stateParams.testId.match(/^[a-f\d]{24}$/i)) {
    this.openTestModal($stateParams.testId);
    $state.go('private.main.testManagement', { openTab: this.openTab, testId: null, customerId: null });
  } else if ($stateParams.customerId && $stateParams.customerId.match(/^[a-f\d]{24}$/i)) {
    this.openNewAppointment($stateParams.customerId);
    $state.go('private.main.testManagement', { openTab: this.openTab, testId: null, customerId: null });
  }

  $scope.$watch(
    () => {
      return this.filter;
    },
    (newValue, oldValue) => {
      this.filteredAppointments = this.appointments.filter((appointment) => {
        let filterOk = true;

        if (this.filter.testId) {
          filterOk = appointment.numericId && appointment.numericId.toString().includes(this.filter.testId);
        }

        if (this.filter.testType && this.filter.testType !== 'none') {
          filterOk = appointment.testType && appointment.testType === this.filter.testType;
        }

        if (this.filter.result && this.filter.result !== 'none') {
          filterOk = appointment.result && appointment.result === this.filter.result;
        }

        if (this.filter.customer) {
          filterOk = appointment.customer &&
            appointment.customer.fullname &&
            appointment.customer.fullname.toLowerCase().includes(this.filter.customer.toLowerCase());
        }

        return filterOk;
      });
    },
    true);
};

TestManagementCtrl.prototype.openDSGVO = function (event, originalEvent, eventData) {
  const pcr = eventData.testType === 'quickTest' ? 0 : 1;
  window.open(`/api/v2/document/create/covid/dsgvo?customerId=${eventData.customer._id}&pcr=${pcr}`);
};

TestManagementCtrl.prototype.openTestInfo = function (event, originalEvent, eventData) {
  window.open(`/api/v2/document/create/covid/testInfo?testId=${eventData.appointmentId}`);
};

TestManagementCtrl.prototype.stopTimer = function () {
  if (this.timer) {
    this._timeout.cancel(this.timer);
    this.timer = undefined;
  }
};

TestManagementCtrl.prototype.startTimer = function () {
  this.timer = this._timeout(function () {
    this.checkTimeLeft();
  }.bind(this), 1000);
};

TestManagementCtrl.prototype.checkTimeLeft = function () {
  const now = moment().subtract(this.timeOffset, 'ms');

  if (!this.appointments) {
    return;
  }

  this.appointments = this.appointments.map((appointment) => {
    const testStarted = moment(appointment.testStarted);
    const diff = now.diff(testStarted);
    const isOver = diff > 900000;
    appointment.timeLeft = moment.utc(diff).format('HH:mm:ss');
    appointment.isOver = isOver;
    return appointment;
  });

  this.timer = this.timer = this._timeout(function () {
    this.checkTimeLeft();
  }.bind(this), 1000);
};

TestManagementCtrl.prototype.switchTab = function (tab) {
  if (!this._stateParams.date) {
    if (tab === 'upcoming') {
      this.loadUpcoming();
    } else if (tab === 'inProgress') {
      this.loadInProgress();
    } else {
      this.loadDone();
    }
  }

  this.filter = {
    testId: '',
    testType: 'none',
    result: 'none',
    customer: ''
  };

  this.openTab = tab;
  this._state.transitionTo('private.main.testManagement', {
    openTab: tab,
  }, { notify: false, reloadOnSearch: false });
};

TestManagementCtrl.prototype.openNewAppointment = function (customerId) {
  const serviceGroups = this.office.serviceGroups.filter(serviceGroup => !serviceGroup.intern && serviceGroup.services.length);
  const preselectedService = serviceGroups ? serviceGroups[0].services[0] : null;
  const roundedUp = Math.ceil(moment().minute() / 5) * 5;
  const start = moment().minute(roundedUp).second(0).toDate();
  const duration = preselectedService ? preselectedService.duration : 5;
  const cachedCustomer = customerId ? this._CustomerDA.getFromCache(customerId) : null;
  const appointment = {
    start: start,
    end: moment(start).add(duration, 'minutes').toDate()
  };
  let serviceCount = 0;

  serviceGroups.forEach((serviceGroup) => {
    serviceCount = serviceCount + serviceGroup.services.length;
  });

  if (preselectedService && serviceCount === 1) {
    appointment.services = [{
      _id: preselectedService._id,
      name: preselectedService.name,
      duration: preselectedService.duration,
      price: preselectedService.price,
      shorthand: preselectedService.shorthand
    }];
  }

  if (cachedCustomer) {
    appointment.customer = {
      _id: cachedCustomer._id,
      firstname: cachedCustomer.firstname,
      lastname: cachedCustomer.lastname,
      fullname: cachedCustomer.fullname
    };
  }

  this._ModalService.openAppointmentModal(
    appointment,
    'appointment',
    () => {
      if (this.openTab === 'upcoming') {
        this.loadUpcoming();
      } else if (this.openTab === 'inProgress') {
        this.loadInProgress();
      } else if (this.openTab === 'done') {
        this.loadDone();
      }
    }
  );
};


TestManagementCtrl.prototype.openAppointment = function (event, originalEvent, eventData) {
  if (eventData.appointmentId) {
    this.openAppointmentModal(eventData.appointmentId);
  }
};


TestManagementCtrl.prototype.openAppointmentModal = function (appointmentId) {
  this._ModalService.openAppointmentModal(
    { _id: appointmentId },
    'appointment',
    () => {
      if (this.openTab === 'upcoming') {
        this.loadUpcoming();
      } else if (this.openTab === 'inProgress') {
        this.loadInProgress();
      } else if (this.openTab === 'done') {
        this.loadDone();
      }
    }
  );
};


TestManagementCtrl.prototype.openTest = function (event, originalEvent, eventData) {
  if (eventData.appointmentId) {
    this.openTestModal(eventData.appointmentId);
  }
};

TestManagementCtrl.prototype.openTestModal = function (testId) {
  this._ModalService.openTestModal(
    testId,
    () => {
      if (this.openTab === 'upcoming') {
        this.loadUpcoming();
      } else if (this.openTab === 'inProgress') {
        this.loadInProgress();
      } else if (this.openTab === 'done') {
        this.loadDone();
      }
    }
  );
};

TestManagementCtrl.prototype.openDocument = function (event, originalEvent, eventData) {
  if (eventData.document) {
    window.open(`/api/v2/document/${eventData.document}?original=true`);
  }
};

TestManagementCtrl.prototype.startTest = function (event, originalEvent, eventData) {
  if (eventData.appointmentId) {
    if (eventData.testType === 'pcrLabor') {
      this._TestDA
        .finishLaborTest(eventData.appointmentId)
        .then((response) => {
          this.appointments = this.appointments.filter(appointment => appointment.appointmentId !== response._id);
          this.filteredAppointments = this.filteredAppointments.filter(appointment => appointment.appointmentId !== response._id);
          this._Notification.addSuccessMessage('Test wurde abgeschlossen');
        });
    } else {
      this._TestDA
        .start(eventData.appointmentId)
        .then((response) => {
          this.appointments = this.appointments.filter(appointment => appointment.appointmentId !== response._id);
          this.filteredAppointments = this.filteredAppointments.filter(appointment => appointment.appointmentId !== response._id);
          this._Notification.addSuccessMessage('Test wurde gestartet');
        });
    }
  }
};

TestManagementCtrl.prototype.loadUpcoming = function () {
  var date = this._stateParams.date ? this._stateParams.date : moment().format('YYYY-MM-DD');
  this.stopTimer();
  this._LoadingIndicatorService.show();
  this._TestDA
    .getUpcoming(date)
    .then((response) => {
      this.appointments = response.appointments;
      this.filteredAppointments = this.appointments;
      this.timeOffset = moment().diff(response.now);
      this.counts = response.counts;
      this._LoadingIndicatorService.hide();
    });
};

TestManagementCtrl.prototype.loadInProgress = function () {
  this._LoadingIndicatorService.show();
  this._TestDA
    .getInProgress()
    .then((response) => {
      this.appointments = response.appointments;
      this.filteredAppointments = this.appointments;
      this.timeOffset = moment().diff(response.now);
      this.counts = response.counts;
      this._LoadingIndicatorService.hide();
      this.startTimer();
    });
};

TestManagementCtrl.prototype.loadDone = function () {
  var date = this._stateParams.date ? this._stateParams.date : moment().format('YYYY-MM-DD');
  this._LoadingIndicatorService.show();
  this.stopTimer();
  this._TestDA
    .getDone(date)
    .then((response) => {
      this.appointments = response.appointments;
      this.filteredAppointments = this.appointments;
      this.timeOffset = moment().diff(response.now);
      this.counts = response.counts;
      this.stats = response.stats;
      this._LoadingIndicatorService.hide();
    });
};

TestManagementCtrl.prototype.getStartDate = function (date) {
  if (moment(date).isSame(new Date(), 'day')) {
    return moment(date).format('HH:mm');
  } else if (moment(date).isSame(moment().subtract(1, 'day'), 'day')) {
    return `gestern, ${moment(date).format('HH:mm')}`;
  }

  return moment(date).format('DD.MM.YYYY, HH:mm');
};

TestManagementCtrl.prototype.openScanQRModal = function () {
  this._ModalService.openScanQRModal();
};

TestManagementCtrl.prototype.changeDate = function (days) {
  this.date = moment(this.date).add(days, 'day').toDate();
  this._state.transitionTo('private.main.testManagement', {
    date: moment(this.date).format('YYYY-MM-DD'),
    openTab: this.openTab
  });
}

module.exports = TestManagementCtrl;
