let angularModule = angular.module('t2g.common.data-access.trash', []);

module.exports = angularModule;

angularModule.factory('TrashResource', function ($resource, settings) {
  const resourceUrl = settings.apiBase + '/api/v2/trash/:itemId/:action';
  const resource = $resource(resourceUrl, {}, {
    list: { method: 'GET', isArray: false },
    remove: { method: 'DELETE', isArray: false },
    removeAll: { method: 'DELETE', isArray: false },
    restore: { method: 'GET', isArray: false, params: {action: 'restore'} }
  });

  return resource;
});

angularModule.factory('TrashDA', function (TrashResource) {
  return {
    list,
    remove,
    removeAll,
    restore
  };

  function list(filter) {
    return TrashResource
      .list(filter)
      .$promise
      .then((response) => {
        return response.items;
      });
  }

  function remove(itemId) {
    return TrashResource
      .remove({subResource1: itemId})
      .$promise
      .then((response) => {
        return response.success;
      });
  }

  function removeAll() {
    return TrashResource
      .removeAll()
      .$promise
      .then((response) => {
        return response.success;
      });
  }

  function restore(itemId) {
    return TrashResource
      .restore({itemId})
      .$promise
      .then((response) => {
        return response.success;
      });
  }
});

