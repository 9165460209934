module.exports = function() {

    const settings = {
        hourHeight: 72,
        offset: 7,
        end: 23
    };

    return {
        set: set,
        get: get
    };

    function set(key, value) {
        settings[key] = value;
    }

    function get(key) {
        return settings[key];
    }

};
