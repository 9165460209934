'use strict';

var angularModule = angular.module('t2g.common.services.sessionStorage', [
    'LocalStorageModule'
]);

module.exports = angularModule;

angularModule.config(function (localStorageServiceProvider) {
    localStorageServiceProvider
      .setPrefix('t2g')
      .setNotify(true, true);
});

angularModule.factory('LocalStorage', function ($window,
                                                localStorageService) {

    return {
        get: get,
        set: set,
        remove
    };

    function get(key) {
        return localStorageService.get(key);
    }

    function set(key, value) {
        localStorageService.set(key, value);
    }

    function remove(key) {
        localStorageService.remove(key);
    }
});


