'use strict';

var angularModule = angular.module('t2g.common.directives.wysiwygDirective', []);

module.exports = angularModule;

angularModule.directive('wysiwyg', function ($timeout, gettextCatalog) {
    return {
        restrict: 'A',
        require: '?ngModel',
        scope: {
            ngModel: '=',
            toolbar: '=',
            placeholder: '=',
            disableInput: '=',
            height: '='
        },
        link: function ($scope, $element, $attributes, ngModel) {
            var height = $scope.height || 300;
            var placeholderButtonLabel = gettextCatalog.getString('Placeholder');
            var summernoteOptions = {
                height: height,
                lang: 'de-DE',
                callbacks: {
                    onChange: function () {
                        if (ngModel) {
                            $timeout(function () {
                                ngModel.$setViewValue($element.summernote('code'));
                            });
                        }
                    }
                }
            };

            var PlaceholderDropdown = function (context) {
                var ui = $.summernote.ui;
                var dropdownList = '';

                if (_.isArray($scope.placeholder)) {
                    _.forEach($scope.placeholder, function (item) {
                        if(item.isHTML) {
                            dropdownList += '<li><a href="#" data-value="' + item.value + '" data-is-html="1">' + item.label + '</a></li>';
                        } else {
                            dropdownList += '<li><a href="#" data-value="' + item.value + '" data-is-html="0">' + item.label + '</a></li>';
                        }
                    });
                }

                var templateButton = ui.buttonGroup([
                    ui.button({
                        className: 'dropdown-toggle',
                        contents: placeholderButtonLabel + ' <span class="caret"></span>',
                        tooltip: 'Your tooltip',
                        click: function () {
                            // context.invoke('editor.saveRange');
                        },
                        data: {
                            toggle: 'dropdown'
                        }
                    }),
                    ui.dropdown({
                        className: 'dropdown-menu note-check',
                        contents: dropdownList,
                        callback: function ($dropdown) {
                            $dropdown.find('a').click(function (event) {
                                console.log($(this));
                                console.log($(this).data('value'));
                                console.log('*********************');
                                if($(this).data('is-html')) {
                                    context.invoke('editor.insertText', '{{{' + $(this).data('value') + '}}}');
                                } else {
                                    context.invoke('editor.insertText', '{{' + $(this).data('value') + '}}');
                                }
                                event.preventDefault();
                                event.stopPropagation();
                            });
                        }
                    })
                ]);

                return templateButton.render();
            };

            if ($scope.toolbar === 'nano') {
                summernoteOptions.toolbar = [
                    ['font', ['bold', 'italic', 'underline', 'clear']],
                    ['para', ['ul', 'ol']],
                    ['link', ['linkDialogShow', 'unlink']],
                    ['view', ['fullscreen']]
                ];
            } else if ($scope.toolbar === 'template') {
                summernoteOptions.toolbar = [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['fontsize', ['fontsize']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['link', ['linkDialogShow', 'unlink']],
                    ['mybutton', ['placeholder']]
                ];
                summernoteOptions.buttons = {
                    placeholder: PlaceholderDropdown
                };
            }

            $element.summernote(summernoteOptions);

            if ($scope.disableInput) {
                $element.summernote('disable');
            }

            // model -> summernote
            var modelObserver = $scope.$watch('ngModel', function () {
                $element.summernote('code', ngModel.$modelValue);
                modelObserver();
            });

            // GC
            $scope.$on('$destroy', function () {
                $element.summernote('destroy');
            });

        }
    };
})
;
