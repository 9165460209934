let angularModule = angular.module('t2g.common.data-access.document', []);

module.exports = angularModule;

angularModule.factory('DocumentResource', function ($resource, settings) {
  const resourceUrl = settings.apiBase + '/api/v2/document/create';
  const resource = $resource(resourceUrl, {}, {
    create: { method: 'POST' },
  });

  return resource;
});

angularModule.factory('DocumentDA', function (DocumentResource) {
  return {
    create
  };

  function create(
    appointmentId,
    sendToCustomer,
    negative,
    sendDocumentToHealthDepartment,
    selectedTest,
    checks,
    selfPay,
    updateAppointment,
    newEmployeeId,
    testType,
    meta
  ) {
    return DocumentResource
      .create({
        appointment: appointmentId,
        sendToCustomer,
        negative,
        sendDocumentToHealthDepartment,
        selectedTest,
        checks,
        selfPay,
        updateAppointment,
        newEmployeeId,
        testType,
        testReason: meta.testReason,
        testReason2: meta.testReason2,
        stayReason: meta.stayReason,
        facilityType: meta.facilityType,
        chargennummer: meta.chargennummer,
        testId: meta.testId,
        ct: meta.ct
      })
      .$promise
      .then(response => response.document);
  }
});

