'use strict';

var angularModule = angular.module('t2g.common.directives.menu', []);

module.exports = angularModule;

angularModule.run(function($templateCache) {
    $templateCache.put('MenuPartial.html', '<div class="menu">' +
        '<ul class="menu-items">' +
        '<li class="menu-item" data-ng-repeat="item in items" data-ng-class="{\'menu-item--seperator\': item.seperator}">' +
        '<button class="menu-item__action waves" type="button" data-ng-click="invokeAction($event, item)">' +
        '<span class="menu-item__action__icon" data-ng-class="item.icon"></span>' +
        '{{item.label}}' +
        '</button>' +
        '</li>' +
        '</ul>' +
        '</div>');
});

angularModule.factory('menuState', function() {
    var currentMenu = null;

    return {
        setCurrentMenu: function(menu) {
            currentMenu = menu;
        },
        getCurrentMenu: function() {
            return currentMenu;
        }
    };
});

angularModule.directive('menu', function($templateCache,
                                         $timeout,
                                         $compile,
                                         $document,
                                         $rootScope,
                                         menuState,
                                         AppointmentDragAndResizeState) {

    return {
        restrict: 'A',
        link: function($scope, $element, $attributes) {
            var menuTemplate = $templateCache.get('MenuPartial.html');
            var menuElement;
            var body = document.querySelector('body');
            var options = $scope.$eval($attributes.menu);
            var metaData = $scope.$eval($attributes.menuMetaData);
            var isOpen = false;
            var originalEvent = null;
            var offset = 30;
            $scope.items = options.items;

            $scope.invokeAction = function($event, item) {
                if (item.action) {
                    item.action($event, originalEvent, metaData);
                }
                $scope.removeMenu();
                $event.stopPropagation();
            };

            // send event to close all open context menus
            $document.on('click contextmenu', function(event) {
                if (!event.target.matches('.menu-item__action')) {
                    closeOpenMenu();
                }
            });

            $element.on(options.event, function(event) {
                if (!isOpen) {
                    closeOpenMenu();
                    originalEvent = event;
                    showMenu(event);
                    event.preventDefault();
                    event.stopPropagation();
                }
            });

            // clean up listeners
            $scope.$on('$destroy', function() {
                $scope.removeMenu();
                $element.off(options.event);
                $document.off('contextmenu');
            });

            $scope.removeMenu = function() {
                if (menuElement) {
                    menuElement.remove();
                    isOpen = false;
                    menuState.setCurrentMenu(null);
                }
            };

            function closeOpenMenu() {
                var currentMenu = menuState.getCurrentMenu();

                if (currentMenu) {
                    currentMenu.removeMenu();
                }
            }

            /**
             * show context menu at given position
             * @param {Number} x
             * @param {Number} y
             */
            function showMenu(event) {
                var menuSize;
                var x;
                var y;
                var distanceX;
                var distanceY;
                var menuHeight;
                var menuWidth;
                var left;
                var top;

                if(!AppointmentDragAndResizeState.isActive()){
                    // check opening direction
                    menuElement = $compile(menuTemplate)($scope);
                    $scope.$digest();
                    body.appendChild(menuElement[0]);
                    menuSize = menuElement[0].getBoundingClientRect();

                    x = event.clientX;
                    y = event.clientY;
                    menuWidth = menuSize.width;
                    menuHeight = menuSize.height;
                    distanceX = window.innerWidth - x - offset;
                    distanceY = window.innerHeight - y - offset;

                    if(distanceX < menuWidth){
                        left = x - menuWidth;
                    } else {
                        left = x;
                    }

                    if(distanceY < menuHeight){
                        top = y - menuHeight;
                    } else {
                        top = y;
                    }

                    menuElement.css('left', left + 'px');
                    menuElement.css('top', top + 'px');


                    isOpen = true;
                    menuState.setCurrentMenu($scope);
                }
            }
        }
    };
});

