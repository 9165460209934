'use strict';

var CustomerMergeCtrl = function ($state,
                                  CustomerDA,
                                  customers,
                                  office,
                                  gettextCatalog,
                                  Notification) {

    var self = this;
    var fieldNames = [
        'firstname',
        'lastname',
        'telephone',
        'email',
        'street',
        'no',
        'postal',
        'city',
        'note'
    ];
    this._CustomerDA = CustomerDA;
    this.customers = customers;
    this.office = office;
    this._state = $state;
    this._Notification = Notification;
    this.gettextCatalog = gettextCatalog;
    this.isMerging = false;
    this.newCustomer = angular.copy(this.customers[0]);
    this.newCustomer.customFields = this.newCustomer.customFields || {};
    delete this.newCustomer._id;
    this.selectedFields = {
        customFields: {}
    };

    if (office.settings.customFields) {
        _.forEach(office.settings.customFields.customer, function (field) {
            self.selectedFields.customFields[field.name] = 0;
        });
    }

    _.forEach(fieldNames, function (fieldName) {
        self.selectedFields[fieldName] = 0;
    });
};

CustomerMergeCtrl.prototype.selectField = function (index, fieldName, isCustomField) {
    if (!isCustomField) {
        this.selectedFields[fieldName] = index;
        this.newCustomer[fieldName] = this.customers[index][fieldName];
    } else {
        this.selectedFields.customFields[fieldName] = index;

        if (this.customers[index].customFields) {
            this.newCustomer.customFields[fieldName] = this.customers[index].customFields[fieldName];
        } else {
            delete this.newCustomer.customFields[fieldName];
        }
    }
};

CustomerMergeCtrl.prototype.isSelected = function (index, fieldName, isCustomField) {
    if (!isCustomField) {
        return this.selectedFields[fieldName] === index;
    } else {
        return this.selectedFields.customFields[fieldName] === index;
    }
};

CustomerMergeCtrl.prototype.merge = function () {
    var self = this;
    var customerIds = [];

    _.forEach(this.customers, function(customer){
        if(customer._id){
            customerIds.push(customer._id);
        }
    });

    this.isMerging = true;

    this._CustomerDA.merge({
        customerIds: customerIds,
        newCustomer: this.newCustomer
    }).then(function(){
        self._Notification.addSuccessMessage(self.gettextCatalog.getString('Customers merged', {name: name}));
        self._state.go('private.main.customer');
        self.isMerging = true;
    }).catch(function(){
        self.isMerging = false;
        self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant merge customer'));
    });

};

CustomerMergeCtrl.prototype.abort = function () {
    this._state.go('private.main.customer');
};

CustomerMergeCtrl.prototype.skip= function (customerId) {
    this.customers = _.remove(this.customers, {_id: customerId});
};



module.exports = CustomerMergeCtrl;
