var angularModule = angular.module('t2g.common.directives.tablinkDirective', []);

module.exports = angularModule;

angularModule.directive('tablink', function () {
    return {
        restrict: 'A',
        link: function ($scope, $element) {
            $element.click(function (e) {
                e.preventDefault();
                $(this).tab('show');
            });
        }
    }
});
