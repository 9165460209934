module.exports = function ($rootScope,
                           $scope,
                           $state,
                           gettextCatalog,
                           Notification,
                           officeResolve,
                           OfficeDA,
                           ServiceGroupModal,
                           ModalService) {

  $scope.groupfilter = '';
  $scope.office = officeResolve;
  $scope.showPremiumNotification = false;

  function checkPremium(serviceGroups) {
    $scope.serviceLen = 0;

    _.forEach(serviceGroups, function (serviceGroup) {
      $scope.serviceLen += serviceGroup.services.length;
    });

    $scope.showPremiumNotification = ($scope.subscription.onlyBasicFunctions && $scope.serviceLen >= 3);
  }

  $scope.$watch('office.serviceGroups', function (newValue) {
    checkPremium(newValue);
  });

  $scope.getServiceCount = function () {
    var count = 0;

    _.forEach($scope.office.serviceGroups, function (group) {
      count += group.services.length;
    });

    return count;
  };


  /**
   * set active class for group pill navigation (not shown at the moment)
   * @param group
   * @returns {string}
   */
  $scope.getActiveState = function (group) {
    return group === $scope.groupfilter ? 'active' : '';
  };


  /**
   * filter the services by clicking on the groups and set the filter for the ng-repeat (not shown at the moment)
   * @param filter
   */
  $scope.setFilter = function (filter) {
    $scope.groupfilter = filter;
  };


  /**
   *@param service
   */
  $scope.openServiceModal = function (service) {
    ModalService.openServiceModal(service);
  };


  /**
   * navigato to /servicesEmployeeMatch
   */
  $scope.navigateToServiceEmployeeMatch = function () {
    $state.go('private.main.serviceResourceMatch');
  };


  /**
   * open the service group modal
   */
  $scope.openServiceGroupModal = function () {
    ServiceGroupModal.open();
  };


  /**
   * save to database
   */
  $scope.save = function () {
    OfficeDA
      .update($scope.office)
      .then(() => {
        Notification.addSuccessMessage(gettextCatalog.getString('service edited'));
      })
      .catch((error) => {
        Notification.addErrorMessage({ text: error });
      });
  };


  $scope.moveUp = function (arr, index) {
    if (index > 0) {
      arr.move(index, (index - 1));
      $scope.save();
    }
  };

  $scope.moveDown = function (arr, index) {
    if (index < (arr.length - 1)) {
      arr.move(index, (index + 1));
      $scope.save();
    }
  };

  $scope.openServiceLocationModal = function () {
    ModalService.openServiceLocationModal(function () {});
  };


  var updateOffice = $rootScope.$on('Office:update', function (event, response) {
    $scope.office = response;
  });


  /**
   * remove event listener if day has changed (CalendarCtrl destroyed)
   */
  $scope.$on('$destroy', function () {
    updateOffice();
  });

  $scope.runTour = function (standalone) {
    if (typeof introJs === 'undefined') return;

    const intro = introJs();
    let steps = [
      {
        intro: gettextCatalog.getString('On this site you can manage services, service groups and allocate employees & rooms to services.')
      },
      {
        element: document.getElementById('btnAddServiceGroup'),
        intro: gettextCatalog.getString('If you offer multiple services it makes sense to separate them into groups. Start here with creating your first service groups.'),
        position: 'bottom'
      },
      {
        element: document.getElementById('btnAddService'),
        intro: gettextCatalog.getString('Click here to create a new service.'),
        position: 'bottom'
      },
      {
        element: document.getElementById('btnAddServiceEmployeeMatch'),
        intro: gettextCatalog.getString('If not all of your employees are bookable for any service you offer, you can allocate employees and rooms to the services here.'),
        position: 'bottom'
      },
      {
        element: document.querySelectorAll('.edit-icon')[ 0 ],
        intro: gettextCatalog.getString('To edit a service you have to click on the pencil icon.')
      },
      {
        element: document.querySelectorAll('.icon-change-order')[ 0 ],
        intro: gettextCatalog.getString('You can manage the order of the services with the up and down arrow.')
      }
    ];

    intro.setOptions({
      nextLabel: gettextCatalog.getString('next'),
      prevLabel: gettextCatalog.getString('back'),
      skipLabel: gettextCatalog.getString('skip'),
      doneLabel: gettextCatalog.getString('done'),
      exitOnOverlayClick: false,
      exitOnEsc: false,
      steps
    });

    intro.start();
  };
};
