var angularModule = angular.module('t2g.common.directives.onResize', []);

module.exports = angularModule;

angularModule.directive('onResize', function ($window) {
    return {
        link: function ($scope) {
            var wait = 250;

            function onResize() {
                $scope.$broadcast('t2g:window:resize');
            }

            function cleanUp() {
                angular.element($window).off('resize', onResize);
            }

            angular.element($window).on('resize', $window._.debounce(onResize, wait));

            $scope.$on('$destroy', cleanUp);
        }
    }
});
