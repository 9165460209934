var StatisticTestsCtrl = function ($scope,
                                   $state,
                                   $stateParams,
                                   Notification,
                                   stats,
                                   office,
                                   DatepickerSettings) {
  var self = this;
  this._Notification = Notification;
  this._state = $state;
  this.testTyp = $stateParams.testType;
  this.colors = this.testTyp === 'quickTestWithReason' ? [ '#1b5e20', '#f9a825', '#FF0000'] : [ '#1b5e20', '#b71c1c'];
  this.series = this.testTyp === 'quickTestWithReason' ? [
    '$4a 1 Satz 10 TestV - Angehörige von Infizierten (kostenfrei)',
    '$4a 1 Satz 9 TestV - Pflegende Angehörige sowie Menschen mit Behinderungen und deren Betreuungskräfte (kostenfrei)',
    '$4a 1 Satz 5 TestV - Besucher von Kliniken und Pflegeheimen (kostenfrei)',
    '$4a 1 Satz 2 TestV - Menschen die sich nicht impfen lassen können (kostenfrei)',
    '$4a 1 Satz 2 TestV - Frauen zu Beginn der Schwangerschaft (kostenfrei)',
    '$4a 1 Satz 1 TestV - Kinder bis fünf Jahre (kostenfrei)',
    '$4a 1 Satz 3 TestV - Teilnahme an klinischen Studien zu Medikamenten gegen COVID-19 (kostenfrei)',
    '$4a 1 Satz 4 TestV - Freitestung (kostenfrei)',
    '$4a 1 Satz 8 TestV - Leistungsberechtigte (kostenfrei)',
    '$4a 1 Satz 6 a) TestV - Veranstaltung in einem Innenraum (3€)',
    '$4a 1 Satz 6 aa) TestV - Besuch einer Person über 60 Jahre (3€)',
    '$4a 1 Satz 6 bb) TestV - Besuch einer Person mit hohen Risiko schwer zu erkranken (3€)',
    '$4a 1 Satz 7 TestV - Corona-Warn-App "erhöhtes Risiko" (3€)',
    `keine der genannten Gründe (${office.settings.covidTest.prices.rapid}€)`
  ] : ['Negativ', 'Positiv'];
  this.labels = stats.labels;
  this.data = stats.data;
  this.sum = stats.sum;
  this.dtpOptionsMonth = DatepickerSettings.minMonth;
  this.dtpOptionsMonthWithWeek = DatepickerSettings.minMonthWithWeek;
  this.dtpOptionsDay = DatepickerSettings.default;
  this.dtpOpened = false;
  this.date = $stateParams.date || moment().format('YYYY-MM-DD');
  this.dateObj = moment(this.date).toDate();
  this.resolution = $stateParams.resolution || 'month';
  this.currentYear = moment(this.dateObj).year();
  this.currentWeek = moment(this.dateObj).startOf('week').format('YYYY-MM-DD');
  this.years = [2021, 2022];
  this.weeks = [];


  for (var i = 1; i <= 52; i++) {
    let week =  moment().year(this.currentYear).week(i);
    this.weeks.push({
      date: week.startOf('week').format('YYYY-MM-DD'),
      label: `KW ${i} (${week.startOf('week').format('DD.MM.')} - ${week.endOf('week').format('DD.MM.YYYY')})`
    });
  }

  $scope.$watch(() => this.dateObj,
    (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.changeResolution();
      }
  });

  this.barChartOptions = {
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
          userCallback: function (label) {
            if (Math.floor(label) === label) {
              return label;
            }
          },
        }
      }]
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        afterTitle: function() {
          window.total = 0;
        },
        label: function(tooltipItem, data) {
          var corporation = data.datasets[tooltipItem.datasetIndex].label;
          var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          window.total += valor;
          return corporation + ": " + valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        footer: function() {
          return "Gesamt: " + window.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
      }
    }
  };
};

StatisticTestsCtrl.prototype.changeResolution = function () {
  this._state.go('private.main.statistic-tests', {
    date: moment(this.dateObj).format('YYYY-MM-DD'),
    resolution: this.resolution
  });
};

StatisticTestsCtrl.prototype.getColor = function (a, b, c) {
  return '#4CAF50';
};

StatisticTestsCtrl.prototype.yearChanged = function () {
  this.dateObj = moment().year(this.currentYear).startOf('year').toDate();
};

StatisticTestsCtrl.prototype.weekChanged = function () {
  this.dateObj = moment(this.currentWeek).toDate();
};

module.exports = StatisticTestsCtrl;
