'use strict';

var angularModule = angular.module('t2g.common.data-access.branches', []);

module.exports = angularModule;

angularModule.factory('BranchResource', function ($resource, settings) {
  var resourceUrl = settings.apiBase + '/api/v2/office/branches/:branchId';

  var resource = $resource(resourceUrl, {}, {
    insert: { method: 'POST', isArray: false },
    delete: { method: 'DELETE', isArray: false },
    updateCompanyGroupSettings: {method: 'PUT', isArray: false},
    getCompanyGroupSettings: {method: 'GET', params: {branchId: 'companyGroupSettings'}, isArray: false}
  });

  return resource;
});

angularModule.factory('BranchDA', function ($q,
                                            BranchResource,
                                            UserSession) {
  return {
    list: function () {
      let user = UserSession.get();

      return $q.when(user.branchNames);
    },
    insert: function (branchName) {
      return BranchResource
        .insert({ branchName })
        .$promise
        .then(function (response) {
          return response.office;
        });
    },
    delete: function (branchId) {
      return BranchResource
        .delete({ branchId })
        .$promise;
    },
    updateCompanyGroupSettings: function (update) {
      return BranchResource
        .updateCompanyGroupSettings(update)
        .$promise;
    },
    getCompanyGroupSettings: function () {
      return BranchResource
        .getCompanyGroupSettings()
        .$promise;
    }
  };
});
