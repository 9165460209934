'use strict';

var angularModule = angular.module('t2g.common.data-access', [
    require('./account').name,
    require('./accounting').name,
    require('./appointment').name,
    require('./branch').name,
    require('./booking').name,
    require('./countryConfig').name,
    require('./covidTest').name,
    require('./customer').name,
    require('./document').name,
    require('./google-sync').name,
    require('./industry').name,
    require('./mail').name,
    require('./marketing').name,
    require('./messages').name,
    require('./payment').name,
    require('./shiftPlanning').name,
    require('./statistic').name,
    require('./subscription').name,
    require('./sync').name,
    require('./resource').name,
    require('./office').name,
    require('./user').name,
    require('./trash').name,
    require('./test').name,
    require('./log').name
]);

module.exports = angularModule;
