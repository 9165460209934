module.exports = function(moment, gettextCatalog) {
    return {
        get: function(resource, shiftPlanning, countryConfig) {
            var shiftPlanningDates = shiftPlanning.dates;
            var start = moment(shiftPlanning.month);
            var end = moment(start).endOf('month');
            var dates = {};
            var datesList;
            var info;

            for (; start.isBefore(end); start.add(1, 'days')) {
                var dateKey = start.format('YYYY-MM-DD');
                var dateKeyShort = start.format('MM-DD');
                var year = start.format('YYYY');
                var dayOfWeek = start.weekday();
                var isWeekend = (dayOfWeek === 5 || dayOfWeek === 6);
                var shiftPlanningDate = shiftPlanningDates[dateKey];
                var holiday = _.get(countryConfig, 'holidays.' + year + '.' + dateKeyShort);

                if(start.isSame(new Date(), 'day')){
                    info = gettextCatalog.getString('Today');
                } else if(holiday) {
                    info = holiday.name;
                } else {
                    info = undefined;
                }

                if (shiftPlanningDate) {
                    shiftPlanningDate.isWeekend = isWeekend;
                    dates[dateKey] = shiftPlanningDate;
                } else {
                    var workingHoursOfDay = resource.workingHours[dayOfWeek];

                    if(workingHoursOfDay){
                        dates[dateKey] = {
                            available: workingHoursOfDay.available,
                            times: _.cloneDeep(workingHoursOfDay.times),
                            type: 'default',
                            date: start.toDate(),
                            isWeekend: isWeekend,
                            info: info
                        };
                    } else {
                        dates[dateKey] = {
                            available: false,
                            times: [{}],
                            type: 'default',
                            date: start.toDate(),
                            isWeekend: isWeekend,
                            info: info
                        };
                    }
                }
            }

            datesList = _.sortBy(_.values(dates), 'date');

            return datesList;
        }
    };
};
