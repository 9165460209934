'use strict';

var angularModule = angular.module('t2g.common.data-access.sync', []);

module.exports = angularModule;

angularModule.factory('SyncResource', function ($resource, settings) {
    var resourceUrl = settings.apiBase + '/api/v2/sync/:resource/:sync';

    var resource = $resource(resourceUrl, {}, {
        insert: {method: 'POST'},
        update: {method: 'PUT', params: {resource: '@_id'}},
        get: {method: 'GET', isArray: false},
        delete: {method: 'delete'}
    });

    return resource;
});

angularModule.factory('SyncDA', function (SyncResource) {

    return {
        get: function (resource) {
            return SyncResource.get({resource: resource}).$promise;
        },
        insert: function (resource, sync) {
            return SyncResource.insert({resource: resource}, sync).$promise;
        },
        delete: function (resource, sync) {
            return SyncResource.delete({resource: resource, sync: sync}).$promise;
        }
    };
});

