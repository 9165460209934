var angularModule = angular.module('t2g.common.directives.timePickerDirective', []);

module.exports = angularModule;

angularModule.directive('timepicker', function TimePickerDirective($filter) {
  return {
    restrict: 'E',
    require: '?ngModel',
    replace: true,
    scope: {
      ngModel: '=model',
      start: '@',
      prepend: '@',
      end: '@',
      step: '@',
      disabled: '=',
      dateTime: '='
    },
    template: '<select class="form-control control input-small" ng-options="t.value as t.label for t in times" data-ng-model="ngModel" data-ng-disabled="disabled"></select>',
    link: function ($scope, $attributes, $element, $ngModel) {
      const parsedStep = parseInt($scope.step, 10);
      const parsedStart = parseInt($scope.start, 10);
      const parsedEnd = parseInt($scope.end, 10);

      const step = !isNaN(parsedStep) ? parsedStep : 15;
      const start = !isNaN(parsedStart) ? parsedStart : 360;
      const end = !isNaN(parsedEnd) ? parsedEnd : 1320;

      $scope.times = [];

      if ($scope.prepend) {
        $scope.prepend
          .split(',')
          .forEach(function (minutesOfday) {
            let hours = Math.floor(minutesOfday / 60);
            let minutes = minutesOfday - hours * 60;

            $scope.times.push({
              label: ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2),
              value: parseInt(minutesOfday)
            });
          });
      }

      for (let minutesOfday = start; minutesOfday <= end; minutesOfday = minutesOfday + step) {
        let hours = Math.floor(minutesOfday / 60);
        let minutes = minutesOfday - hours * 60;

        $scope.times.push({
          label: ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2),
          value: minutesOfday
        });
      }

      if ($ngModel) {
        $ngModel.$formatters.push(function (model) {
          var view;

          if ($scope.dateTime) {
            view = moment(model).hours() * 60 + moment(model).minutes();
          } else {
            view = model;
          }

          // // check if value exists, otherwhise add
          // const timeIndex = $scope.times.findIndex(time => time.value > view);
          //
          // if (timeIndex > -1 && $scope.times[timeIndex].value !== view) {
          //   let hours = Math.floor(view / 60);
          //   let minutes = view - hours * 60;
          //
          //   $scope.times.splice(timeIndex, 0, {
          //     label: ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2),
          //     value: parseInt(view)
          //   });
          // }

          return view;
        });

        // viewValue -> modelValue
        $ngModel.$parsers.push(function (view) {
          if ($scope.dateTime) {
            const hours = Math.floor(view / 60);
            const minutes = view - hours * 60;
            const newDateTime = $scope.ngModel;

            newDateTime.setHours(hours);
            newDateTime.setMinutes(minutes);

            return newDateTime;
          } else {
            return view;
          }
        });
      }
    }
  };
});
