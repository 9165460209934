'use strict';

var angularModule = angular.module('t2g.common.data-access.accounting', []);

module.exports = angularModule;

angularModule.factory('AccountingResource', function ($resource, settings) {
  var resourceUrl = settings.apiBase + '/api/v2/accounting/:subResource0/:subResource1';

  var resource = $resource(resourceUrl, {}, {
    openDay: { method: 'POST', isArray: false, params: { subResource0: 'openDay' } },
    createInvoice: { method: 'POST', isArray: false, params: { subResource0: 'invoice' } },
    createCashBookEntry: { method: 'POST', isArray: false, params: { subResource0: 'cashBook' } },
    getForCustomer: { method: 'GET', isArray: false, params: { subResource0: 'customer' } },
    cashBookEntry: { method: 'GET', isArray: false, params: { subResource0: 'cashBook' } },
    cancelCashBookEntry: { method: 'DELETE', params: { subResource0: 'cashBookEntry', subResource1: '@_id' } },
    updateCashBookEntry: { method: 'PUT', params: { subResource0: 'cashBookEntry', subResource1: '@_id' } },
    getHelloCashCheckoutLink: { method: 'POST', params: { subResource0: 'helloCashCheckoutLink'} },
    connectToHelloCash: { method: 'POST', params: { subResource0: 'connectToHelloCash'} },
    disconnectHelloCash: { method: 'POST', params: { subResource0: 'disconnectHelloCash'} },
  });

  return resource;
});

angularModule.factory('AccountingDA', function (AccountingResource) {
  return {
    getHelloCashCheckoutLink,
    connectToHelloCash,
    disconnectHelloCash,
    openDay,
    createInvoice,
    getForCustomer,
    createCashBookEntry,
    getCashBook,
    updateCashBookEntry,
    cancelCashBookEntry
  };

  function getHelloCashCheckoutLink(data) {
    return AccountingResource
      .getHelloCashCheckoutLink(data)
      .$promise
      .then(response => response.link);
  }

  function connectToHelloCash(username, password) {
    return AccountingResource
      .connectToHelloCash({username, password})
      .$promise
      .then(response => response.data);
  }

  function disconnectHelloCash() {
    return AccountingResource
      .disconnectHelloCash({})
      .$promise
      .then(response => response.data);
  }

  function openDay(amount, note) {
    return AccountingResource
      .openDay({amount, note})
      .$promise
      .then((response) => {
        return response.cashBook;
      });
  }

  function cancelCashBookEntry(cashBookEntryId) {
    return AccountingResource
      .cancelCashBookEntry(cashBookEntryId)
      .$promise;
  }

  function updateCashBookEntry(cashBookEntry) {
    return AccountingResource
      .updateCashBookEntry(cashBookEntry)
      .$promise
      .then(function (response) {
        return response.cashBookEntry;
      });
  }

  function getCashBook(date) {
    return AccountingResource
      .cashBookEntry({ date: date })
      .$promise
      .then(function (response) {
        return response.cashBook;
      });
  }

  function getForCustomer(customerId) {
    return AccountingResource
      .getForCustomer({ subResource1: customerId })
      .$promise
      .then(function (response) {
        return response.invoices;
      });
  }

  function createCashBookEntry(invoice) {
    return AccountingResource
      .createCashBookEntry(invoice)
      .$promise;
  }

  function createInvoice(invoice) {
    return AccountingResource
      .createInvoice(invoice)
      .$promise;
  }
});

