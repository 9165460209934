'use strict';

module.exports = {
  load: function () {
    setTimeout(function () {
      if (window.zE) {
        zE(function () {
          $zopim(function () {
            $zopim.livechat.hideAll();
            $zopim.livechat.addTags('app.termin2go.com');
            $zopim.livechat.button.setPosition('bl');
            $zopim.livechat.window.onHide(() => {
              $zopim.livechat.button.hide();
            });
          });
        });
      }
    });
  }
};
