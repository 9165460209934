'use strict';

var angularModule = angular.module('t2g.common.services.industrySpecificService', []);

module.exports = angularModule;

angularModule.factory('IndustrySpecific', function ($q,
                                                    OfficeDA) {
    
    var _labels = null;
    var defaultLabels = {
        customer: 'Customer',
        customers: 'Customers'
    };
    
    return {
        setLabels: function (labels) {
            _labels = labels;
        },
        getLabels: function () {
            if (_labels) {
                return $q.when(_labels);
            } else {
                return OfficeDA
                    .get()
                    .then(function (office) {
                        var customLabels = _.get(office, 'settings.customLabels', {});
                        
                        if (customLabels.customer && customLabels.customers) {
                            _labels = customLabels;
                            return _labels;
                        } else {
                            return defaultLabels;
                        }
                    });
            }
        }
    };
});

