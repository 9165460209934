'use strict';

var angularModule = angular.module('t2g.common.directives.google-places-autocomplete', []);

module.exports = angularModule;

angularModule.directive('googlePlacesAutocomplete', function () {

    return {
        require: 'ngModel',
        scope: {
            ngModel: '=',
            postal: '=',
            city: '=',
            country: '=',
            countryRestriction: '='
        },
        link: function (scope, element, attrs, model) {
            const googleResponseData = {
                street_number: '',
                route: '',
                locality: '',
                postal_code: '',
                country: '',
            };

            /*            setTimeout(() => {
                            scope.postal = '15537';
                            scope.city = 'HB';
                            scope.country = 'DE';

                            scope.$apply(function() {
                                model.$setViewValue('Tes 123');
                                model.$render();
                            });
                        }, 5000);*/

            if (window.google) {
                const gPlace = new google.maps.places.Autocomplete(element[0], {
                    componentRestrictions: {country: (scope.countryRestriction || ['de', 'at', 'ch', 'lu'])},
                    fields: ['address_components'],
                    strictBounds: false,
                });

                google.maps.event.addListener(gPlace, 'place_changed', function () {
                    const geoComponents = gPlace.getPlace();
                    const addressComponents = geoComponents.address_components;

                    addressComponents.forEach((addressComponent) => {
                        addressComponent.types.some((addressComponentType) => {
                            if (googleResponseData[addressComponentType] !== undefined) {
                                googleResponseData[addressComponentType] = addressComponent.short_name;
                                return true;
                            }
                        });
                    });

                    scope.postal = googleResponseData.postal_code;
                    scope.city = googleResponseData.locality;
                    scope.country = googleResponseData.country;

                    scope.$apply(function () {
                        model.$setViewValue(`${googleResponseData.route} ${googleResponseData.street_number}`);
                        model.$render();
                    });
                });
            }
        }
    };
});

