'use strict';

module.exports = function ($rootScope,
                           $scope,
                           $state,
                           $stateParams,
                           $window,
                           $timeout,
                           customer,
                           CustomerDA,
                           AccountingDA,
                           invoices,
                           DatepickerSettings,
                           filteredCustomers,
                           gettextCatalog,
                           IndustrySpecific,
                           ModalService,
                           Notification,
                           office,
                           LocalStorage,
                           resources,
                           services,
                           settings) {
  $scope.resources = resources;
  $scope.currentCustomer = customer;
  $scope.filteredCustomers = filteredCustomers;
  $scope.translationData = {
    name: ''
  };
  $scope.filter = {};
  $scope.dtpOptions = DatepickerSettings.default;
  $scope.dtpOptionsFilterFrom = angular.copy(DatepickerSettings.default);
  $scope.dtpOptionsFilterTo = angular.copy(DatepickerSettings.default);
  $scope.smsReminderEnabled = _.get(office, 'settings.reminder.sms.enabled');
  $scope.emailReminderEnabled = _.get(office, 'settings.reminder.email.enabled');
  $scope.settings = settings;
  $scope.invoiceFormSubmitted = false;
  $scope.CustomerInvoiceForm = {};
  $scope.invoices = invoices;
  $scope.showPremiumNotification = $scope.subscription.onlyBasicFunctions;
  $scope.overallDuration = 0;
  $scope.countryRestriction = _.get(office, 'address.countryCode', ['de', 'at', 'ch', 'lu']);

  $scope.isPackageIsSchnelltest = ['schnelltest', 'schnelltest2', 'schnelltest_archiv'].includes($scope.subscription.package);
  $scope.showLog = ['plus', 'enterprise'].includes($scope.subscription.package);

  function setMinMaxDate() {
    $scope.dtpOptionsFilterFrom.maxDate = $scope.filter.end;
    $scope.dtpOptionsFilterTo.minDate = $scope.filter.start;
  }

  $scope.$watch('filter', function () {
    setMinMaxDate();
  }, true);

  if ($scope.currentCustomer && $scope.currentCustomer.birthday) {
    $scope.currentCustomer.birthday = new Date($scope.currentCustomer.birthday);
  }

  if ($scope.currentCustomer.image) {
    $scope.imagePreview = $scope.currentCustomer.image.m ? $scope.currentCustomer.image.m : $scope.currentCustomer.image;
  }

  if ($stateParams.customerId && $stateParams.customerId === 'new') {
    $scope.smsReminderEnabled = _.get(office, 'settings.reminder.sms.enabled');
    $scope.emailReminderEnabled = _.get(office, 'settings.reminder.email.enabled');

    if ($scope.smsReminderEnabled) {
      $scope.currentCustomer.reminder = $scope.currentCustomer.reminder || {};
      $scope.currentCustomer.reminder.sms = true;
    }

    if ($scope.emailReminderEnabled) {
      $scope.currentCustomer.reminder = $scope.currentCustomer.reminder || {};
      $scope.currentCustomer.reminder.email = true;
    }

    if ($stateParams.egkData) {
      try {
        const egkData = JSON.parse($stateParams.egkData);
        $scope.currentCustomer.firstname = egkData.firstName;
        $scope.currentCustomer.lastname = egkData.lastName;
        $scope.currentCustomer.birthday = moment(egkData.dob, 'YYYYMMDD').hours(12).toDate(); // dob
        $scope.currentCustomer.city = egkData.city;
        $scope.currentCustomer.postal = egkData.zipCode;
        $scope.currentCustomer.street = `${egkData.street} ${egkData.houseNumber}`;

        if (egkData.sex && egkData.sex.toUpperCase() === 'M') {
          $scope.currentCustomer.salutation = 'm';
        } else if(egkData.sex && egkData.sex.toUpperCase() === 'W') {
          $scope.currentCustomer.salutation = 'f';
        }
        console.log(egkData);
      } catch(error) {
        console.error(error);
      }
    }
  }

  IndustrySpecific
    .getLabels()
    .then(function (labels) {
      $scope.customerLabel = gettextCatalog.getString(labels.customer.toLowerCase());
      $scope.customersLabel = gettextCatalog.getString(labels.customers.toLowerCase());
    });

  var generateName = function () {
    var nameParts = [];

    if ($scope.currentCustomer.firstname) {
      nameParts.push($scope.currentCustomer.firstname);
    }

    if ($scope.currentCustomer.lastname) {
      nameParts.push($scope.currentCustomer.lastname);
    }

    $scope.translationData.name = nameParts.join(' ');
  };

  if ($scope.currentCustomer._id) {
    generateName();
  }

  $scope.$watch('currentCustomer.firstname+currentCustomer.lastname', function () {
    generateName();
  }, true);

  $scope.checkSubscription = function (event) {
    if ($scope.showPremiumNotification) {
      event.preventDefault();
      event.stopPropagation();
      Notification.addErrorMessage(gettextCatalog.getString('Upgrade your plan to use this feature.'));
      return;
    }
  }


  /**
   * generate resource image preview
   * @param $files
   */
  $scope.onResourceImageSelect = function (files, event) {
    var file = files && files.length ? files[0] : null;

    if (file && window.FileReader && file.type.indexOf('image') > -1) {
      var fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onload = function (e) {
        $timeout(function () {
          $scope.imagePreview = e.target.result;
        });
      };

      CustomerDA
        .uploadImage($scope.currentCustomer._id, file)
        .then((response) => {
          $scope.currentCustomer = response;
          $scope.imagePreview = response.image.m;
          Notification.addSuccessMessage(gettextCatalog.getString('Image uploaded'));
        })
        .catch((response) => {
          if (response.data.error.message === 'file_to_large') {
            Notification.addErrorMessage(gettextCatalog.getString('File is to large (max 10 MB)'));
          } else if (response.data.error.message === 'extension_not_allowed') {
            Notification.addErrorMessage(gettextCatalog.getString('File extension not of type [JPG,PNG]'));
          } else {
            Notification.addErrorMessage({ text: response.data.error });
          }
        });
    }
  };

  $scope.deleteImage = function () {
    CustomerDA
      .deleteImage($scope.currentCustomer._id)
      .then(() => {
        delete $scope.currentCustomer.image;
        $scope.imagePreview = null;

        Notification.addSuccessMessage(gettextCatalog.getString('Image deleted'));
      })
      .catch(() => {
        Notification.addErrorMessage(gettextCatalog.getString('Cannot delete image'));
      });
  };

  /**
   * save current customer to database
   */
  $scope.save = function () {
    var name = $scope.currentCustomer.lastname + ', ' + $scope.currentCustomer.firstname;

    if ($scope.currentCustomer.email === '') {
      delete $scope.currentCustomer.email;
    }

    if ($scope.currentCustomer && $scope.currentCustomer.birthday) {
      $scope.currentCustomer.birthday.setHours(12);
    }

    if (!$scope.currentCustomer._id) {
      CustomerDA
        .insert($scope.currentCustomer)
        .then(function (newCustomer) {
          Notification.addSuccessMessage(gettextCatalog.getString('{{name}} created', { name: name }));
          $scope.currentCustomer = newCustomer;

          if (LocalStorage.get('openAppointment') || LocalStorage.get('openCash')) {
            $scope.backToReferrer(newCustomer);
          }
        })
        .catch(function () {
          Notification.addErrorMessage(gettextCatalog.getString('Cant add customer'));
        });
    } else {
      CustomerDA
        .update($scope.currentCustomer)
        .then((response) => {
          Notification.addSuccessMessage(gettextCatalog.getString('{{name}} updated', { name: name }));

          $scope.currentCustomer = response;
          if (LocalStorage.get('openAppointment') || LocalStorage.get('openCash')) {
            $scope.backToReferrer();
          }
        })
        .catch(() => {
          Notification.addErrorMessage(gettextCatalog.getString('Cant update customer'));
        });
    }
  };


  /**
   * go back to source (list|appointmentModal)
   */
  $scope.backToReferrer = function (upsertedCustomer) {
    var openAppointment = LocalStorage.get('openAppointment');
    var openCash = LocalStorage.get('openCash');

    if ($stateParams.referrer) {
      if($stateParams.tab) {
        $state.go($stateParams.referrer, {openTab: $stateParams.tab});
        return;
      }

      $state.go($stateParams.referrer);

      return;
    }

    if (openAppointment && openAppointment.appointment) {
      if(openAppointment.state === 'private.main.testManagement') {
        $state.go(openAppointment.state, {openTab: openAppointment.params.openTab});
      } else {
        $state.go(openAppointment.state);
      }

      switch (openAppointment.appointment.type) {
        case 'course':
          for (var i = 0, partLen = openAppointment.appointment.participants.length; i < partLen; i++) {
            if (openAppointment.appointment.participants[i] === '') {
              openAppointment.appointment.participants[i] = $scope.currentCustomer;
              break;
            }
          }

          ModalService.openAppointmentModal(openAppointment.appointment);
          break;
        case 'customer':
          var customerId = _.get(openAppointment.appointment, 'customer._id');
          var nameParts = [];

          if (!customerId && upsertedCustomer) {
            if (upsertedCustomer.lastname) {
              nameParts.push(upsertedCustomer.lastname);
            }

            if (upsertedCustomer.firstname) {
              nameParts.push(upsertedCustomer.firstname);
            }

            openAppointment.appointment.customer = {
              _id: upsertedCustomer._id,
              fullname: nameParts.join(' ')
            };
          }

          ModalService.openAppointmentModal(
            openAppointment.appointment,
            'appointment',
            () => {
              $state.reload();
            }
          );
          break;
      }

    } else if (openCash) {
      $state.go('private.main.calendar');
      openCash.customer = upsertedCustomer ? upsertedCustomer._id : openCash.customer;
      ModalService.openCashModal(openCash);
    } else {
      $state.go('private.main.customer');
    }
  };


  /**
   *  load the customer history
   */
  $scope.loadAppointments = function () {
    $scope.isLoading = true;

    if ($scope.currentCustomer._id) {
      CustomerDA
        .getAppointments($scope.currentCustomer._id, $scope.filter.start, $scope.filter.end)
        .then(function (appointments) {
          appointments = appointments.map((appointment) => {
            appointment.duration = moment(appointment.end).diff(moment(appointment.start), 'minutes');
            $scope.overallDuration += appointment.duration;
            return appointment;
          });
          $scope.isLoading = false;
          $scope.appointments = appointments;
        })
        .catch(function () {
          $scope.isLoading = false;
          Notification.addErrorMessage(gettextCatalog.getString('Cant load appointments'));
        });
    }
  };

  /**
   *  load customer documents
   */
  $scope.loadDocuments = function () {
    $scope.isLoading = true;

    if ($scope.currentCustomer._id) {
      CustomerDA
        .getDocuments($scope.currentCustomer._id)
        .then(function (documents) {
          $scope.documents = documents;
        })
        .catch(function () {
          $scope.isLoading = false;
          Notification.addErrorMessage(gettextCatalog.getString('Cant load documents'));
        });
    }
  };


  /**
   * delete a customer
   * @param customer
   */
  $scope.delete = function () {
    CustomerDA
      .remove($scope.currentCustomer._id)
      .then(function () {
        var name = $scope.currentCustomer.firstname + ' ' + $scope.currentCustomer.lastname;
        Notification.addSuccessMessage(gettextCatalog.getString('{{name}} was moved to trash', { name: name }));
        $scope.backToReferrer();
      })
      .catch(function () {
        Notification.addErrorMessage(gettextCatalog.getString('Cant delete customer'));
      });
  };

  /**
   * open appointment modal
   * @param appointment
   */
  $scope.openAppointment = function (appointment) {
    appointment.start = new Date(appointment.start);
    appointment.end = new Date(appointment.end);

    ModalService.openAppointmentModal(appointment);
  };

  $scope.openDocument = function (document) {
    const queryParam = document.fileNameOriginal && document.fileNameOriginal.length ? '?original=true' : '';
    window.open(`/api/v2/document/${document._id}${queryParam}`);
  };

  $scope.translateDocType = function(docType) {
    const translations = {
      covidTestResult: gettextCatalog.getString('covidTestResult')
    };

    return translations[docType] ? translations[docType] : gettextCatalog.getString('Unknown Type');
  };

  $scope.goToCustomer = function (direction) {
    var filteredCustomersLen = filteredCustomers.length;
    var currentIndex = filteredCustomers.indexOf($scope.currentCustomer._id);
    var targetIndex = currentIndex + direction;

    if (targetIndex < 0) {
      targetIndex = filteredCustomersLen - 1;
    } else if (targetIndex >= filteredCustomersLen) {
      targetIndex = 0;
    }

    if (filteredCustomers[targetIndex]) {
      $state.go('private.main.customerProfile', { customerId: filteredCustomers[targetIndex] });
    }
  };

  /**
   * check if form data is changed
   * @param customer
   * @returns {*}
   */
  $scope.isUnchanged = function () {
    return angular.equals($scope.currentCustomer, $scope.backupItem);
  };

  $scope.print = function () {
    $window.print();
  };

  $scope.loadAppointments();
  $scope.loadDocuments();
};
