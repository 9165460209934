'use strict';

var angularModule = angular.module('t2g.common.services.geoCodeService', []);

module.exports = angularModule;

angularModule.factory('GeocodeService', function ($q, $http, settings) {
  return {
    getGeoData: function (address) {
      return $http({
        method: 'GET',
        url: 'https://maps.googleapis.com/maps/api/geocode/json?key=' + settings.google.maps + '&address=' + encodeURIComponent(address) + '&sensor=false'
      }).then(function (response) {
          if (response.data.status === 'ZERO_RESULTS') {
            return $q.reject(response.data)
          } else {
            return response.data.results[0];
          }
        });
    }
  }
});