'use strict';

var AppointmentsCtrl = function($scope, $timeout) {
  this.appointmentScopes = $scope.appointmentScopes = [];

  $scope.$on('t2g:calendar:changed', function() {
    $timeout(() => {
      this.calcGroups();
    });
  });
};

AppointmentsCtrl.prototype.add = function(appointmentScope) {
  this.appointmentScopes.push(appointmentScope);
};

AppointmentsCtrl.prototype.remove = function(appointmentScope) {
  _.remove(this.appointmentScopes, {$id: appointmentScope.$id});
  this.calcGroups();
};

AppointmentsCtrl.prototype.calcGroups = function() {
  var scopeGroups = [[]];
  var scopesLen = this.appointmentScopes.length;
  var lastScopeGroup = scopeGroups[0];

  this.appointmentScopes.sort(function(scope1, scope2) {
    return scope1.appointment.preparationStart - scope2.appointment.preparationStart;
  });

  if (scopesLen > 0) {
    scopeGroups[0].push(this.appointmentScopes[0]);
  }

  _.forEach(this.appointmentScopes, function(scope, index) {
    var latestScope = lastScopeGroup[0];

    if (index > 0) {
      if (moment(scope.appointment.preparationStart).isSameOrAfter(moment(latestScope.appointment.followUpEnd))) {
        var newGroup = [scope];
        lastScopeGroup = newGroup;
        scopeGroups.push(newGroup);
      } else if (moment(scope.appointment.followUpEnd).isSameOrAfter(moment(latestScope.appointment.followUpEnd))) {
        // add latest end to start of array
        lastScopeGroup.unshift(scope);
      } else {
        lastScopeGroup.push(scope);
      }
    }
  });

  // calc widths
  scopeGroups.forEach((scopeGroup) => {
    var width = 100 / scopeGroup.length;

    scopeGroup.forEach((appointment, index) => {
      appointment.setVerticalPos(width, index * width);
    });
  });
};

module.exports = AppointmentsCtrl;
