'use strict';

var RRULE_WEEK_DAYS = [
  rrule.RRule.MO,
  rrule.RRule.TU,
  rrule.RRule.WE,
  rrule.RRule.TH,
  rrule.RRule.FR,
  rrule.RRule.SA,
  rrule.RRule.SU
];

var frequencies = [
  { weeks: 1, value: 7 },
  { weeks: 2, value: 14 },
  { weeks: 3, value: 21 },
  { weeks: 4, value: 28 },
  { weeks: 5, value: 35 },
  { weeks: 6, value: 42 },
  { weeks: 7, value: 49 },
  { weeks: 8, value: 56 },
  { weeks: 9, value: 63 },
  { weeks: 10, value: 70 },
];

var AppointmentModalBaseCtrl = function (modalInstance, $injector) {
  var self = this;
  this._modalInstance = modalInstance;
  this._AppointmentDA = $injector ? $injector.get('AppointmentDA') : undefined;
  this._calendarDA = $injector ? $injector.get('CalendarDA') : undefined;
  this._gettextCatalog = $injector ? $injector.get('gettextCatalog') : undefined;
  this._ModalService = $injector ? $injector.get('ModalService') : undefined;
  this._rootScope = $injector ? $injector.get('$rootScope') : undefined;
  this._state = $injector ? $injector.get('$state') : undefined;
  this._stateParams = $injector ? $injector.get('$stateParams') : undefined;
  this._LocalStorage = $injector ? $injector.get('LocalStorage') : undefined;
  this._Notification = $injector ? $injector.get('Notification') : undefined;
  this._DatepickerSettings = $injector ? $injector.get('DatepickerSettings') : undefined;
  this.submitted = false;
  this.send = false;
  this.frequencies = [];
  this.dtpStartOpened = false;
  this.dtpEndOpened = false;
  this.dtpOptions = this._DatepickerSettings ? this._DatepickerSettings.default : {};
  this.initialResourceSet = false;

  if ($injector) {
    // generate frequencies for series tab
    frequencies.forEach(function (frequency) {
      self.frequencies.push({
        label: self._gettextCatalog.getPlural(frequency.weeks, 'week', '{{$count}} weeks', {}),
        value: frequency.value
      });
    });

    this._rootScope.$watch(function () {
      return self.appointment.start.getTime();
    }, function (newValue, oldValue) {
      var duration = moment(self.appointment.end).diff(oldValue);

      if (!moment(newValue).isSame(moment(oldValue))) {
        self.appointment.end = moment(self.appointment.start).add(duration).toDate();
      }

      self.checkCollision();
    });

    this._rootScope.$watch(function () {
      return self.appointment.end.getTime();
    }, function () {
      if (moment(self.appointment.end).isBefore(self.appointment.start)) {
        self.appointment.end = moment(self.appointment.start).add(5, 'minutes').toDate();
      }

      self.checkCollision();
    });

    this._rootScope.$watch(function () {
      return _.get(self, 'appointment.object._id') + _.get(self, 'appointment.room', '');
    }, function () {
      if (self.initialResourceSet) {
        self.checkCollision();
      }
      self.initialResourceSet = true;
    });
  }
};


/**
 * check for appointment collision
 */
AppointmentModalBaseCtrl.prototype.checkCollision = function () {
  var self = this;

  if (this.appointment.object && this.appointment.object._id && this.appointment.start && this.appointment.end) {
    self.collision = { collided: false };
    this._calendarDA
      .checkCollision(
        this.appointment._id,
        this.appointment.object._id,
        this.appointment.room,
        this.appointment.start,
        this.appointment.end
      )
      .then(function (response) {
        var employee;
        var room;

        if (_.get(response, 'employee.collided')) {
          employee = _.find(self.resources.employees, { _id: response.employee.id });
          response.employee.name = employee.fullname;
        }

        if (_.get(response, 'room.collided')) {
          room = _.find(self.resources.rooms, { _id: response.room.id });
          response.room.name = room.fullname;
        }

        self.collision = response;
      });
  }
};


/**
 * generate series of appointments
 */
AppointmentModalBaseCtrl.prototype.generateSeries = function () {
  var startHours = this.appointment.start.getHours();
  var byweekday = [];
  var rrulOptions = {
    freq: rrule.RRule.WEEKLY,
    interval: (this.appointment.recurring.frequency / 7),
    dtstart: this.appointment.start
  };

  this.appointment.recurring.until = this.appointment.recurring.until ? new Date(this.appointment.recurring.until) : moment(this.appointment.start).endOf('month').toDate();

  // generate weekday settings
  _.forEach(this.appointment.recurring.weekDays, function (weekDayEnabled, index) {
    if (weekDayEnabled) {
      byweekday.push(RRULE_WEEK_DAYS[index]);
    }
  });

  if (byweekday.length > 0) {
    rrulOptions.byweekday = byweekday;
  }

  if (this.appointment.recurring.endType === 'times') {
    rrulOptions.count = this.appointment.recurring.times || 5;
  } else if (rrulOptions.dtstart.getTime() >= this.appointment.recurring.until.getTime()) {
    this.recurringDates = [];
    return;
  } else {
    rrulOptions.until = this.appointment.recurring.until;
  }

  var rule = new rrule.RRule(rrulOptions);
  this.recurringDates = rule.all();
  angular.forEach(this.recurringDates, function (recurringDate) {
    recurringDate.setHours(startHours);
  });
};

/**
 * regenerate appointment series on any recurring option changes
 */
AppointmentModalBaseCtrl.prototype.watchRecurringSettings = function () {
  var self = this;
  return this.rootScope.$watch(function () {
    return self.appointment.recurring;
  }, function (newValue, oldValue) {
    if (newValue && newValue.enabled) {
      if (!oldValue || !oldValue.enabled) {
        var start = moment(self.appointment.start);
        self.appointment.recurring = {
          frequency: 7,
          enabled: true,
          weekDays: [false, false, false, false, false, false, false]
        };

        if (self.appointment.recurring.endType === 'date') {
          self.appointment.recurring.until = self.appointment.recurring.until ? new Date(self.appointment.recurring.until) : moment(self.appointment.start).endOf('year').toDate();
        } else {
          self.appointment.recurring.endType = 'times';
          self.appointment.recurring.times = self.appointment.recurring.times || 5;
        }

        self.appointment.recurring.weekDays[start.weekday()] = true;
      }

      self.generateSeries();
    }

  }, true);
};

AppointmentModalBaseCtrl.prototype.delete = function () {

};


/**
 * save/update current appointment form
 */
AppointmentModalBaseCtrl.prototype.save = function (close = true) {
  var promise;
  var self = this;
  this.submitted = true;

  if (this.AppointmentForm.$valid && !self.send) {
    if (this.appointment.type === 'course') {
      this.appointment.membershipSettings = _.map(this.appointment.membershipSettings, function (participantsSetting) {
        if (participantsSetting.customer) {
          participantsSetting.customer = participantsSetting.customer._id;
        }

        return participantsSetting;
      });

      if (self.appointment._id) {
        // TODO: if series ask: edit all, edit from now (change this and all following), edit only this one
        promise = this._AppointmentDA.update(this.appointment);
      } else {
        promise = this._AppointmentDA.insert(this.appointment);
      }

      self.send = true;

      promise
        .then(function () {
          self.close();
        })
        .catch(function () {
          self.send = false;
        });
    } else if (this.appointment.type === 'customer') {
      if (self.appointment &&
        self.appointment.meta &&
        self.appointment.meta.testReason !== 18
      ) {
        delete self.appointment.meta.testReason2;
      }

      if (self.appointment._id) {
        // TODO: if series ask: edit all, edit from now (change this and all following), edit only this one
        promise = self._AppointmentDA.update(self.appointment, self.appointment.sendNotifications);
      } else {
        promise = self._AppointmentDA.insert(self.appointment, self.appointment.sendNotifications);
      }

      this.send = true;

      promise
        .then(function () {
          if (close) {
            self.close();
          }
        })
        .catch(function (error) {
          self.send = false;
        });
    } else {
      if (self.appointment._id) {
        promise = this._AppointmentDA.update(this.appointment);
      } else {
        promise = this._AppointmentDA.insert(this.appointment);
      }

      this.send = true;

      promise
        .then(function () {
          self.close();
        })
        .catch(function () {
          self.send = false;
        });
    }
  }
};


/**
 * remove current opened appointment
 */
AppointmentModalBaseCtrl.prototype.remove = function () {
  if (this.appointment.seriesId || (this.appointment.recurring && this.appointment.recurring.enabled)) {
    this.close();
    this._ModalService.openDeleteSeriesAppointmentModal(this.appointment, (result) => {
      if (result) {
        this._AppointmentDA
          .remove(this.appointment._id, this.appointment.sendNotifications, this.appointment, result)
          .then(() => {
            this.close();
          });
      }
    });
  } else {
    this._AppointmentDA
      .remove(this.appointment._id, this.appointment.sendNotifications, this.appointment)
      .then(() => {
        this.close();
      });
  }
};


AppointmentModalBaseCtrl.prototype.openSeriesHead = function () {
  this.close();

  if (this.appointment.seriesId) {
    this._ModalService.openAppointmentModal(
      { _id: this.appointment.seriesId, type: this.appointment.type }
    );
  }
};


/**
 * close appointment modal modal
 * @param {*} data
 */
AppointmentModalBaseCtrl.prototype.close = function (data, storeAppointmentInSession) {
  if (storeAppointmentInSession) {
    this._LocalStorage.set('openAppointment', {
      appointment: this.appointment,
      state: this._state.current.name,
      params: this._stateParams
    });
  } else {
    this._LocalStorage.remove('openAppointment');
  }

  this._modalInstance.close(data);
};


AppointmentModalBaseCtrl.prototype.openCustomerModal = function (customer) {
  var customerId = _.get(customer, '_id', 'new');

  this.close({}, true);
  this._state.go('private.main.customerProfile', { customerId: customerId });
};

module.exports = AppointmentModalBaseCtrl;



