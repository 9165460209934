'use strict';

var angularModule = angular.module('t2g.common.directives.radioButtonDirective', []);

module.exports = angularModule;

angularModule.directive('radio', function ($timeout) {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function ($scope, $element, $attributes, ngModel) {
                var value = $attributes.radio;

                $timeout(function () {
                    if (ngModel.$modelValue === value) {
                        $element.addClass('button--secondary');
                    }
                });

                $element.on('click', function () {
                    $('.btn-group .button').each(function () {
                        $(this).removeClass('button--secondary');
                    });

                    $element.addClass('button--secondary');

                    if (ngModel) {
                        $scope.$apply(function () {
                            ngModel.$setViewValue(value);
                        });
                    }
                });
            }
        };
    }
);

