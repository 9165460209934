'use strict';

var angularModule = angular.module('t2g.common.data-access.countryConfig', [
    'ngResource',
    require('t2g.common.services.sessionStorage').name
]);

module.exports = angularModule;

var STORAGE_KEY = 'countryConfig';

var countries = [
    {code: 'DE', label: 'Deutschland', currency: 'EUR', language: ['de-DE']},
    {code: 'CH', label: 'Schweiz', currency: 'CHF', language: ['de-CH']},
    {code: 'AT', label: 'Österreich', currency: 'EUR', language: ['de-DE']},
    {code: 'LI', label: 'Liechtenstein', currency: 'CHF', language: ['de-CH']},
    {code: 'LU', label: 'Luxemburg', currency: 'EUR', language: ['de-DE']},
    {code: 'ES', label: 'Spanien', currency: 'EUR', language: ['de-DE']}
];

angularModule.factory('CountryConfigResource', function ($resource, settings) {
    var resourceUrl = settings.apiBase + '/api/v2/countryConfig/:countryCode';

    var resource = $resource(resourceUrl, {}, {});

    return resource;
});

angularModule.factory('CountryConfig', function ($q,
                                                 $timeout,
                                                 localStorageService,
                                                 CountryConfigResource,
                                                 OfficeDA) {

    return {
        get: get,
        getCountries,
        getCountryByCode
    };

    function getCountryByCode(countryCode) {
        countryCode = countryCode.toUpperCase();

        return get()
            .then(function(countries){
               return _.find(countries, {code: countryCode});
            });
    }

    function get() {
        var countryConfig = localStorageService.get(STORAGE_KEY);

        if (!countryConfig) {
            return OfficeDA
                .get()
                .then(function (office) {
                    return CountryConfigResource.get({countryCode: office.address.countryCode}).$promise;
                })
                .then(function (response) {
                    const countryConfigResponse = response.countryConfig;

                    // add holidayKey to each holiday
                    angular.forEach(countryConfigResponse.holidays, function (days, year) {
                        angular.forEach(countryConfigResponse.holidays[year], function (holiday, date) {
                            holiday.date = new Date(holiday.date);
                            countryConfigResponse.holidays[year][date].key = holiday.date.getDayKey();
                        });
                    });

                    localStorageService.set(STORAGE_KEY, countryConfigResponse);

                    return countryConfigResponse;
                });
        } else {
            return $q.when(countryConfig);
        }
    }

    function getCountries() {
        return $q.when(countries);
    }
});
