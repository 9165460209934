'use strict';

var angularModule = angular.module('t2g.common.data-access.messages', []);

module.exports = angularModule;

angularModule.factory('MessageResource', function ($resource, settings) {
    var resourceUrl = settings.apiBase + '/api/v2/message/:messageId';

    var resource = $resource(resourceUrl, {messageId: '@_id'}, {
        getAll: {method: 'GET', isArray: false}
    });

    return resource;
});

angularModule.factory('Message', function (MessageResource) {
    return {
        get: function (box, page = 0) {
            return MessageResource
              .getAll({box: box, page})
              .$promise;
        },
        getOne: function (messageId) {
            return MessageResource
              .get({messageId: messageId})
              .$promise
              .then(function (response) {
                  return response.message;
              });
        },
        delete: function (message) {
            var message = new MessageResource(message);
            return message.$delete();
        }
    };
});
