var angularModule = angular.module('t2g.common.directives.onScrollDirective', []);

module.exports = angularModule;

angularModule.directive('onScroll', function () {
    return {
        restrict: 'A',
        link: function ($scope, $element) {
            $(window).on('scroll', function () {
                if ($(window).scrollTop() > 0) {
                    $element.addClass('shadow');
                } else {
                    $element.removeClass('shadow');
                }
            });

            $scope.$on('$destroy', function () {
                $element.off();
            });
        }
    }
});





