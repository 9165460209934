'use strict';

var PaymentDetailsCtrl = function ($state,
                                   $timeout,
                                   gettextCatalog,
                                   Notification,
                                   LocalStorage,
                                   subscription,
                                   settings,
                                   BillwerkService,
                                   office,
                                   OfficeDA) {

    var officeCountryCode = _.get(office, 'address.countryCode', 'de');
    var subscriptionPackage;

    this.submitted = false;
    this._LocalStorage = LocalStorage;
    this._state = $state;
    this._BillwerkService = BillwerkService;
    this._settings = settings;
    this._Notification = Notification;
    this._OfficeDA = OfficeDA;
    this.office = office;
    this.gettextCatalog = gettextCatalog;
    this.subscriptionProcessModel = this._LocalStorage.get('SubscriptionProcess');
    this.isStandalone = this.subscriptionProcessModel ? false : true;
    this.countryCode = _.get(subscription, 'billingInformation.country', officeCountryCode).toLowerCase();
    this.model = subscription.paymentInformation;
    this.methods = this._settings.paymentMethods[this.countryCode] || [this._settings.billwerk.bearer.invoice, this._settings.billwerk.bearer.payPal];
    subscriptionPackage = _.get(this.subscriptionProcessModel, 'subscription.package', _.get(subscription, 'package', 'basic'));
    this.isBasicPackage = subscriptionPackage === 'basic';
    this.model = this.model || {method: this.isBasicPackage ? 'none' : this.methods[0]};
    this.activateSMS = _.get(this.subscriptionProcessModel, 'activateSMS', false);
    this.isLoading = false;

    if (this.isBasicPackage) {
        this.model.method = this._settings.billwerk.bearer.invoice;
    } else if (!this.model.method || this.model.method.length === 0) {
        this.model.method = this.methods[0];
    }
};

PaymentDetailsCtrl.prototype.showInvoiceNotification = function() {
    return this.office.settings.currency === 'EUR' && this.model.interval === 'monthly';
};

PaymentDetailsCtrl.prototype.isPaymentMethodVisible = function (method) {
    if (this.isBasicPackage) {
        return false;
    }

    if (this.showInvoiceNotification() && method === 'invoice') {
        return false;
    }

    return this.methods.indexOf(method) > -1;
};

PaymentDetailsCtrl.prototype.select = function (paymentType) {
    this.model.method = this._settings.billwerk.bearer[paymentType];
};

PaymentDetailsCtrl.prototype.isSelected = function (paymentType) {
    return this._settings.billwerk.bearer[paymentType] === this.model.method;
};

PaymentDetailsCtrl.prototype.goBack = function () {
    this._state.go('private.main.billingDetails');
};

PaymentDetailsCtrl.prototype.save = function () {
    var self = this;
    var paymentData;
    var order;
    this.submitted = true;

    if (this.isSelected('creditCard')) {
        paymentData = {
            bearer: this._settings.billwerk.bearer.creditCard,
            cardNumber: this.model.cardNumber,
            expiryMonth: this.model.expiryMonth,
            expiryYear: this.model.expiryYear,
            cardHolder: this.model.cardHolder,
            cvc: this.model.cvc
        };
    } else if (this.isSelected('payPal')) {
        paymentData = {
            bearer: this._settings.billwerk.bearer.payPal,
            emailAddress: this.model.paypalEmail
        };
    } else if (this.isSelected('directDebit')) {
        paymentData = {
            bearer: this._settings.billwerk.bearer.directDebit,
            accountHolder: this.model.accountHolder,
            iban: this.model.iban,
            mandateSignatureDate: new Date(),
            mandateText: `${this.gettextCatalog.getString('By signing this mandate form, you authorise')}
            ${this.gettextCatalog.getString('(A)Termin2go to send instructions to your bank to debit your account and')}
            ${this.gettextCatalog.getString('(B) your bank to debit your account in accordance with the instructions from Termin2go.')}
            ${this.gettextCatalog.getString('As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited.')}
            `
        };
        console.log(paymentData);
    } else if (this.isSelected('invoice')) {
        paymentData = {
            bearer: this._settings.billwerk.bearer.invoice
        };
    } else if (this.isSelected('none')) {
        paymentData = {
            bearer: this._settings.billwerk.bearer.none
        };
    }

    if (this.PaymentDetailsForm.$valid) {
        this.isLoading = true;

        if (this.isStandalone) {
            this._BillwerkService
                .changePaymentInformation(paymentData)
                .then(function (response) {
                    self.isLoading = false;

                    if (response.Message) {
                        self._Notification.addErrorMessage({text: response.Message});
                    } else {
                        self._Notification.addSuccessMessage(self.gettextCatalog.getString('Changed Payment Details successfully'));
                    }
                })
                .catch(function (error) {
                    self._Notification.addErrorMessage({text: error.Message});
                    self.isLoading = false;
                });
        } else {
            order = _.get(this.subscriptionProcessModel, 'billing.billwerkOrder');

            this._BillwerkService
                .createPayment(paymentData, order)
                .then(function (response) {
                    self.isLoading = false;

                    if (response.Message) {
                        self._Notification.addErrorMessage({text: response.Message});
                    } else {
                        if (self.activateSMS) {
                            self.office.settings.reminder = self.office.settings.reminder || {};
                            self.office.settings.reminder.sms = self.office.settings.reminder.sms || {enabled: true};
                            self._OfficeDA.update(self.office);
                        }
                        self._Notification.addSuccessMessage(self.gettextCatalog.getString('Order finalized'));
                        self._state.go('private.main.finalize');
                    }
                })
                .catch(function (error) {
                    self._Notification.addErrorMessage({text: error.Message});
                    self._state.go('private.main.subscription', {openTab: 'plans'});
                    self.isLoading = false;
                });
        }
    }
};


module.exports = PaymentDetailsCtrl;
