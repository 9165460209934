'use strict';

var wavesModule = angular.module('t2g.common.directives.waves', []);

module.exports = wavesModule;

wavesModule.directive('waves', function() {
    return {
        restrict: 'C',
        link: function($scope, element) {
            Waves.init();
            Waves.attach(element[0]);
        }
    };
});

