let angularModule = angular.module('t2g.common.data-access.log', []);

module.exports = angularModule;

angularModule.factory('LogResource', function ($resource, settings) {
  const resourceUrl = settings.apiBase + '/api/v2/log/:itemId';
  const resource = $resource(resourceUrl, {}, {
    list: { method: 'GET', isArray: false },
  });

  return resource;
});

angularModule.factory('LogDA', function (LogResource) {
  return {
    list,
  };

  function list(filter) {
    return LogResource
      .list(filter)
      .$promise
      .then((response) => {
        return response.items;
      });
  }
});

